/*
    Grid Componet has multiple ways to use it:
        1. Pass the URL of the service which returns the data with specific format. Events are handled automatically.
        2. Pass the Data to display. Also handle the events: 'pageChanged', 'pageCountChanged', 'sort', 'search'.
    Appearance:
        1. Default style is small, white grid
        2. If passed FullGrid as a parameter, Grid will have ordering, filtering and page size changer

    Because the use ways vary, some 'props' and 'data' elements have similar functionality e.g. 'passedData' and 'gridData'
    and they are used according the use case.
 */

<template>
    <div>
        <loader v-if="isLoading"></loader>
        <div v-else class="grid" :class="{'full-grid' : fullGrid}">
            <form v-if="fullGrid">
                <button v-if="exportable" @click.prevent="exportToExcel" class="export">Export</button>
                <div class="page-count">
                    <dropdown
                        :options="pageCountOptions"
                        name="pageCount"
                        v-model="selectedPageCount"
                    ></dropdown>
                </div>
                <div v-if="toggleable" class="show-hide-columns">
                    <div class="multiselect">
                        <div class="selectBox" @click="showCheckboxes">
                            <i class="material-icons eye">remove_red_eye</i>
                            <div class="overSelect"></div>
                        </div>
                        <div v-show="checkboxesAreExpanded" class="checkboxes">
                            <label v-for="column in columns" :key="column.title">
                                <input
                                    :name="column.title"
                                    type="checkbox"
                                    @change="ToggleColumn(column.title)"
                                    checked
                                >
                                <span>{{ column.title }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <div class="table-wrapper" :class="{'block' : fullGrid}">
                <table>
                    <thead :class="{'align-right' : alignLastRight}">
                        <tr v-if="!orderable">
                            <th
                                v-for="(column, ind) in filteredColumns"
                                :key="ind"
                                class="plain"
                            >{{ $t(column.title) || column.title }}</th>
                        </tr>
                        <tr v-else>
                            <th v-for="(column, ind) in filteredColumns" :key="ind" @click="sortColumn(column.key)">
                                <div class="th-wrapper">
                                    <div
                                        class="title"
                                    >{{ $t(column.title) || column.title }}</div>
                                    <div class="sort-icons" v-if="!column.plainColumn && orderable && column.key">
                                        <i
                                            v-if="sortedColumn == column.key && sortedByDesc"
                                            class="material-icons"
                                        >arrow_drop_down</i>
                                        <i
                                            v-if="sortedColumn == column.key && !sortedByDesc"
                                            class="material-icons"
                                        >arrow_drop_up</i>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody :class="{'align-right' : alignLastRight}">
                        <template v-for="(row, rowIndex) in gridData">
                            <tr :rowID="row[identificator]" :key="row[identificator]">
                                <td
                                    v-for="(column, ind) in filteredColumns"
                                    :key="ind"
                                    v-html="getCustomValue(column, row[column.key], row)"
                                    :title="row[column.key]"
                                ></td>
                            </tr>
                            <tr
                                v-if="collapsible && rowIndex + 1 == collapsedIndex"
                                :key="row[identificator]+'col'"
                                class="collapsed"
                            >
                                <td :colspan="columns.length" class="td-collapsed">
                                    <slot></slot>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="lastRow" class="custom-row">
                            <td v-for="(value, index) in lastRow" :key="index">{{value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="tfoot" v-if="pagination">
                <div class="footer">
                    <p class="footer-text">
                        Showing
                        <span>{{entryStartIndex}}</span> -
                        <span>{{entryEndIndex}}</span> of
                        <span>{{requestURL ? totalCount : passedCount}}</span> entries
                    </p>
                    <pagination :pageCount="pageCount" @changed="pageChange"></pagination>
                </div>
            </div>
        </div>
        <iframe id="txtArea1" style="display:none"></iframe>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import Pagination from './Pagination.vue'
import Dropdown from '../Dropdown/Dropdown.vue'
import Loader from '@/components/ReusableComponents/Loader/Loader.vue'

export default {
    created: function() {
        if (this.requestURL) {
            this.requestData.orderByParam = this.initialOrderingColumn
            axios.post(this.requestURL, this.requestData).then(response => {
                this.gridData = response.data.data
                this.totalCount = response.data.count
                this.isLoading = false
                this.$emit('gotDataFromRequest', this.gridData)
            })
        } else {
            this.gridData = this.passedData
            this.isLoading = false
        }
    },

    name: 'Grid',

    components: {
        Pagination,
        Dropdown,
        Loader,
    },

    props: {
        columns: {
            type: Array,
            required: true,
        },
        requestURL: String,
        passedData: Array,
        passedCount: Number,
        passedPageSize: Number,
        identificator: String,
        pagination: {
            type: Boolean,
            default: true,
        },
        requiredParameters: Object,
        toggleable: {
            type: Boolean,
            default: true,
        },
        exportable: {
            type: Boolean,
            default: true,
        },
        orderable: {
            type: Boolean,
            default: true,
        },
        collapsible: {
            type: Boolean,
            default: false,
        },
        fullGrid: {
            type: Boolean,
            default: false,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        collapsedIndex: Number,
        initialOrderingColumn: String,
        alignLastRight: Boolean,
        lastRow: Array,
    },

    data: function() {
        return {
            gridData: [],
            filters: {}, //which columns should be filtered
            lastFilters: {},
            isLoading: true,
            checkboxesAreExpanded: false,
            pageCountOptions: [
                {
                    text: 15,
                    value: 15,
                },
                {
                    text: 30,
                    value: 30,
                },
                {
                    text: 50,
                    value: 50,
                },
                {
                    text: 100,
                    value: 100,
                },
            ],
            currentPageIndex: 1,
            sortedColumn: '',
            sortedByDesc: true,
            showFilter: '',
            totalCount: Number,
            requestData: {
                orderByParam: 'string',
                orderByIsDesc: true,
                pageCount: 1,
                pageLength: 15,
                params: [],
            },
            pageSize: 15,
            selectedPageCount: '15',
            hiddenColumns: new Set(),
        }
    },

    watch: {
        passedData: function(newData) {
            this.gridData = newData
        },
        selectedPageCount(count) {
            this.pageCountChangeHandler(count)
        },
    },

    computed: {
        filteredColumns() {
            return this.columns.filter(el => !this.hiddenColumns.has(el.title))
        },
        pageCount() {
            var count = this.requestURL ? this.totalCount : this.passedCount
            var pageSize = this.requestURL ? this.pageSize : this.passedPageSize
            if (count % pageSize == 0) return count / pageSize
            return Math.floor(count / pageSize) + 1
        },
        entryStartIndex() {
            var count = this.requestURL ? this.totalCount : this.passedCount
            var pageSize = this.requestURL ? this.pageSize : this.passedPageSize
            return Math.min(pageSize * (this.currentPageIndex - 1) + 1, count)
        },
        entryEndIndex() {
            var count = this.requestURL ? this.totalCount : this.passedCount
            var pageSize = this.requestURL ? this.pageSize : this.passedPageSize
            return Math.min(this.entryStartIndex + pageSize - 1, count)
        },
    },

    methods: {
        showCheckboxes() {
            if (this.checkboxesAreExpanded) this.checkboxesAreExpanded = false
            else this.checkboxesAreExpanded = true
        },
        ToggleColumn(columnTitle) {
            if(this.hiddenColumns.has(columnTitle)) this.hiddenColumns.delete(columnTitle)
            else this.hiddenColumns.add(columnTitle)
            this.hiddenColumns = new Set(this.hiddenColumns) //for reactivity, because vue doesn't support it for Set/Map structures
        },
        pageChange(page) {
            this.currentPageIndex = page
            if (this.requestURL) {
                this.requestData.pageCount = page
                this.postData()
            } else {
                this.$emit('pageChanged', page)
            }
        },
        getCustomValue(column, val, row) {
            if (!column.customTemplate) return `<span>${val != undefined ? val : '-'}</span>`
            var text = column.customTemplate(row, val)
            return text
        },
        pageCountChangeHandler(value) {
            this.pageSize = parseInt(value)
            this.currentPageIndex = 1
            if (this.requestURL) {
                this.requestData.pageLength = value
                this.requestData.pageCount = 1
                this.postData()
            } else {
                this.$emit('pageCountChanged', value)
            }
        },
        sortColumn(columnKey) {
            if(!this.sortable) return
            this.sortedColumn = columnKey
            this.sortedByDesc = !this.sortedByDesc
            if (this.requestURL) {
                this.requestData.orderByParam = columnKey
                this.requestData.orderByIsDesc = this.sortedByDesc
                this.postData()
            } else
                this.$emit('sort', {
                    orderByDesc: this.sortedByDesc,
                    columnName: columnKey,
                })
        },
        filterIconClickHandler(columnKey) {
            if (this.showFilter == columnKey) this.showFilter = ''
            else this.showFilter = columnKey
        },
        postData() {
            axios.post(this.requestURL, this.requestData).then(response => {
                this.gridData = response.data.data
                this.totalCount = response.data.count
                this.$emit('gotDataFromRequest', this.gridData)
            })
        },
        exportToExcel() {
            /* eslint-disable */
            var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>"
            var textRange
            var j = 0
            var tab = this.$el.querySelector('table')

            for (j = 0; j < tab.rows.length; j++) {
                var clone = tab.rows[j].cloneNode(true)
                //remove extra texts from rows
                var searches = clone
                    .querySelectorAll('.search-wrapper')
                    .forEach(s => s.remove())
                var iconNames = clone
                    .querySelectorAll('.filter')
                    .forEach(i => i.remove())
                tab_text += clone.innerHTML + '</tr>'
            }

            tab_text = tab_text + '</table>'
            tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, '') //remove if u want links in your table
            tab_text = tab_text.replace(/<img[^>]*>/gi, '') // remove if u want images in your table
            tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, '') // removes input params

            var ua = window.navigator.userAgent
            var msie = ua.indexOf('MSIE ')

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                // If Internet Explorer
                txtArea1.document.open('txt/html', 'replace')
                txtArea1.document.write(tab_text)
                txtArea1.document.close()
                txtArea1.focus()
                var sa = txtArea1.document.execCommand(
                    'SaveAs',
                    true,
                    'Say Thanks to Sumit.xls'
                )
            } //other browser not tested on IE 11
            else
                var sa = window.open(
                    'data:application/vnd.ms-excel,' +
                        encodeURIComponent(tab_text)
                )
            /* eslint-enable */
            return sa
        },
        search(form) {
            this.requestData.params = []
            Object.keys(form).forEach(key => {
                if (!form[key]) return
                this.requestData.params.push({
                    operator: 0,
                    paramName: key,
                    value: form[key],
                })
            })
            this.postData()
        },
    },
}
</script>

<style scoped lang="scss">
.grid {
    margin-top: 25 * $rem;
    width: 100%;
    border-radius: 5 * $rem;

    .table-wrapper {
        overflow-x: auto;
        min-height: 150 * $rem;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-size: 14 * $rem;

        tr {
            height: 20 * $rem;
        }

        tbody {
            td {
                text-align: center;
                padding: 0;
                margin: 0;
                color: rgb(99, 99, 99);
                text-align: left;
                font-weight: 500;
                padding-bottom: 5 * $rem;
                // padding-left: 15 * $rem;
            }

            &.align-right td:last-child {
                text-align: right;
            }
        }

        thead {
            th {
                text-align: left;
                padding-bottom: 25 * $rem;
                color: rgba(99, 99, 99, 0.7);
                font-weight: 400;

                // &.plain {
                //     padding-left: 1rem;
                // }
            }

            &.align-right th:last-child {
                text-align: right;
            }
        }

        .tfoot {
            width: 100%;
            margin-top: 30 * $rem;

            td {
                padding-top: 15 * $rem;
            }
        }
    }
}

.grid /deep/ .blue {
    color: #0060ff;
    font-weight: bold;
    margin-right: 3 * $rem;
}

.full-grid {
    margin-top: 0;
    // min-height: 150*$rem;

    /deep/ .block.table-wrapper {
        box-shadow: 0 0 0.3375rem -0.125rem grey;
        padding-bottom: 35 * $rem;
    }

    form {
        padding: 0 0 15 * $rem 0;
        display: flex;
        justify-content: flex-end;

        .show-hide-columns {
            margin-left: 7 * $rem;

            .multiselect {
                width: initial;
                position: relative;

                .selectBox {
                    cursor: pointer;
                    width: 50px;
                    background-color: white;
                    height: 33px;
                    border: 1px solid #b9b9b9b5;
                    border-radius: 0.1875rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .overSelect {
                        cursor: pointer;
                    }

                    i.eye {
                        color: #4c4c4c;
                        z-index: 4;
                    }
                }
                .checkboxes {
                    z-index: 9;
                    background-color: white;
                    position: absolute;
                    right: 0;
                    border: $rem solid $grey-light;
                    padding: 5px;

                    label {
                        display: block;
                        width: 150 * $rem;
                        padding: 5 * $rem 15 * $rem;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        span {
                            margin-left: 15 * $rem;
                            color: $grey-dark;
                            font-size: 14 * $rem;
                            color: $grey-dark;
                            font-weight: 400;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    label:hover {
                        background-color: #f9f9f9;
                    }
                }
            }
        }

        button.export {
            margin-right: 10 * $rem;
            background-color: #4061c5;
            border: none;
            border-radius: $rem;
            font-size: 12 * $rem;
            padding: 0 15 * $rem;
            border-radius: 0.1875rem;
            letter-spacing: 1 * $rem;
            color: white;
            cursor: pointer;

            &:hover {
                filter: brightness(1.1);
            }
        }
    }

    table {
        tr {
            height: 43 * $rem;
            border-bottom: $rem solid #ebebeb;
        }

        tbody {
            tr {
                background-color: white;
                &:hover {
                    background-color: rgb(250, 250, 250);
                }
                &.custom-row td {
                    font-style: italic;
                    font-weight: 700;
                }
            }

            td {
                padding-top: 12 * $rem;
                padding-bottom: 12 * $rem;
                font-weight: 500;
                max-width: 200 * $rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 15 * $rem;

                &.td-collapsed {
                    white-space: initial;
                    overflow: initial;
                    text-align: center;
                }

                &.td-center {
                    text-align: center;
                    padding-left: 0;
                }
            }
        }

        thead {
            &:hover {
                cursor: pointer;
            }

            th {
                padding: 15 * $rem 15 * $rem;
                border-right: 0.0625rem solid #c0bfc0;
                background-color: #f2f1f2;
                position: relative;
                font-weight: 400;

                &:last-child {
                    // text-align: center;
                    border-right: none;
                }

                .th-wrapper {
                    color: $grey-dark;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    height: 16 * $rem;
                    overflow: visible;

                    .sort-icons,
                    .filter {
                        width: 24 * $rem;
                        padding-top: 2 * $rem;
                        position: relative;
                        left: 10px;
                    }

                    .title {
                        font-weight: 600;
                    }
                }
            }

            th.plain {
                padding-left: 3rem;
            }
        }
    }

    .tfoot {
        width: 100%;
        margin-top: 15 * $rem;

        .footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
                margin-right: 15 * $rem;
                color: $grey-dark;

                span {
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
