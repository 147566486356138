<template>
    <div class="acc-details">
        <div class="info-wrapper">
            <info-block :title="$t('ACCOUNT__PERSONAL_INFORMATION')" :fields="personalInfoFields"></info-block>
            <info-block :title="$t('ACCOUNT__AFFILIATE_INFORMATION')" :fields="accountInfoFields"></info-block>
        </div>
        <periods @periodChanged="periodChangeHandler"></periods>
        <div class="area-chart block">
            <div class="header">
                <h4 class="title">{{ $t('ACCOUNT__TOTAL_ACTIVITY') }}</h4>
                <div class="summary" v-if="profileActivityData">
                    <div class="summary-item" v-for="item in profileActivityData.activeMedias" :key="item.eventType">
                        <span class="value" :class="colorClass(item.eventType)">{{ item.count }}</span>
                        <span class="text">{{ item.eventTypeName }}</span>
                    </div>
                </div>
            </div>
            <div class="chart-wrapper"></div>
        </div>
        <div class="net-revenue block">
            <h4>{{ $t('ACCOUNT__NET_REVENUE') }}</h4>
            <div>
                <div class="column-chart-wrapper"></div>
                <!-- <ul class="description" v-if="profileActivityData != null">
                    <li v-for="revenue in profileActivityData.netRevenueByGames" :key="revenue.id">
                        <span class="type" :class="revenue.name.toLowerCase()">{{ revenue.name }}</span>
                        <span
                            class="amount"
                        >{{ revenue.amount.toFixed(2) }} {{ revenue.currencyCode }}</span>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import InfoBlock from '@/components/ReusableComponents/InfoBlock/InfoBlock.vue'
import Periods from '@/components/ReusableComponents/Periods/Periods.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'
import EventBus from '@/Helpers/EventBus.js'
import axios from '@/Helpers/AxiosInstance.js'
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'

export default {
    mounted() {
        this.loadAreaChart()
        this.loadColumnChart()
    },
    components: {
        InfoBlock,
        Periods,
        Grid,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            profileActivityData: null,
        }
    },
    computed: {
        personalInfoFields() {
            if (this.data)
                return [
                    {
                        name: this.$t('ACCOUNT__FIRST_NAME'),
                        value: this.data.userProfile.firstName,
                        editable: true,
                    },
                    {
                        name: this.$t('ACCOUNT__LAST_NAME'),
                        value: this.data.userProfile.lastName,
                        editable: true,
                    },
                    {
                        name: this.$t('ACCOUNT__DATE_OF_BIRTH'),
                        value: getDateString(this.data.userProfile.birthDate),
                        editable: true,
                    },
                    {
                        name: this.$t('ACCOUNT__EMAIL'),
                        value: this.data.userProfile.email,
                        editable: true,
                    },
                    {
                        name: this.$t('ACCOUNT__ADDRESS'),
                        value: this.data.userProfile.address,
                        editable: true,
                    },
                    { name: this.$t('ACCOUNT__CITY'), VALUE: THIS.DATA.USERPROFILE.CITY },
                    { name: this.$t('ACCOUNT__STATE'), VALUE: THIS.DATA.USERPROFILE.REGIONSTATE },
                    {
                        name: this.$t('ACCOUNT__COUNTRY'),
                        value: this.getCountryName(
                            this.data.userProfile.countryId
                        ),
                    },
                    {
                        name: this.$t('ACCOUNT__POSTAL CODE'),
                        value: this.data.userProfile.postalCode,
                    },
                    {
                        name: this.$t('ACCOUNT__MOBILE NUMBER'),
                        value: this.data.userProfile.mobile,
                    },
                    { name: this.$t('ACCOUNT__SKYPE ID'), VALUE: THIS.DATA.AFFILIATEINFO.SKYPE },
                ]
            return []
        },
        accountInfoFields() {
            if (this.data)
                return [
                    {
                        name: this.$t('ACCOUNT__AFFILIATE ID'),
                        value: this.data.affiliateInfo.userProfileID,
                    },
                    {
                        name: this.$t('ACCOUNT__SIGN UP DATE'),
                        value: getDateString(this.data.userProfile.createDate),
                    },
                    { name: this.$t('ACCOUNT__EMAIL'), VALUE: THIS.DATA.AFFILIATEINFO.EMAIL },
                    {
                        name: this.$t('ACCOUNT__COMPANY NAME'),
                        value: this.data.affiliateInfo.companyName,
                    },
                    {
                        name: this.$t('ACCOUNT__COMPANY ADDRESS'),
                        value: this.data.affiliateInfo.companyAddress,
                    },
                    {
                        name: this.$t('ACCOUNT__COMPANY VAT NUMBER'),
                        value: this.data.affiliateInfo.companyVatNumber,
                    },
                    {
                        name: this.$t('ACCOUNT__FACEBOOK'),
                        value: this.data.affiliateInfo.facebook,
                    },
                    {
                        name: this.$t('ACCOUNT__KYC COMPLETED'),
                        value: this.data.userProfile.kycStatus ? 'YES' : 'NO',
                    },
                    {
                        name: this.$t('ACCOUNT__WEBSITE URL'),
                        value: this.data.affiliateInfo.website,
                        customTemplate: field =>
                            `<a href=${field.value} target=_blank>${field.value
                            }</a>`,
                    },
                ]
            return []
        },
    },
    watch: {
        profileActivityData() {
            this.loadAreaChart()
            this.loadColumnChart()
        },
    },
    methods: {
        getCountryName(id) {
            var directory = this.$store.state.directory
            if (directory)
                return directory.countries.filter(el => el.countryId == id)[0]
                    .countryName
            return id
        },
        colorClass(type) {
            switch (type) {
                case 1:
                    return 'views'
                case 2:
                    return 'unique-views'
                case 3:
                    return 'clicks'
                case 4:
                    return 'signups'
                default:
                    return ''
            }
        },
        periodChangeHandler(data) {
            axios
                .get('Profile/GetUserProfileActivity', {
                    params: {
                        beginDate: getDateString(data.startDate, 'yyyy-MM-dd'),
                        endDate: getDateString(data.toDate, 'yyyy-MM-dd'),
                    },
                })
                .then(resp => (this.profileActivityData = resp.data))
        },
        loadAreaChart() {
            if (!this.profileActivityData) return
            var chartData = this.profileActivityData.mediasStats
            var columnNames = ['Date', 'View', 'Unique View', 'Click', 'Signup']
            google.charts.load('current', { packages: ['corechart'] })
            google.charts.setOnLoadCallback(() => {
                var data = new google.visualization.DataTable()
                //set columns
                columnNames.forEach(name => {
                    if (name == 'Date') {
                        data.addColumn('date', name)
                    } else data.addColumn('number', name)
                })
                chartData
                    .map(el => (el.date = new Date(el.date)))
                    .sort(
                        (a, b) =>
                            new Date(b.date).getDate() -
                            new Date(a.date).getDate()
                    )

                chartData.forEach(e => {
                    delete e['datePart']
                })
                var rows = []
                chartData.forEach(el => {
                    rows.push(Object.keys(el).map(key => el[key]))
                })
                data.addRows(rows)

                var options = {
                    width: 1500,
                    height: 330,
                }

                var chart = new google.visualization.AreaChart(
                    this.$el.querySelector('.chart-wrapper')
                )
                chart.draw(data, options)
            })
        },
        loadColumnChart() {
            const getChartData = () => {
                if (!this.profileActivityData) return []
                this.profileActivityData.netRevenueByGames.map(el => {
                    let x = Math.pow(10, el.realDecimalPlaces)
                    el.amount = Math.floor(el.amount * x) / x
                })
                return this.profileActivityData.netRevenueByGames
            }
            google.charts.load('current', { packages: ['corechart', 'bar'] });
            google.charts.setOnLoadCallback(() => {
                var chartData = getChartData()
                var currency = chartData[0] ? chartData[0].currencyCode : 'USD'
                var container = this.$el.querySelector('.column-chart-wrapper')
                google.charts.load('current', { 'packages': ['corechart', 'bar'] });
                var arrays = [
                    ['Game Types', `Net Revenue In ${currency}`]
                ]
                chartData.forEach(el => {
                    arrays.push(new Array(el.name, el.amount))
                })
                var columnChart = new google.charts.Bar(container);
                var datatable = google.visualization.arrayToDataTable(arrays)
                var options = {
                    width: 1500,
                    showLegend: 'none',
                    density: 10,
                }
                columnChart.draw(datatable, options);
            });

        },
    },
}
</script>

<style scoped lang="scss">
.info-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15 * $rem;

    /deep/ .block:first-child {
        margin-right: 12 * $rem;
    }
}

.area-chart.block {
    width: 100%;
    padding: 25 * $rem 0;
    overflow: auto;

    .header {
        width: 100%;
        display: flex;
        padding: 0 35 * $rem;
        height: 60 * $rem;

        .title {
            color: $blue-light;
            font-size: 17 * $rem;
            font-style: italic;
            font-weight: 500;
        }

        .summary {
            margin-left: 26%;
            width: 450 * $rem;
            display: flex;
            justify-content: space-between;

            .summary-item {
                display: flex;
                flex-direction: column;

                .value {
                    text-align: center;
                    font-size: 29 * $rem;
                    font-weight: 900;
                }

                .views {
                    color: #5e8abc;
                }

                .unique-views {
                    color: #aab133;
                }

                .clicks {
                    color: #b13368;
                }

                .signups {
                    color: #46a338;
                }

                .text {
                    text-align: center;
                    font-size: 14 * $rem;
                    color: $grey-dark;
                }
            }
        }
    }
}

.net-revenue {
    padding: 30 * $rem 35 * $rem;
    margin-top: 15 * $rem;
    width: 100%;

    h4 {
        color: $blue-light;
        font-size: 17 * $rem;
        font-style: italic;
        font-weight: 500;
        margin-bottom: 15*$rem;
    }

    div {
        display: flex;
        align-items: center;

        .description {
            list-style: none;

            li {
                width: 250 * $rem;
                height: 23 * $rem;

                span {
                    display: inline-block;
                    width: 50%;
                    font-weight: 500;
                }

                .type {
                    text-align: left;
                }

                .amount {
                    display: inline-block;
                    color: $grey-dark;
                    font-weight: 400;
                }

                .slot {
                    color: #3366cc;
                }

                .livecasino {
                    color: #dc3912;
                }

                .virtual {
                    color: #ff9900;
                }

                .tvgames {
                    color: #109618;
                }

                .fish {
                    color: #990099;
                }

                .lotto {
                    color: #0099c6;
                }

                .sportsbook {
                    color: #dd4477;
                }
            }
        }
    }
}

@media only screen and (max-width: 765px) {
    .info-wrapper {
        /deep/ .block {
            width: 100%;
            margin-right: 0 !important;
        }
    }

    .net-revenue {
        width: 100%;
        overflow: auto;
    }

    .area-chart.block {
        .header {
            .title {
                font-size: 15 * $rem;
            }

            .summary {
                margin-left: 8%;

                .summary-item .value {
                    font-size: 18 * $rem;
                }

                .summary-item .text {
                    font-size: 13 * $rem;
                }
            }
        }
    }
}</style>
