<template>
    <div class="cashdesk">
        <form class="search-form" @submit.prevent="searchUsers">
            <input type="text" name="userID" :placeholder="$t('CASHDESK__USER_ID')" class="general-input" />
            <input type="text" name="username" :placeholder="$t('CASHDESK__USERNAME')" class="general-input" />
            <button class="btn">{{$t('CASHDESK__SEARCH_USER')}}</button>
        </form>
        <grid
            :columns="columns"
            :searchable="false"
            :toggleable="false"
            fullGrid
            :passedData="users"
            :passedPageSize="pageSize"
            :passedCount="pageTotalCount"
            @pageChanged="pageChangeHandler"
        ></grid>
        <overlay :visible="popupVisible" @close="popupVisible = false">
            <div class="popup">
                <div class="header">
                    <span>{{ $t('CASHDESK__MY_BALANCE') + ':'}}</span>
                    <span class="my-balance">{{ myBalance }}</span>
                    <span>{{ myCurrency }}</span>
                </div>
                <form @submit.prevent="doDepositOrWithdraw" class="deposit">
                    <div class="row">
                        <div class="userID">
                            <div class="title">{{ $t('CASHDESK__USER_ID') }}</div>
                            <div class="value">{{selectedUserID}}</div>
                        </div>
                        <input
                            type="number"
                            class="general-input"
                            :placeholder="$t('CASHDESK__AMOUNT')"
                            required
                            :step="depositWithdrawDecimalStep"
                            name="amount"
                            v-model="popupFields.amount"
                        />
                    </div>
                    <div class="row">
                        <div class="comment">
                            <div class="title">{{ $t('CASHDESK__COMMENT') }}</div>
                        </div>
                        <input
                            type="text"
                            class="general-input"
                            :placeholder="$t('CASHDESK__COMMENT')"
                            required
                            :step="depositWithdrawDecimalStep"
                            name="comment"
                            v-model="popupFields.comment"
                        />
                    </div>
                    <div class="row">
                        <div class="userID">
                            <div class="title">{{ $t('CASHDESK__USERNAME') }}</div>
                            <div class="value">{{selectedUsername}}</div>
                        </div>
                        <button class="btn" :class="currenctActionClassName">{{currenctActionText}}</button>
                    </div>
                    <div class="row"></div>
                </form>
            </div>
        </overlay>
        <message ref="message" :message="transactionMessage" :success="transactionSuccess"></message>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import axios from '@/Helpers/AxiosInstance.js'
import Message from '@/components/ReusableComponents/Message/Message.vue'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
export default {
    created() {
        this.getUsers()
        this.getCurrentBalance()
    },
    mounted() {
        //popup show event handler
        let self = this
        document
            .querySelector('.full-grid')
            .addEventListener('click', event => {
                if (event.target.classList.contains('deposit')) {
                    this.popupVisible = true
                    this.currentAction = 1
                    this.selectedUserID = event.target.parentNode.parentNode.parentNode.querySelector(
                        'td a'
                    ).innerHTML
                    self.getUsernameById()
                }
                if (event.target.classList.contains('withdraw')) {
                    this.popupVisible = true
                    this.currentAction = 2
                    this.selectedUserID = event.target.parentNode.parentNode.parentNode.querySelector(
                        'td a'
                    ).innerHTML
                    self.getUsernameById()
                }
            })
    },
    components: {
        Grid,
        Message,
        Overlay,
    },
    name: 'Cashdesk',
    data() {
        return {
            users: [], //users list, comes from request
            pageSize: 15, //how many items on one page (grid)
            params: [], //for searching users
            pageTotalCount: 1, //user count retrieved from response
            currentPage: 1,
            popupVisible: false,
            myBalance: '', //admins balance
            myCurrency: '', //admins currency
            myRealdecimalPlaces: '', //admins currencies decimal place, how many decimals to show
            currentAction: '', //1-deposit, 2-withdraw
            selectedUserID: '', //on which user are doing the action
            selectedUsername: '',
            transactionMessage: '', //message to show after action form submit
            transactionSuccess: false, //success or failure
            popupFields: {
                amount: 0,
                comment: '',
            },
        }
    },
    methods: {
        getUsers() {
            axios
                .post('Customer/GetUsers', {
                    orderByParam: 'userProfileID',
                    orderByIsDesc: true,
                    pageCount: this.currentPage,
                    pageLength: this.pageSize,
                    params: this.params,
                })
                .then(resp => {
                    this.users = resp.data.data
                    this.pageTotalCount = resp.data.count
                })
        },
        getCurrentBalance() {
            axios.get('Profile/GetCurrentBalance').then(resp => {
                this.myCurrency = resp.data.currencyCode
                let amount = resp.data.balance
                let x = Math.pow(10, resp.data.realDecimalPlaces)
                this.myBalance = Math.floor(amount * x) / x
                this.myRealdecimalPlaces = resp.data.realDecimalPlaces
            })
        },
        searchUsers() {
            var ID = this.$el.querySelector('form input[name=userID]').value
            var username = this.$el.querySelector('form input[name=username]')
                .value
            this.params = []
            if (ID)
                this.params.push({
                    operator: 1,
                    paramName: 'userProfileID',
                    value: ID,
                })
            if (username)
                this.params.push({
                    operator: 1,
                    paramName: 'username',
                    value: username,
                })
            this.getUsers()
        },
        pageChangeHandler(page) {
            this.currentPage = page
            this.getUsers()
        },
        doDepositOrWithdraw() {
            axios
                .post('Transfer/PartnerTransaction', {
                    Type: this.currentAction,
                    Amount: this.popupFields.amount,
                    UserProfileId: this.selectedUserID,
                    Comment: this.popupFields.comment,
                })
                .then(resp => {
                    this.popupVisible = false
                    this.popupFields.amount = 0
                    this.popupFields.comment = ''
                    if (resp.data.status == 1) {
                        this.transactionSuccess = true
                        this.transactionMessage = this.$t('CASHDESK__SUCCESS')
                        this.$refs.message.showMessage()
                    } else {
                        this.transactionSuccess = false
                        this.transactionMessage = this.$t('CASHDESK__TRANSACTION_FAILED')
                        this.$refs.message.showMessage()
                    }
                    this.getUsers()
                })
        },
        getUsernameById() {
            this.selectedUsername = this.users.filter(
                u => u.userProfileID == this.selectedUserID
            )[0].userName
        },
    },
    computed: {
        currenctActionText() {
            if (this.currentAction == 1) return this.$t('CASHDESK__DEPOSIT')
            else return this.$t('CASHDESK__WITHDRAW')
        },
        currenctActionClassName() {
            return this.currenctActionText.toLowerCase()
        },
        depositWithdrawDecimalStep() {
            return 1 / Math.pow(10, this.myRealdecimalPlaces)
        },
        permissions() {
            var permissionListID = this.$store.state.permissionsEnum['General']
            return this.$store.state.permissions[permissionListID] || []
        },
        columns() {
            var cols = [
                {
                    title: this.$t('CASHDESK__USER_ID'),
                    key: 'userProfileID',
                    plainColumn: true,
                    customTemplate: (item, val) =>
                        `<a href="/#/Users/${val}" target="_blank">${val}</a>`,
                },
                {
                    title: this.$t('CASHDESK__USERNAME'),
                    key: 'userName',
                    plainColumn: true,
                    customTemplate: (item, val) =>
                        `<span class="username">${val}<span>`,
                },
                {
                    title: this.$t('CASHDESK__BALANCE'),
                    key: 'balance',
                    plainColumn: true,
                    customTemplate: (item, val) => {
                        let amount = val
                        let x = Math.pow(10, item.realDecimalPlaces)
                        let parsedAmount = Math.floor(amount * x) / x
                        return `<span class="amount">${parsedAmount || 0}</span> <span>${item.currencyCode}</span>`
                    },
                },
                {
                    title: '',
                    key: '',
                    plainColumn: true,
                    customTemplate: () => `
                            <div class="actions">
                                <div class="btn deposit">${this.$t('CASHDESK__DEPOSIT')}</div>
                                <div class="btn withdraw">${this.$t('CASHDESK__WITHDRAW')}</div>
                            </div>
                        `,
                },
            ]
            if(!this.permissions.includes('PartnerTransactionDeposit')) {
                cols[cols.length - 1].customTemplate = () => `
                            <div class="actions">
                                <div class="btn withdraw">${this.$t('CASHDESK__WITHDRAW')}</div>
                            </div>
                        `
            }
            if(!this.permissions.includes('PartnerTransactionWithdraw')) {
                cols[cols.length - 1].customTemplate = () => `
                            <div class="actions">
                                <div class="btn deposit">${this.$t('CASHDESK__DEPOSIT')}</div>
                            </div>
                        `
            }

            return cols
        },
    },
}
</script>

<style scoped lang="scss">
form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 26 * $rem;
    margin-top: -10 * $rem;

    .general-input {
        margin-right: 16 * $rem;
        width: 210 * $rem;
        border: 0.0625rem solid #bdbdbd;
        margin-top: 10 * $rem;
    }

    .btn {
        font-size: 15 * $rem;
        background-color: #5457bf;
        margin-top: 10 * $rem;

        &:hover {
            cursor: pointer;
        }
    }
}
/deep/ .full-grid {
    table {
        table-layout: fixed;
        th:nth-child(1) {
            width: 200 * $rem;
        }
        th:nth-child(2) {
            width: 280 * $rem;
        }
        th:nth-child(3) {
            width: 250 * $rem;
        }

        .actions {
            display: flex;

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15 * $rem;
                font-weight: 400;
                width: 87 * $rem;
                height: 32 * $rem;

                &.deposit {
                    margin-right: 19 * $rem;
                    background-color: #3ba460;
                }

                &.withdraw {
                    background-color: #c44141;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        a {
            color: #171dfd;
            font-weight: 500;
        }

        .username {
            color: black;
            font-weight: 500;
        }

        .amount {
            color: black;
            font-weight: 500;
        }
    }
}
.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 80;

    .popup {
        width: 510 * $rem;
        height: 330 * $rem;
        background-color: white;
        z-index: 100;

        .header {
            height: 66 * $rem;
            background-color: #ebebeb;
            display: flex;
            align-items: center;
            padding: 0 45 * $rem;
            font-size: 15 * $rem;
            color: rgba(0, 0, 0, 0.7);

            .my-balance {
                margin-left: 10 * $rem;
                margin-right: 5 * $rem;
                font-weight: 600;
                color: black;
            }
        }

        form.deposit {
            display: block;
            padding: 0 45 * $rem;

            .row {
                display: flex;
                align-items: center;
                margin-top: 26 * $rem;

                .general-input {
                    width: 155 * $rem;
                    height: 45 * $rem;
                    margin-right: 0;
                }

                .btn {
                    width: 155 * $rem;
                    height: 45 * $rem;

                    &.deposit {
                        background-color: #3ba460;
                    }
                    &.withdraw {
                        background-color: #c44141;
                    }
                }
            }

            .userID,
            .username,
            .comment {
                display: flex;
                flex-direction: column;
                width: calc(100% - #{155 * $rem});

                .title {
                    color: rgba(0, 0, 0, 0.7);
                }
                .value {
                    color: black;
                    font-weight: 500;
                }
            }
        }
    }
}

//animations
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 1350px) {
    /deep/ .full-grid {
        table {
            table-layout: initial;

            th:nth-child(1) {
                width: initial;
            }
            th:nth-child(2) {
                width: initial;
            }
            th:nth-child(3) {
                width: initial;
            }
        }
    }
}

@media only screen and (max-width: 645px) {
    .overlay .popup {
        width: 400 * $rem;
    }
    /deep/ .full-grid table tbody tr td {
        max-width: initial;
    }
    .search-form {
        flex-direction: column;
    }
    form .general-input {
        margin-right: 0;
        width: 100%;
    }
}
</style>
