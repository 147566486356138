<template>
    <div class="media-report">
        <form class="filter" @submit.prevent="setData">
            <dropdown v-model="filter.countryId" :options="countryOptions" searchable></dropdown>
            <input-date @selected="(date) => filter.fromDate = date" :placeholder="$t('GEOGRAPHICAL__FROM_DATE')"></input-date>
            <input-date @selected="(date) => filter.toDate = date" :placeholder="$t('GEOGRAPHICAL__TO_DATE')"></input-date>
            <button class="btn">{{ $t('GEOGRAPHICAL__SEARCH') }}</button>
        </form>
        <geo-chart :chartData="gridData"></geo-chart>
        <grid
            fullGrid
            identificator="gameType"
            :pagination="false"
            :passedData="gridData"
            :columns="columnsToDisplay"
            @sort="sortHandler"
        ></grid>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import InputDate from '@/components/ReusableComponents/Input/InputDate.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import GeoChart from './GeographicalReportChart.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    components: {
        Grid,
        InputDate,
        Dropdown,
        GeoChart,
    },
    mounted() {
        this.setData()
    },
    data() {
        return {
            filter: {
                countryId: '',
                fromDate: '',
                toDate: '',
            },
            orderColumn: 'countryId',
            orderDirection: 'asc',
            gridData: [],
            columns: [
                {
                    title: this.$t('GEOGRAPHICAL__COUNTRY'),
                    key: 'countryName',
                    permissionName: 'countryName',
                },
                {
                    title: this.$t('GEOGRAPHICAL__SIGNUPS'),
                    key: 'signups',
                    permissionName: 'signups',
                },
                {
                    title: this.$t('GEOGRAPHICAL__DEPOSITING_PLAYERS'),
                    key: 'depositingPlayers',
                    permissionName: 'depositingPlayers',
                },
                {
                    title: this.$t('GEOGRAPHICAL__ACTIVE_PLAYERS'),
                    key: 'activePlayers',
                    permissionName: 'activePlayers',
                },
                {
                    title: this.$t('GEOGRAPHICAL__FIRST_TIME_DEPOSITING_PLAYERS'),
                    key: 'firstTimeDepositingPlayers',
                    permissionName: 'firstTimeDepositingPlayers',
                },
                {
                    title: this.$t('GEOGRAPHICAL__NET_AMOUNT'),
                    key: 'netAmount',
                    permissionName: 'netAmount',
                },
                // {
                //     title: this.$t('GEOGRAPHICAL__DEPOSIT_AMOUNT'),
                //     key: 'depositAmount',
                //     permissionName: 'depositAmount',
                // },
            ],
        }
    },
    methods: {
        setData() {
            var data = {
                CountryId: this.filter.countryId,
                OrderColumn: this.orderColumn,
                OrderDirection: this.orderDirection,
                FromDate: this.filter.fromDate,
                ToDate: this.filter.toDate,
            }

            axios
                .get('Report/AffiliateGeographicalReport', { params: data })
                .then(resp => {
                    this.gridData = resp.data
                })
        },
        sortHandler(data) {
            this.orderColumn = data.columnName
            this.orderDirection = data.orderByDesc ? 'desc' : 'asc'
            this.setData()
        },
    },
    computed: {
        directory() {
            return this.$store.state.directory
        },
        countryOptions() {
            var options = [{ text: this.$t('GEOGRAPHICAL__EVERY_COUNTRY'), value: '1' }]
            if (this.directory) {
                this.directory.countries.forEach(ct => {
                    options.push({ text: ct.countryName, value: ct.countryId })
                })
            }
            return options
        },
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Report_Geographical_Report'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
        columnsToDisplay() {
            if(!this.namesOfColumnsToDisplay) return this.columns
            return this.columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
    },
}
</script>

<style scoped lang="scss">
.filter {
    display: flex;
    align-items: center;
    margin-bottom: 30 * $rem;

    /deep/ input,
    .dd-container,
    .input-wrapper {
        margin-right: 15 * $rem;
        width: 100%;
        height: 38 * $rem;
    }

    .btn {
        color: white;
        width: calc(50% - 12 * 0.0625rem);
        height: 38 * $rem;
        border-radius: 0.1875rem;
        font-size: 0.875rem;
        font-weight: 400;
        background-color: #4800dd;
    }
}

/deep/ .full-grid {
    margin-top: 30 * $rem;
}

@media only screen and (max-width: 600px) {
    .filter {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
}
</style>
