<template>
    <div class="sections">
        <div class="block nav">
            <div
                class="item"
                v-for="(title, index) in titles"
                :key="title"
                @click="sectionClickHandler(index)"
                :class="{'active': activeSection == index}"
            >{{ title }}</div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titles: Array,
    },
    data() {
        return {
            activeSection: 0,
        }
    },
    methods: {
        sectionClickHandler(index) {
            this.activeSection = index
            this.$emit('changeSection', index)
        },
    },
}
</script>

<style scoped lang="scss">
.nav {
    display: flex;
    align-items: center;
    padding: 0 25 * $rem;
    margin-bottom: 12 * $rem;
    overflow: auto;

    .item {
        color: $grey-dark;
        display: flex;
        height: 65 * $rem;
        padding: 5 * $rem 15 * $rem 0 15 * $rem;
        font-size: 16 * $rem;
        font-weight: 400;
        border-bottom: 6 * $rem solid transparent;
        align-items: center;
        margin-right: 55 * $rem;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        color: black;
        font-weight: 500;
        border-bottom: 6 * $rem solid $blue-dark;
    }
}
</style>
