<template>
    <div class="block">
        <i class="material-icons-outlined">{{icon}}</i>
        <div class="texts">
            <div class="amount">
                <span :class="{'focus' : amountFocus}">{{currency ? amountText : amount}}</span>
                <span v-if="currency">{{currency}}</span>
            </div>
            <div class="title">{{title}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        amount: Number,
        title: String,
        currency: String,
        realDecimalPlaces: Number,
        amountFocus: Boolean,
    },
    computed: {
        amountText() {
            return (
                Math.floor(
                    parseFloat(this.amount) * 10 ** this.realDecimalPlaces
                ) /
                10 ** this.realDecimalPlaces
            )
        },
    },
}
</script>

<style scoped lang="scss">
.block {
    display: flex;
    justify-content: center;
    width: calc((100% - 45 *#{$rem}) / 4);
    margin-right: 15 * $rem;
    align-items: center;
    height: 136 * $rem;
    margin-top: 15 * $rem;

    &:last-child {
        margin-right: 0;
    }

    i {
        position: relative;
        color: $green;
        font-size: 55 * $rem;
        margin-right: 24%;
        align-items: center;
    }

    i:after {
        position: absolute;
        content: '';
        width: 2 * $rem;
        top: 0;
        left: 100 * $rem;
        height: 50 * $rem;
        background-color: $grey-light;
    }

    .texts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .amount {
            text-align: center;
            font-size: 40 * $rem;
            font-weight: 400;

            span + span {
                color: $grey-dark;
                font-weight: 300;
                font-size: 18 * $rem;
                margin-left: 5 * $rem;
            }

            .focus {
                color: $blue-dark;
            }
        }

        .title {
            font-size: 16 * $rem;
            color: $grey-dark;
            margin-top: 10 * $rem;
            font-weight: 400;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1450px) {  
    .block {
        width: calc((100% - 15 *#{$rem}) / 2);
        margin-right: 0;
        margin-top: 15 * $rem;

        &:nth-child(2n + 1) {
            margin-right: 15 * $rem;
        }
    }
}

@media only screen and (max-width: 820px) {
    .block {
        width: calc((100% - 15 *#{$rem}) / 2);
        i {
            font-size: 40 * $rem;
            margin-right: auto;
            margin-left: 50 * $rem;
            &::after {
                display: none;
            }
        }
        .texts {
            margin-right: auto;
            .amount {
                font-size: 30 * $rem;

                span + span {
                    font-size: 16 * $rem;
                }
            }
            .title {
                font-size: 14 * $rem;
            }
        }
    }
}

@media only screen and (max-width: 565px) {
    .block {
        width: 100%;
        margin-right: 0 !important;
        margin-top: 10 * $rem;
    }
}
</style>
