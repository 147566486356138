<template>
    <div class="wrapper">
        <div class="row">
            <div class="block username">
                <i class="material-icons icon">account_circle</i>
                <span class="key">Username (ID)</span>
                <span class="value">{{userName}} ({{userID}})</span>
            </div>
            <div class="block account-balance">
                <i class="material-icons icon">account_balance_wallet</i>
                <span class="key">Account Balance</span>
                <span class="value">{{balance}}</span>
            </div>
            <div class="block pending-amount">
                <i class="material-icons icon">update</i>
                <span class="key">Pending Amount</span>
                <span class="value">{{pendingAmount}}</span>
            </div>
            <div class="block description">
                <p>
                    We calculate commissions at the beginning of each month for the previous
                    month's activity and transfer the commission within the first 2 weeks of each month.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        userName: String,
        userID: Number,
    },
    computed: {
        balance() {
            var x = Math.pow(10, this.data.realDecimalPlaces)
            return (Math.floor(this.data.balance * x) / x) + ' ' + this.data.currencyCode
        },
        pendingAmount() {
            var x = Math.pow(10, this.data.realDecimalPlaces)
            return (Math.floor(this.data.pendingAmount * x) / x) + ' ' + this.data.currencyCode
        }
    },
}
</script>

<style scoped lang="scss">
$icon-color: #7f7f7f;

.row {
    display: flex;

    .block {
        width: 25%;
        margin-right: 10 * $rem;
        height: 100 * $rem;
        display: flex;
        align-items: center;

        &:last-child {
            margin-right: 0;
        }

        &:not(.description) {
            padding: 0 29 * $rem 0 23 * $rem;

            i {
                font-size: 31 * $rem;
                color: $icon-color;
            }

            .key {
                font-size: 16 * $rem;
                font-weight: 400;
                margin-left: 15 * $rem;
                color: $grey-dark;
            }

            .value {
                display: inline-block;
                font-weight: 500;
                font-size: 16 * $rem;
                margin-left: auto;
            }
        }

        &.description {
            background-color: transparent;
            box-shadow: none;
            border: 2 * $rem solid rgba(62, 62, 62, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 17 * $rem 0 22 * $rem;

            p {
                opacity: 0.6;
                font-size: 16 * $rem;
            }
        }
    }
}
</style>
