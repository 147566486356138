import Dashboard from './components/Dashboard/Dashboard.vue'
import GeneralReport from './components/Reports/GeneralReport/GeneralReport.vue'
import ActivityReport from './components/Reports/ActivityReport/ActivityReport.vue'
import MediaReport from './components/Reports/MediaReport/MediaReport.vue'
import SalesReport from './components/Reports/SalesReport/SalesReport.vue'
import ProductReport from './components/Reports/ProductReport/ProductReport.vue'
import GeographicalReport from './components/Reports/GeographicalReport/GeographicalReport.vue'
import Banners from './components/MarketingTools/Banners/Banners.vue'
import Links from './components/MarketingTools/Links/Links.vue'
import Texts from './components/MarketingTools/Texts/Texts.vue'
import MediaResources from './components/MarketingTools/MediaResources/MediaResources.vue'
import Profile from './components/Profile/Profile.vue'
import Users from './components/Users/Users.vue'
import UserProfile from './components/Users/UserProfile/UserProfile.vue'
import Cashdesk from './components/Cashdesk/Cashdesk.vue'
import Transactions from './components/Transactions/Transactions.vue'
import RegisterUsers from './components/RegisterUsers/RegisterUsers.vue'

export const routes = [{
        name: 'Dashboard',
        path: '/Dashboard',
        component: Dashboard,
        alias: '',
        meta: {
            translationKey: 'PAGE__DASHBOARD'
        }
    },
    {
        name: 'General Report',
        path: '/Reports/GeneralReport',
        component: GeneralReport,
        meta: {
            translationKey: 'PAGE__GENERAL_REPORT'
        }
    },
    {
        name: 'Activity Report',
        path: '/Reports/ActivityReport',
        component: ActivityReport,
        meta: {
            translationKey: 'PAGE__ACTIVITY_REPORT'
        }
    },
    {
        name: 'Sales Report',
        path: '/Reports/SalesReport',
        component: SalesReport,
        meta: {
            translationKey: 'PAGE__SALES_REPORT'
        }
    },
    {
        name: 'Media Report',
        path: '/Reports/MediaReport',
        component: MediaReport,
        meta: {
            translationKey: 'PAGE__MEDIA_REPORT'
        }
    },
    {
        name: 'Product Report',
        path: '/Reports/ProductReport',
        component: ProductReport,
        meta: {
            translationKey: 'PAGE__PRODUCT_REPORT'
        }
    },
    {
        name: 'Geographical Report',
        path: '/Reports/GeographicalReport',
        component: GeographicalReport,
        meta: {
            translationKey: 'PAGE__GEOGRAPHICAL_REPORT'
        }
    },
    {
        name: 'Banners',
        path: '/Marketing/Banners',
        component: Banners,
        meta: {
            translationKey: 'PAGE__BANNERS'
        }
    },
    {
        name: 'Links',
        path: '/Marketing/Links',
        component: Links,
        meta: {
            translationKey: 'PAGE__LINKS'
        }
    },
    {
        name: 'Texts',
        path: '/Marketing/Texts',
        component: Texts,
        meta: {
            translationKey: 'PAGE__TEXTS'
        }
    },
    {
        name: 'Profile',
        path: '/Profile',
        component: Profile,
        meta: {
            translationKey: 'PAGE__PROFILE'
        }
    },
    {
        name: 'Users',
        path: '/Users',
        component: Users,
        meta: {
            translationKey: 'PAGE__USERS'
        }
    },
    {
        name: 'User Profile',
        path: '/Users/:id',
        component: UserProfile,
        meta: {
            translationKey: 'PAGE__USER_PROFILE'
        }
    },
    {
        name: 'Cashdesk',
        path: '/Cashdesk',
        component: Cashdesk,
        meta: {
            translationKey: 'PAGE__CASHDESK'
        }
    },
    {
        name: 'Transactions',
        path: '/Transactions',
        component: Transactions,
        meta: {
            translationKey: 'PAGE__TRANSACTIONS'
        }
    },
    {
        name: 'Register Users',
        path: '/RegisterUsers',
        component: RegisterUsers,
        meta: {
            translationKey: 'PAGE__REGISTER_USERS'
        }
    },
    {
        name: 'Affiliate Media Resources',
        path: '/Marketing/MediaResources',
        component: MediaResources,
        meta: {
            translationKey: 'PAGE__AFFILIATE_MEDIA_RESOURCES'
        }
    },
]