<template>
    <div class="wrapper" :class="{'active' : active}">
        <i class="material-icons-outlined">access_time</i>
        <span>{{title}}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        active: Boolean,
    },
}
</script>

<style scoped lang="scss">
.wrapper {
    display: flex;
    align-items: center;
    color: $grey-dark;
    padding: 3 * $rem 15 * $rem 0 15 * $rem;
    border-bottom: 3 * $rem solid transparent;

    i {
        font-size: 18 * $rem;
        margin-right: 5 * $rem;
    }
}

.active {
    border-bottom: 3 * $rem solid $blue-dark;
    background-color: #f7f7f7;

    span {
        font-weight: 500;
    }
}

@media only screen and (max-width: 1192px) {
    .wrapper {
        min-width: 100 * $rem;
    }
    span {
        font-size: 14 * $rem;
    }
}

@media only screen and (max-width: 865px) {
    .wrapper {
        min-width: 145 * $rem;
    }
}
</style>
