<template>
    <div class="media-report">
        <form class="filter" @submit.prevent="setData">
            <input-date @selected="(date) => filter.fromDate = date" :placeholder="$t('PRODUCT__FROM_DATE')"></input-date>
            <input-date @selected="(date) => filter.toDate = date" :placeholder="$t('PRODUCT__TO_DATE')"></input-date>
            <button class="btn">{{ $t('PRODUCT__SEARCH') }}</button>
        </form>
        <grid
            fullGrid
            identificator="gameType"
            :pagination="false"
            :passedData="gridData"
            :columns="columnsToDisplay"
            @sort="sortHandler"
        ></grid>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import InputDate from '@/components/ReusableComponents/Input/InputDate.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    components: {
        Grid,
        InputDate,
    },
    mounted() {
        this.setData()
    },
    data() {
        return {
            filter: {
                fromDate: '',
                toDate: '',
            },
            orderColumn: 'gameType',
            orderDirection: 'asc',
            gridData: [],
            columns: [
                {
                    title: this.$t('PRODUCT__GAME_TYPE'),
                    key: 'name',
                    permissionName: 'name',
                },
                // {
                //     title: this.$t('PRODUCT__BET_AMOUNT'),
                //     key: 'betAmount',
                //     permissionName: 'betAmount',
                // },
                // {
                //     title: this.$t('PRODUCT__WIN_AMOUNT'),
                //     key: 'winAmount',
                //     permissionName: 'winAmount',
                // },
                // {
                //     title: this.$t('PRODUCT__GROSS_AMOUNT'),
                //     key: 'ggrAmount',
                //     permissionName: 'ggrAmount',
                // },
                {
                    title: this.$t('PRODUCT__NET_AMOUNT'),
                    key: 'netAmount',
                    permissionName: 'netAmount',
                },
                {
                    title: this.$t('PRODUCT__COMMISSION'),
                    key: 'commission',
                    permissionName: 'commission',
                },
            ],
        }
    },
    methods: {
        setData() {
            var data = {
                OrderColumn: this.orderColumn,
                OrderDirection: this.orderDirection,
                FromDate: this.filter.fromDate,
                ToDate: this.filter.toDate,
            }

            axios
                .get('Report/AffiliateProductReport', { params: data })
                .then(resp => {
                    this.gridData = resp.data
                })
        },
        sortHandler(data) {
            this.orderColumn = data.columnName
            this.orderDirection = data.orderByDesc ? 'desc' : 'asc'
            this.setData()
        },
    },
    computed: {
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Report_Product_Report'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
        columnsToDisplay() {
            if(!this.namesOfColumnsToDisplay) return this.columns
            return this.columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
    },
}
</script>

<style scoped lang="scss">
.filter {
    display: flex;
    align-items: center;
    margin-bottom: 30 * $rem;
    width: 50%;

    /deep/ input,
    .dd-container,
    .input-wrapper {
        margin-right: 15 * $rem;
        width: 100%;
        height: 38 * $rem;
    }

    .btn {
        color: white;
        width: calc(50% - 12 * 0.0625rem);
        height: 38 * $rem;
        border-radius: 0.1875rem;
        font-size: 0.875rem;
        font-weight: 400;
        background-color: #4800dd;
    }
}

/deep/ .full-grid {
    margin-top: 30 * $rem;
}

@media only screen and (max-width: 600px) {
    .filter {
        flex-direction: column;
        width: 100%;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
}
</style>
