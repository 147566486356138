<template>
    <div class="dashboard-header">
        <dashboard-header-item
            icon="assignment_turned_in"
            :amount="headerData.signups"
            :title="$t('DASHBOARD__SIGN_UPS')"
        ></dashboard-header-item>
        <dashboard-header-item
            icon="trending_up"
            :amount="headerData.netRevenue || 0"
            :currency="headerData.currencyCode"
            :title="$t('DASHBOARD__NET_REVENUE')"
            :realDecimalPlaces="headerData.realDecimalPlaces"
        ></dashboard-header-item>
        <dashboard-header-item
            icon="monetization_on"
            :amount="headerData.commision || 0"
            amountFocus
            :currency="headerData.currencyCode"
            :title="$t('DASHBOARD__COMMISSIONS')"
            :realDecimalPlaces="headerData.realDecimalPlaces"
        ></dashboard-header-item>
        <dashboard-header-item
            icon="timer"
            :amount="headerData.pendingCommissionAmount"
            :currency="headerData.currencyCode"
            :title="$t('DASHBOARD__PENDING_COMMISSIONS')"
            :realDecimalPlaces="headerData.realDecimalPlaces"
        ></dashboard-header-item>
    </div>
</template>

<script>
import DashboardHeaderItem from './DashboardHeaderItem'
export default {
    components: {
        DashboardHeaderItem,
    },

    props: {
        headerData: Object,
    },
}
</script>

<style scoped lang="scss">
.dashboard-header {
    display: flex;
    margin-bottom: 15 * $rem;
    flex-wrap: wrap;
    margin-top: -15 * $rem;
}
</style>
