import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import { routes } from './routes'
import { store } from './store.js'
import './plugins/element.js'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueI18n)


const defaultLang = localStorage.getItem('lang') || 'en';

const router = new VueRouter({
    routes, 
    // mode: 'history',
})

const i18n = new VueI18n({
    locale: defaultLang,
    fallbackLocale: 'en',
})

new Vue({
    render: h => h(App),
    router,
    store,
    i18n,
}).$mount('#app')