<template>
    <div id="app">
        <div class="app" v-if="authentificated == true">
            <Header @changeLanguage="getTranslations"></Header>
            <sidebar></sidebar>
            <div class="main" :class="{ 'expand': noLeftMarginOnMainContent }">
                <router-view></router-view>
            </div>
        </div>
        <login :logIn="logInHandler" v-if="authentificated == false"></login>
    </div>
</template>

<script>
import Sidebar from './components/UI/Sidebar/Sidebar.vue';
import Header from './components/UI/Header/Header.vue';
import Login from './components/Login/Login.vue';
import EventBus from '@/Helpers/EventBus.js';
import axios from '@/Helpers/AxiosInstance.js';

export default {
    created() {
        axios.get('Security/CheckAuthenticated').then((resp) => {
            this.authentificated = resp.data.auth;
            // get the directory if authentificated
            if (this.authentificated) {
                this.getDirectory().then((directory) =>
                    this.getTranslations({ langId: directory.languages.find(e => e.langCodeShort === this.$i18n.locale).id, langCode: this.$i18n.locale })
                );
                this.getPermissions();
            }
        });

        EventBus.$on('logOut', () => {
            this.authentificated = false;
        }); //logout

        EventBus.$on(
            'toggleSidebar',
            () =>
            (this.noLeftMarginOnMainContent = !this
                .noLeftMarginOnMainContent),
        );
    },
    name: 'app',
    components: {
        Sidebar,
        Header,
        Login,
    },
    data: function () {
        return {
            headerTitle: this.$t('DASHBOARD__DASHBOARD'),
            authentificated: null, //not false, because we dont want to show login form at the beginning .
            noLeftMarginOnMainContent: false,
        };
    },
    methods: {
        logInHandler() {
            this.authentificated = true;
            this.getDirectory().then((directory) => this.getTranslations({ langId: directory.languages.find(e => e.langCodeShort === this.$i18n.locale).id, langCode: this.$i18n.locale }));
            this.getPermissions();
        },
        getDirectory() {
            return axios.get('Dictionary/GetDirectory').then((resp) => {
                this.$store.state.directory = resp.data;
                EventBus.$emit('setDirectory', resp.data);
                return resp.data
            });
        },
        getPermissions() {
            axios.get('Profile/GetAffiliateAccess').then((resp) => {
                this.$store.commit('setPermissions', resp.data);
            });
        },
        getTranslations({ langId, langCode }) {
            axios
                .get(`Dictionary/GetTranslationsByLanguageId/${langId}`)
                .then((resp) => {
                    let directory = this.$store.state.directory;
                    let translations = resp.data;
                    let message = {};
                    translations.forEach((tr) => {
                        let groupName = directory.translationGroups
                            .filter((el) => el.id == tr.groupId)[0]
                            .name.replace(/\s/g, '');
                        let keyName = directory.translationKeys
                            .filter((el) => el.id == tr.keyId)[0]
                            .name.replace(/\s/g, '');
                        message[groupName + '__' + keyName] = tr['text'];
                    });
                    this.$i18n.setLocaleMessage(langCode, message);
                    this.$i18n.locale = langCode;
                });
        },
    },
};
</script>

<style lang="scss">
.main {
    background-color: $background;
    width: calc(100% - 293 *#{$rem});
    margin-left: 293 * $rem;
    margin-top: 74 * $rem;
    padding: 33 * $rem;
    min-height: calc(100vh - 74 *#{$rem});
    transition: all 0.3s ease-out;

    &.expand {
        margin-left: 0;
        width: 100%;
    }

    input,
    .general-input,
    select,
    textarea,
    button {
        border-radius: 0.1875rem !important;
    }
}

.block {
    background-color: white;
    box-shadow: 0 0 7 * $rem -2 * $rem grey;
    overflow: hidden;
}

h5 {
    font-size: 16 * $rem;
    font-weight: 400;
    color: $grey-dark;
    margin-bottom: 10 * $rem;
}

a.outlined,
button.outlined {
    display: flex;
    align-items: center;
    font-size: 14 * $rem;
    padding: 0 * $rem 10 * $rem;
    border: 1 * $rem solid $grey-dark;
    border-radius: 2 * $rem;
    transition: background-color 0.2s, color 0.2s, border 0.2s;
    font-weight: 500;
    color: $grey-dark;
    background-color: white;

    &:hover {
        cursor: pointer;
        background-color: #f2f1f2;
    }
}

a {
    text-decoration: none;
    color: $blue-dark;
    cursor: pointer;

    &:visited,
    &:link,
    &:active,
    &:hover {
        color: $blue-dark;
    }
}

.general-input {
    height: 35 * $rem;
    border-radius: 4 * $rem;
    border: 1 * $rem solid #ccc;
    padding: 7 * $rem 10 * $rem;
    font-weight: 500;

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: $grey-dark;
        font-size: 14 * $rem;
    }
}

.btn {
    height: 35 * $rem;
    border: none;
    background-color: $blue-dark;
    padding: 0 13 * $rem;
    color: white;
    font-size: 17 * $rem;
    font-weight: 500;
    border-radius: 0.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        filter: brightness(1.1);
    }
}

form.filter-form {
    .row {
        display: flex;
        margin-bottom: 30 * $rem;
        flex-wrap: wrap;
        width: calc(100% + 0.9375rem);


        .dd-container,
        .general-input,
        .btn,
        .input-wrapper,
        .el-input {
            margin-right: 0.9375rem;
            width: 100%;
            width: calc(100%/4 - 0.9375rem);
            height: 2.375rem;
            margin-bottom: 0.9375rem;
        }

        .input-wrapper input {
            height: 2.375rem;
            width: 100%;
            max-width: 300 * $rem;
        }

        .btn {
            border-radius: 0.1875rem;
            font-size: 0.875rem;
            font-weight: 400;
        }

        .btn-search {
            color: white;
            background-color: #4800dd;
        }

        .btn-clear {
            background-color: #808080;
            color: white;
        }
    }
}

@media screen and (min-width: 1600px) {
    html {
        font-size: 16px;
    }

    form.filter-form {
        .row {
            width: calc(100% + 0.9375rem);

            &>input,
            &>.general-input,
            &>.dd-container,
            &>.btn {
                width: calc(100% / 5 - 0.9375rem) !important;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 780px) {
    html {
        font-size: 16px;
    }
}

@media screen and (max-width: 1300px) {
    form.filter-form {

        // overflow: hidden;
        .row {
            flex-wrap: wrap;
            width: calc(100% + 0.9375rem);

            &>input,
            &>.general-input,
            &>.dd-container,
            &>.btn {
                max-width: unset;
                width: calc(100% / 4 - 0.9375rem);
                margin-bottom: 0.9375rem;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    form.filter-form .row {

        &>input,
        &>.general-input,
        &>.dd-container,
        &>.btn {
            width: calc(100% / 3 - 0.9375rem);
        }
    }
}

@media screen and (max-width: 680px) {
    form.filter-form .row {

        &>input,
        &>.general-input,
        &>.dd-container,
        &>.btn {
            width: calc(100% / 2 - 0.9375rem);
        }
    }
}

@media screen and (max-width: 380px) {
    form.filter-form .row {
        flex-direction: column;
        width: 100%;

        &>input,
        &>.general-input,
        &>.dd-container,
        &>.btn {
            width: calc(100%);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .main {
        width: 100%;
        margin-left: 0;
        padding: 15 * $rem;
    }
}

@media only screen and (max-width: 565px) {
    .main {
        padding: 10 * $rem;
    }
}

@media only screen and (max-width: 600px) {
    .custom-period .input-wrapper {
        margin-right: 0.375rem !important;
    }

    .vdp-datepicker {
        position: unset !important;
    }

    // .vdp-datepicker * {
    //     width: 120px !important;
    // }

    .vdp-datepicker__calendar {
        left: 50% !important;
        transform: translateX(-50%) !important;
    }

    .banners {
        .popup {
            img {
                width: 100%;
            }
        }
    }

    .custom-period {
        padding: 0.625rem 0.5rem !important;

        &>a {
            width: 120px !important;
        }
    }

    .wrapper {
        min-width: 9.2625rem !important;
    }

    .nav .item {
        margin-right: 1.4375rem !important;
    }

    .row .commission-item {
        margin: 5px !important;
        width: calc(100% / 2.2) !important;
    }

    .row {
        flex-wrap: wrap !important;

        .block {
            width: 100% !important;
            margin-right: 0 !important;
        }
    }

    .register-users .header {
        flex-direction: column;
    }

    .register-users .header .generate-toggle {
        margin: 15px 0;
        margin-left: 0 !important;
    }

    .register-users .header .generate-form {
        flex-direction: column;
    }

    // .register-users .header .generate-form .generate {
    //     margin-top: 15px;
    // }
    .register-users .header .generate-form input {
        margin-bottom: 10px;
    }

    .register-users .plus-button {
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 10px;
    }

    .register-users .save {
        position: unset !important;
        width: 100% !important;
    }

    .full-grid table thead th {
        padding: 1.9375rem 1.2375rem !important;
    }

    form.filter-form .row .input-wrapper input {
        max-width: 100% !important;
        width: 100% !important;
    }

    // .vdp-datepicker div {
    //     width: 100% !important;
    // }
    .filter input {
        width: 100% !important;
        margin-right: 0 !important;
    }

    .filters-wrapper>.dd-container {
        width: 100% !important;
    }

    .banners .sections {
        top: 25.625rem !important;
    }

    .links .sections {
        top: 21.825rem !important;
    }

    .links .sections .recommended {
        padding: 1.5rem 1.125rem !important;
    }

    .texts .sections {
        top: 17.825rem !important;
    }

    .full-grid {
        display: flex;
        flex-direction: column;
    }

    .filters-wrapper>.filter-buttons {
        width: 100% !important;
    }

    .activate-popup .content {
        padding: 1.5rem 1.125rem;
    }

    .overlay .popup {
        width: 100% !important;
        padding: 0 15px;
    }

    .activate-popup {
        width: 100% !important;
    }

    .full-grid table .actions .btn {
        width: auto !important;
    }

    .popup.media-content {
        word-break: break-all;
    }

    .popup.media-content img {
        width: 100%;
        object-fit: contain;
    }

    .popup-comment {
        width: 100%;
    }

    .grid table tbody td {
        padding-right: 0.375rem !important;
    }

    .login .login-form {
        width: 100% !important;
    }

    .login .login-form .content {
        padding: 1.75rem !important;
        width: 90% !important;
        margin: 0 auto !important;
    }

    .thumbnail-wrapper .container {
        flex-shrink: 0 !important;
    }

    .activate-popup .header {
        justify-content: center !important;

        h4 {
            margin-left: 0 !important;
        }
    }

    .generate-code-wrapper {
        flex-direction: column;
    }

    .generate-code-wrapper .code-container {
        width: 100% !important;

        p {
            word-break: break-all !important;
        }
    }

    .popup {
        min-width: 100% !important;
    }

    .localizations .popup .thumbnail {
        width: 5.625rem !important;
    }

    .popup-comment h4 {
        left: 20px !important;
    }

    .popup-comment {
        width: 100% !important;
    }

    form.filter-form .row>.btn {
        max-width: 100% !important;
        width: 100% !important;
        margin-right: 0 !important;
    }

    .active-players {
        position: unset !important;
    }

    .active-players input[type="checkbox"] {
        width: auto !important;
        margin-bottom: 0 !important;
    }

    .cashdesk .overlay .popup .popup .header {
        display: flex !important;
        justify-content: center !important;
    }

    .cashdesk .overlay .popup {
        height: auto !important;
    }

    .cashdesk .overlay .popup form.deposit .row .general-input {
        width: 100%;
    }

    .cashdesk .overlay .popup form.deposit .row .btn.withdraw {
        width: 100%;
    }

    .cashdesk .overlay .popup form.deposit .row .btn.deposit {
        width: 100%;
    }

    .overlay {
        position: fixed !important;
    }

    .grid table thead th {
        padding: 0 15px 0 0;
    }

    .report-chart .dd-container {
        margin-left: 1.875rem !important;
        margin-right: 1.875rem !important;
        margin-top: 1.875rem !important;
        width: 15.625rem !important;
    }

    .report-chart {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .info-wrapper .block {
        padding: 1.1875rem 0.8rem;
    }
}
</style>
