<template>
    <div class="media-report">
        <form class="filter" @submit.prevent="setData">
            <dropdown v-model="filter.mediaType" :options="mediaTypesOptions"></dropdown>
            <input
                class="general-input"
                type="text"
                v-model="filter.mediaName"
                :placeholder="$t('MEDIA__MEDIA_NAME')"
            />
            <input
                class="general-input"
                type="number"
                v-model="filter.mediaId"
                :placeholder="$t('MEDIA__MEDIA_ID')"
            />
            <input-date @selected="(date) => filter.fromDate = date" :placeholder="$t('MEDIA__FROM_DATE')"></input-date>
            <input-date @selected="(date) => filter.toDate = date" :placeholder="$t('MEDIA__TO_DATE')"></input-date>
            <button class="btn">{{ $t('MEDIA__SEARCH') }}</button>
        </form>
        <grid
            fullGrid
            identificator="id"
            :passedData="gridData"
            :passedPageSize="pageSize"
            :columns="columnsToDisplay"
            :passedCount="count"
            @pageChanged="pageChangeHandler"
            @pageCountChanged="pageCountChangeHandler"
            @sort="sortHandler"
        ></grid>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import InputDate from '@/components/ReusableComponents/Input/InputDate.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    components: {
        Grid,
        Dropdown,
        InputDate,
    },
    mounted() {
        this.setData()
    },
    data() {
        return {
            filter: {
                mediaName: '',
                mediaId: '',
                mediaType: '',
                fromDate: '',
                toDate: '',
            },
            currentPage: 1,
            pageSize: 15,
            orderColumn: 'mediaId',
            orderDirection: 'desc',
            gridData: [],
            count: 0,
            columns: [
                {
                    title: this.$t('MEDIA__ID'),
                    key: 'id',
                    permissionName: 'id',
                },
                {
                    title: this.$t('MEDIA__NAME'),
                    key: 'name',
                    permissionName: 'name',
                },
                {
                    title: this.$t('MEDIA__MEDIA_TYPE'),
                    key: 'mediaType',
                    permissionName: 'mediaType',
                },
                {
                    title: this.$t('MEDIA__VIEW'),
                    key: 'views',
                    permissionName: 'views',
                },
                {
                    title: this.$t('MEDIA__UNIQUE_VIEWS'),
                    key: 'uniqueViews',
                    permissionName: 'uniqueViews',
                },
                {
                    title: this.$t('MEDIA__CLICKS'),
                    key: 'clicks',
                    permissionName: 'clicks',
                },
                {
                    title: this.$t('MEDIA__SIGNUPS'),
                    key: 'signups',
                    permissionName: 'signups',
                },
                {
                    title: this.$t('MEDIA__CTR'),
                    key: 'ctr',
                    customTemplate: (item, val) => val * 100 + '%',
                    permissionName: 'ctr',
                },
                {
                    title: this.$t('MEDIA__DEPOSIT_AMOUNT'),
                    key: 'depositAmount',
                    permissionName: 'depositAmount',
                },
                {
                    title: this.$t('MEDIA__DEPOSIT_COUNT'),
                    key: 'depositCount',
                    permissionName: 'depositCount',
                },
                {
                    title: this.$t('MEDIA__NGR'),
                    key: 'ngr',
                    permissionName: 'ngr',
                },
                {
                    title: this.$t('MEDIA__FTD'),
                    key: 'ftd',
                    permissionName: 'ftd',
                },
                {
                    title: this.$t('MEDIA__FTD_COUNT'),
                    key: 'ftdCount',
                    permissionName: 'ftdCount',
                },
            ],
        }
    },
    computed: {
        directory() {
            return this.$store.state.directory
        },
        mediaTypesOptions() {
            let options = [{ text: this.$t('MEDIA__ANY_TYPE'), value: '' }]
            if (this.directory) {
                this.directory.affiliateMediaTypes.forEach(type => {
                    options.push({ text: type.name, value: type.id })
                })
            }
            return options
        },
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Report_Media_Report'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
        columnsToDisplay() {
            if(!this.namesOfColumnsToDisplay) return this.columns
            return this.columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
    },
    methods: {
        setData() {
            var data = {
                CurrentPage: this.currentPage,
                PageSize: this.pageSize,
                OrderColumn: this.orderColumn,
                OrderDirection: this.orderDirection,
                MediaName: this.filter.mediaName,
                MediaId: this.filter.mediaId,
                MediaType: this.filter.mediaType,
                FromDate: this.filter.fromDate,
                ToDate: this.filter.toDate,
            }

            axios
                .get('Report/AffiliateMediaReport', { params: data })
                .then(resp => {
                    this.gridData = resp.data
                    this.count = resp.data[0] ? resp.data[0].count : 0
                })
        },
        pageChangeHandler(page) {
            this.currentPage = page
            this.setData()
        },
        pageCountChangeHandler(size) {
            this.pageSize = size
            this.setData()
        },
        sortHandler(data) {
            this.orderColumn = data.columnName
            this.orderDirection = data.orderByDesc ? 'desc' : 'asc'
            this.setData()
        },
    },
}
</script>

<style scoped lang="scss">
.filter {
    display: flex;
    align-items: center;
    margin-bottom: 30 * $rem;
    
    /deep/ input,
    .dd-container,
    .input-wrapper {
        margin-right: 15 * $rem;
        width: 100%;
        height: 38 * $rem;
    }

    .btn {
        color: white;
        width: calc(50% - 12 * 0.0625rem);
        height: 38 * $rem;
        border-radius: 0.1875rem;
        font-size: 0.875rem;
        font-weight: 400;
        background-color: #4800dd;
    }
}

/deep/ .full-grid {
    margin-top: 30 * $rem;
}

@media only screen and (max-width: 600px) {
    .filter {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
}

</style>
