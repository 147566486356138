<template>
    <div class="block">
        <h5>{{ this.$t('DASHBOARD__TOP_PLAYERS') }}</h5>
        <grid
            :pagination="false"
            :toggleable="false"
            :searchable="false"
            :passedData="topPlayers"
            :columns="columnsToDisplay"
            :pageSize="pageSize"
            :identificator="identificator"
            :orderable="false"
            alignLastRight
        ></grid>
    </div>
</template>

<script>
import Grid from '../ReusableComponents/Grid/Grid.vue'
import { getDateString } from '../../Helpers/HelperFunctions'
export default {
    components: {
        Grid,
    },
    props: {
        topPlayers: Array,
        currencyCode: String,
        realDecimalPlaces: Number,
    },
    data: function() {
        return {
            columns: [
                {
                    title: this.$t('DASHBOARD__FLAG'),
                    key: 'countryName',
                    customTemplate: (item, val) =>
                        `<div country="${val}" class="flag"></div>`,
                    permissionName: 'countryName',
                },
                {
                    title: this.$t('DASHBOARD__ID'),
                    key: 'userProfileID',
                    permissionName: 'userProfileID',
                },
                {
                    title: this.$t('DASHBOARD__USERNAME'),
                    key: 'userName',
                    permissionName: 'userName',
                },
                {
                    title: this.$t('DASHBOARD__REGISTRATION_DATE'),
                    key: 'registrationDate',
                    customTemplate: (item, val) => getDateString(val),
                    permissionName: 'registrationDate',
                },
                {
                    title: this.$t('DASHBOARD__NET_REVENUE'),
                    key: 'netRevenue',
                    permissionName: 'netRevenue',
                    customTemplate: (item, val) => {
                        let number = parseInt(val.replace(',', '.'))
                        let x = Math.pow(10, this.realDecimalPlaces)
                        return Math.floor(number * x) / x
                    },
                },
                {
                    title: this.$t('DASHBOARD__COMMISSION'),
                    key: 'commission',
                    customTemplate: (item, val) =>
                        `<span class="blue">${this.amountText(val)}</span>${
                            this.currencyCode
                        }`,
                    permissionName: 'commission',
                },
            ],
            pageSize: 11,
            identificator: 'userProfileID',
        }
    },
    methods: {
        amountText(amount) {
            amount = amount.replace(',', '.')
            let x = Math.pow(10, this.realDecimalPlaces)
            return Math.floor(parseFloat(amount) * x) / x
        },
    },
    computed: {
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Dashboard_Top_Players'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
        columnsToDisplay() {
            return this.columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
    }
}
</script>

<style scoped lang="scss">
.block {
    width: 510 * $rem;
    height: 435 * $rem;
    padding: 24 * $rem 24 * $rem;
}
</style>
