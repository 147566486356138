<template>
    <div class="block line-chart-block">
        <h5>{{chartName}}</h5>
        <div ref="container" class="chart"></div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    mounted() {
        this.loadChart(this.chartData)
    },
    props: {
        chartData: Array,
        columnNames: Array,
        chartName: {
            type: String,
            default: 'Chart',
        },
        chartWidth: Number,
    },
    watch: {
        chartData: function(newValue) {
            this.loadChart(newValue)
        },
    },
    methods: {
        loadChart(chartData) {
            if (!chartData || chartData.length == 0) return
            var columnNames = this.columnNames
            var chartWidth = this.chartWidth
            var container = this.$refs.container
            google.charts.load('current', { packages: ['line'] })
            google.charts.setOnLoadCallback(drawChart)

            function drawChart() {
                var data = new google.visualization.DataTable()
                //set columns
                columnNames.forEach(name => {
                    if (name == 'Date') {
                        data.addColumn('date', name)
                    } else data.addColumn('number', name)
                })
                chartData
                    .map(el => (el.date = new Date(el.date)))
                    .sort(
                        (a, b) =>
                            new Date(b.date).getDate() -
                            new Date(a.date).getDate()
                    )
                chartData.forEach(e => {
                    delete e['datePart']
                })
                var rows = []
                chartData.forEach(el => {
                    rows.push(Object.keys(el).map(key => el[key]))
                })
                data.addRows(rows)

                var options = {
                    height: 280,
                    legend: { position: 'bottom' },
                    theme: 'material',
                }
                if (chartWidth) options['width'] = chartWidth

                var chart = new google.charts.Line(container)

                chart.draw(data, options)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.block {
    width: 510 * $rem;
    height: 350 * $rem;
    padding-top: 15 * $rem;
    overflow: auto;

    .chart {
        display: flex;
        justify-content: center;
        padding: 20 * $rem 10 * $rem 0 10 * $rem;
    }

    h5 {
        padding-left: 15 * $rem;
    }
}
</style>
