<template>
    <div>
        <div class="banners">
            <form class="filter-form" @submit.prevent="searchHandler">
                <div class="row">
                    <input
                        type="text"
                        :placeholder="$t('RESOURCES__NAME')"
                        v-model="form.name"
                        class="general-input"
                    />
                    <dropdown name="types" :options="mediaTypeOptions" v-model="form.mediaTypeId"></dropdown>
                    <el-date-picker
                        v-model="form.createDate"
                        :placeholder="$t('RESOURCES__CREATE_DATE')"
                        format="yyyy-MM-dd"
                    />
                    <button class="btn btn-clear" type="button">{{$t('RESOURCES__CLEAR_ALL')}}</button>
                    <button class="btn btn-search" type="submit">{{ $t('RESOURCES__SEARCH') }}</button>
                </div>
            </form>
            <grid
                :columns="resourcesGridColumns"
                identificator="id"
                fullGrid
                :searchable="false"
                :passedData="resourcesGridData"
                :passedPageSize="postData.pageLength"
                :passedCount="totalCount"
                :searchedColumns="this.postData.params"
                :exportable="false"
                @pageChanged="pageChangeHandler"
                @pageCountChanged="pageCountChangeHandler"
                @sort="sortHandler"
            ></grid>
            <overlay :visible="thumbnailPopupVisible" @close="thumbnailPopupVisible = false">
                <img src alt="thumbnail" class="thumbnail-big" />
            </overlay>
            <overlay :visible="getCodePopupVisible" @close="getCodePopupVisible = false">
                <div class="popup grid-wrapper">
                    <grid
                        :passedData="getCodeGridData"
                        :columns="getCodeGridColumns"
                        :pagination="false"
                        :toggleable="false"
                        :searchable="false"
                        :pageSize="40"
                        identificator="id"
                    ></grid>
                </div>
            </overlay>
            <overlay :visible="viewContentVisible" @close="viewContentVisible = false">
                <div class="popup media-content" v-html="mediaContent"></div>
            </overlay>
        </div>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import axios from '@/Helpers/AxiosInstance.js'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'

import EventBus from '@/Helpers/EventBus.js'
import { getDateString } from '@/Helpers/HelperFunctions.js'
import { copyToClipboard } from '@/Helpers/HelperFunctions.js'

export default {
    components: {
        Grid,
        Overlay,
        Dropdown,
    },

    created() {
        this.setData()
    },

    mounted() {
        this.$el.addEventListener('click', event => {
            let t = event.target
            while (t && t !== this) {
                if (t.matches('.generate-code')) {
                    // 'Get Code' click
                    this.generateCodeClickHandler(t)
                } else if (t.matches('.view-content')) {
                    // 'View' click
                    this.showContent(t)
                } else if (t.matches('.copy-code')) {
                    //copy generated code
                    this.changeCopyTextForAWhile(t)
                    copyToClipboard(t.parentNode.firstElementChild.innerText)
                }
                t = t.parentNode
                if (t == this.$el) break
            }
        })
    },

    data() {
        return {
            resourcesGridData: [], // main grid data
            totalCount: 0, // main grid count
            postData: {
                // main grid request parameters
                orderByParam: 'id',
                orderByIsDesc: true,
                pageCount: 1,
                pageLength: 15,
                params: [],
            },
            thumbnailPopupVisible: false,
            form: {
                // search form controls (binding with v-model)
                mediaTypeId: '',
                name: '',
                createDate: '',
            },
            mediaTypeOptions: [
                {
                    text: this.$t('RESOURCES__ANY_TYPE'),
                    value: '',
                },
                {
                    text: this.$t('RESOURCES__LINK'),
                    value: 1,
                },
                {
                    text: this.$t('RESOURCES__BANNER'),
                    value: 2,
                },
            ],
            getCodeGridData: [], // grid for Generated Codes
            getCodeGridColumns: [
                {
                    title: '',
                    key: 'code',
                    customTemplate: (item, val) => `
                            <div class="generate-code-wrapper">
                                <span>${item.langCode}</span>
                                <div class="code-container">
                                    <p>${val}</p>
                                    <div class="btn copy-code">Copy</div>
                                </div>
                            </div>
                        `,
                },
            ],
            getCodePopupVisible: false,
            viewContentVisible: false,
            mediaContent: '',
        }
    },

    methods: {
        // get main grid data
        setData() {
            axios.post('Media/GetVWMedia', this.postData).then(resp => {
                this.resourcesGridData = resp.data.data
                this.totalCount = resp.data.count
            })
        },
        pageChangeHandler(page) {
            this.postData.pageCount = page
            this.setData()
        },
        pageCountChangeHandler(count) {
            this.postData.pageLength = parseInt(count)
            this.postData.pageCount = 1
            this.setData()
        },
        sortHandler(data) {
            this.postData.orderByParam = data.columnName
            this.postData.orderByIsDesc = data.orderByDesc
            this.setData()
        },
        searchHandler() {
            var newDt = []
            Object.keys(this.form).forEach(key => {
                if (this.form[key]) {
                    //add param to postdata
                    newDt.push({
                        operator: 0,
                        paramName: key,
                        value: this.form[key],
                    })
                }
            })
            this.postData.params = newDt
            this.setData()
        },
        //get the item ID that was clicked in row
        getRowID(target) {
            let parent = target.parentNode
            while (!parent.matches('tr')) {
                parent = parent.parentNode
            }
            return parent.getAttribute('rowid')
        },
        clearFilters() {},
        //get the main grids one row item with ID
        getGridElementByID(ID) {
            var elem = this.resourcesGridData.filter(el => el.id == ID)[0]
            return elem
        },
        //click handler for 'Get Code' button in grid
        generateCodeClickHandler(t) {
            var ID = this.getRowID(t)
            var item = this.getGridElementByID(ID)
            if (!item) return
            axios
                .get('Media/GetMediaLocalizations', {
                    params: {
                        mediaId: ID,
                    },
                })
                .then(resp =>
                    this.generateCodesAndShowPopup(resp.data, item.mediaTypeId)
                )
        },
        generateCodesAndShowPopup(data, typeID) {
            if (!this.directory) {
                EventBus.$on('setDirectory', () => {
                    this.generateCodesAndShowPopup(data, typeID)
                })
                return
            }

            var gridData = []
            data.mediaLocalizations.forEach(el => {
                let linkCode = ''

                var linkUrl = data.linkUrl.replace(
                    'https://tracker.inplaynetpartners.com/',
                    this.directory.brandTrackerUrl
                )

                if (typeID == 1) {
                    linkCode = this.generateLinkCode(
                        el,
                        data.affilateGId,
                        linkUrl
                    )
                } else if (typeID == 2) {
                    linkCode = this.generateBannerCode(
                        el,
                        data.affilateGId,
                        data.gifUrl,
                        linkUrl
                    )
                }

                gridData.push({
                    langCode: el.langCode,
                    code: linkCode,
                })
            })
            this.getCodeGridData = gridData
            this.getCodePopupVisible = true
        },
        //Code for Type Link
        generateLinkCode(localization, affilateGId, url) {
            return `${url}?mediaid=${localization.mediaId}&amp;lang=${localization.langCode}&amp;affiliategid=${affilateGId}`
        },
        //Code for Type Banner
        generateBannerCode(localization, affilateGId, gifUrl, linkUrl) {
            let imgUrl = localization.imageUrl.includes('http')
                ? localization.imageUrl
                : this.$store.state.assetsPath + localization.imageUrl
            return `
                        &lt;img src="${gifUrl}?mediaid=${localization.mediaId}&amp;affiliategid=${affilateGId}" style="position:absolute; visibility:hidden" /&gt;
                        &lt;a href="${linkUrl}?mediaid=${localization.mediaId}&amp;lang=${localization.langCode}&amp;affiliategid=${affilateGId}" target="_blank"&gt;
                            &lt;img src="${imgUrl}"
                                height="${localization.sizeY}" width="${localization.sizeX}" /&gt;
                        &lt;/a&gt;
                    `
        },
        //changes 'Copy' to 'Copied' for 3 seconds
        changeCopyTextForAWhile(t) {
            t.innerText = 'Copied'
            setTimeout(() => {
                t.innerText = 'Copy'
            }, 3000)
        },
        //shows banner or link
        showContent(t) {
            var ID = this.getRowID(t)
            var item = this.getGridElementByID(ID)
            if (!item) return
            axios
                .get('Media/GetMediaLocalizations', {
                    params: {
                        mediaId: ID,
                    },
                })
                .then(resp => {
                    if (resp.data.mediaLocalizations.length == 0) return
                    let code = ''
                    //LINK
                    if (item.mediaTypeId == 1) {
                        code = this.generateLinkCode(
                            resp.data.mediaLocalizations[0],
                            resp.data.affilateGId,
                            resp.data.linkUrl
                        )
                    }
                    //BANNER
                    else if (item.mediaTypeId == 2) {
                        code = this.generateBannerCode(
                            resp.data.mediaLocalizations[0],
                            resp.data.affilateGId,
                            resp.data.gifUrl,
                            resp.data.linkUrl
                        )
                    }
                    let htmlCode = code
                        .replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&amp;/g, '&')
                    this.mediaContent = htmlCode
                    this.viewContentVisible = true
                })
        },
    },

    computed: {
        directory() {
            return this.$store.state.directory
        },
        resourcesGridColumns() {
            if (!this.directory) return []
            let dir = this.directory
            var columns = [
                {
                    title: this.$t('RESOURCES__ID'),
                    key: 'id',
                    permissionName: 'Id',
                },
                {
                    title: this.$t('RESOURCES__NAME'),
                    key: 'name',
                    permissionName: 'name',
                },
                {
                    title: this.$t('RESOURCES__TYPE'),
                    key: 'mediaTypeId',
                    customTemplate: (item, val) => {
                        let type = dir.affiliateMediaTypes.filter(
                            el => el.id == val
                        )[0]
                        if (type) return type.name
                        return '-'
                    },
                    permissionName: 'mediaTypeId',
                },
                {
                    title: this.$t('RESOURCES__CREATE_DATE'),
                    key: 'createDate',
                    customTemplate: (item, val) => getDateString(val),
                    permissionName: 'createDate',
                },
                {
                    title: this.$t('RESOURCES__VIEWS'),
                    key: 'views',
                    permissionName: 'views',
                },
                {
                    title: this.$t('RESOURCES__UNIQUE_VIEWS'),
                    key: 'uniqueViews',
                    permissionName: 'uniqueViews',
                },
                {
                    title: this.$t('RESOURCES__CLICKS'),
                    key: 'clicks',
                },
                {
                    title: this.$t('RESOURCES__SIGNUPS'),
                    key: 'signups',
                    permissionName: 'signups',
                },
                {
                    title: this.$t('RESOURCES__CTR'),
                    key: 'signups',
                    customTemplate: item => {
                        if (item.clicks == 0) return '0%'
                        let percent = (item.signups / item.clicks) * 100
                        if (percent == Math.floor(percent)) return percent + '%'
                        return percent.toFixed(2) + '%'
                    },
                    permissionName: 'clicks',
                },
                {
                    title: this.$t('RESOURCES__FTD_COUNT'),
                    key: 'firstDeposit',
                    permissionName: 'firstDeposit',
                },
                {
                    title: this.$t('RESOURCES__PRODUCT'),
                    key: 'productId',
                    customTemplate: (item, val) => {
                        let product = dir.afiliateMediaProducts.filter(
                            el => el.id == val
                        )[0]
                        if (product) return product.name
                        return '-'
                    },
                    permissionName: 'productId',
                },
                {
                    title: this.$t('RESOURCES__DEPOSIT'),
                    key: '',
                    customTemplate: () => '-',
                },
                {
                    title: this.$t('RESOURCES__ACTIONS'),
                    key: '',
                    plainColumn: true,
                    customTemplate: () => {
                        return `
                                <div class="actions media-actions">
                                    <div class="view-content btn" title="View Content">View</div>
                                    <div class="generate-code btn" title="Code For Website">Get Code</div>
                                </div>`
                    },
                },
            ]
            return columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Marketing_Affiliate_Media_Resources'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
    },
}
</script>

<style scoped lang="scss">
/deep/ .media-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
        width: 71 * $rem;
        height: 28 * $rem;
        background-color: #4800dd;
        font-weight: 500;
        font-size: 14 * $rem;
        border-radius: 3 * $rem;
        margin-right: 15 * $rem;
    }

    .statistics {
        font-size: 36 * $rem;
        cursor: pointer;
    }
}

/deep/ .input-wrapper {
    float: left;

    input {
        width: 372 * $rem;
        height: 44 * $rem;
        border-radius: 0;
    }
}

/deep/ .full-grid {
    margin-top: 30 * $rem;

    .table-wrapper {
        box-shadow: none;
    }

    table {
        thead {
            tr {
                th {
                    background-color: #f2f1f2;
                    color: #636363;
                    font-weight: 600;
                    border-right: 1 * $rem solid #c0bfc0;

                    &:last-of-type {
                        width: 135px;

                        .title {
                            margin: auto;
                        }
                    }
                }
            }
        }

        tbody {
            td {
                padding-top: 12 * $rem;
                padding-bottom: 12 * $rem;
                font-weight: 500;
            }
        }
    }
}

/deep/ .generate-code-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
        font-size: 20 * $rem;
        text-transform: capitalize;
    }

    .code-container {
        width: 500 * $rem;
        margin-bottom: 30 * $rem;

        p {
            padding: 15 * $rem;
            border: 1px solid grey;
            background-color: rgb(245, 245, 245);
        }
    }
}

img.thumbnail-big {
    width: 30%;
}

.popup.grid-wrapper {
    height: 50vh;
    width: 800 * $rem;
    background-color: #fff;
    padding: 0 30 * $rem;
    overflow-y: auto;

    /deep/ th {
        display: none;
    }
}

.popup.media-content {
    background-color: white;
    padding: 30 * $rem;
    img {
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 600px) {
    .filter-form {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
    form.filter-form .row .el-input {
        width: 100%;
    }
}
</style>
