<template>
    <transition name="fade">
        <div class="overlay" v-show="visible" @click.self="$emit('close')">
            <div class="popup">
                <div class="header">
                    <i class="material-icons" @click="$emit('close')">close</i>
                </div>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        visible: Boolean,
    },
    mounted() {
        document.querySelectorAll('.overlay').forEach(el => el.addEventListener('click', (e) => {
            if(e.target.classList.contains('overlay')) this.$emit('close')
        }))
    },
}
</script>

<style scoped lang="scss">
.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 80;



    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        overflow: hidden;
/deep/
        .header {
            background-color: white;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            i {
                margin-right: 5px;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.4);
            }
        }

        & > .header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }
    }
}

//animations
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
