<template>
    <div>
        <div class="banners">
            <form class="filters-wrapper" @submit.prevent="searchHandler">
                <input type="text" :placeholder="$t('BANNERS__NAME')" v-model="form.name">
                <dropdown name="types" :options="bannerTypeOptions" v-model="form.bannerType"></dropdown>
                <dropdown
                    name="sizes"
                    :options="sizeOptions"
                    v-model="form.selectedSize"
                    :searchable="true"
                ></dropdown>
                <dropdown name="products" :options="productOptions" v-model="form.productId"></dropdown>
                <dropdown
                    name="languages"
                    :options="languageOptions"
                    v-model="form.languageId"
                    searchable
                ></dropdown>
                <dropdown name="statuses" :options="statusOptions" v-model="form.status"></dropdown>
                <div class="filter-buttons">
                    <button class="btn clear-btn" type="button" @click="clearFilters">{{ $t('BANNERS__CLEAR_ALL') }}</button>
                    <button class="btn search-btn" type="submit">{{ $t('BANNERS__SEARCH') }}</button>
                </div>
            </form>
            <grid
                :columns="bannersGridColumns"
                identificator="id"
                fullGrid
                :searchable="false"
                :passedData="bannersGridData"
                :passedPageSize="postData.pageLength"
                :passedCount="totalCount"
                :searchedColumns="this.postData.params"
                :exportable="false"
                :collapsible="true"
                :collapsedIndex="collapsedIndex"
                @pageChanged="pageChangeHandler"
                @pageCountChanged="pageCountChangeHandler"
                @sort="sortHandler"
            >
                <div class="statistics-wrapper">
                    <h2>{{ $t('BANNERS__COMING_SOON') + '...'}}</h2>
                </div>
            </grid>
            <overlay :visible="thumbnailPopupVisible" @close="thumbnailPopupVisible = false">
                <img src alt="thumbnail" class="thumbnail-big">
            </overlay>
            <overlay
                :visible="activateMediaPopupVisible"
                @close="activateMediaPopupVisible = false"
                class="activateMediaPopup"
            >
                <div class="activate-popup">
                    <div class="header">
                        <h4>{{ $t('BANNERS__ACTIVATE') }}</h4>
                    </div>
                    <form class="content" @submit.prevent="activateHandler">
                        <div class="row">
                            <span>{{ $t('BANNERS__NAME') }}</span>
                            <input
                                type="text"
                                name="name"
                                :placeholder="$t('BANNERS__NAME')"
                                autocomplete="off"
                                required
                                v-model="activationForm.name"
                            >
                        </div>

                        <div class="row">
                            <span>{{ $t('BANNERS__LINK') }}</span>
                            <dropdown
                                name="links"
                                :options="linkOptions"
                                v-model="activationForm.link"
                            ></dropdown>
                        </div>

                        <div class="row" v-if='commissionModeType == 3'>
                            <span>{{ $t('BANNERS__REFERRAL_TYPE') }}</span>

                            <dropdown
                                name="referralType"
                                :options="referralTypeOptions"
                                v-model="activationForm.referralType"
                            ></dropdown>
                        </div>

                        <button class="btn create-media">{{ $t('BANNERS__CREATE') }}</button>

                        <div
                            class="btn cancel-creating-media"
                            @click="activateMediaPopupVisible = false"
                        >{{ $t('BANNERS__CANCEL') }}</div>
                    </form>
                </div>
            </overlay>
            <overlay
                :visible="localizationsPopupVisible"
                @close="localizationsPopupVisible = false"
                class="localizations"
            >
                <div class="popup">
                    <grid
                        :passedData="localizationGridData"
                        :columns="localizationGridColumns"
                        :pagination="false"
                        :toggleable="false"
                        :searchable="false"
                        :sortable="false"
                        :pageSize="40"
                        identificator="id"
                    ></grid>
                </div>
            </overlay>
            <overlay :visible="getCodePopupVisible" @close="getCodePopupVisible = false">
                <div class="popup grid-wrapper">
                    <grid
                        :passedData="getCodeGridData"
                        :columns="generatedCodeGridColumns"
                        :pagination="false"
                        :toggleable="false"
                        :searchable="false"
                        :pageSize="40"
                        identificator="id"
                    ></grid>
                </div>
            </overlay>
            <div class="sections">
                <div
                    class="all"
                    :class="{'selected': !recommendedSelected}"
                    @click="allClickHandler"
                >{{ $t('BANNERS__ALL') }}</div>
                <div
                    class="recommended"
                    :class="{'selected': recommendedSelected}"
                    @click="recommendedClickHandler"
                >{{ $t('BANNERS__RECOMMENDED') }}</div>
            </div>
        </div>
        <message ref="message" :message="createMessage.text" :success="createMessage.success"></message>
    </div>
</template>

<script>
    import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
    import axios from '@/Helpers/AxiosInstance.js'
    import Message from '@/components/ReusableComponents/Message/Message.vue'
    import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
    import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
    // import MediaStatistics from '../MediaStatistics/MediaStatistics.vue'

    import EventBus from '@/Helpers/EventBus.js'
    import { getDateString } from '@/Helpers/HelperFunctions.js'
    import { copyToClipboard } from '@/Helpers/HelperFunctions.js'

    export default {
        components: {
            Grid,
            Overlay,
            Message,
            Dropdown,
            // MediaStatistics,
        },

        created() {
            axios.get('Media/GetMediaTemplateBannerSize').then(resp => {
                this.sizes = resp.data
            })

            axios.get('Profile/GetProfile').then(resp => {
                this.commissionModeType = resp.data.commissionModeType
            })

            this.setData()
        },

        mounted() {
            this.$el.addEventListener('click', event => {
                let t = event.target
                while (t && t !== this) {
                    if (t.matches('.image-overlay')) {
                        // click on thumbnail in grid
                        this.thumbnailClickHandler(t)
                    } else if (t.matches('.create')) {
                        //activation popup
                        let rowID = this.getRowID(t)
                        this.prepareMediaActivatorPopup(rowID)
                        this.activateMediaPopupVisible = true
                    } else if (t.matches('.statistics')) {
                        //activation popup
                        let tr = t.parentNode.parentNode.parentNode
                        let index =
                            [].slice
                                .call(tr.parentNode.children)
                                .filter(el => !el.classList.contains('collapsed'))
                                .indexOf(tr) + 1 // index of usual tr (not '.collapsed'), starting from 1
                        if (this.collapsedIndex == index) this.collapsedIndex = 0
                        // if same index is clicked, close
                        else this.collapsedIndex = index // collapse tr
                    } else if (t.matches('.copy-code')) {
                        //copy generated code
                        this.changeCopyTextForAWhile(t)
                        copyToClipboard(t.parentNode.firstElementChild.innerText)
                    }
                    t = t.parentNode
                    if (t == this.$el) break
                }
            })
        },

        data() {
            return {
                bannersGridData: [], //main grid data
                totalCount: 0, //main grid total count
                postData: {
                    orderByParam: 'id',
                    orderByIsDesc: true,
                    pageCount: 1,
                    pageLength: 15,
                    params: [
                        {
                            operator: 0,
                            paramName: 'mediaTypeId',
                            value: 2,
                        },
                    ],
                },
                thumbnailPopupVisible: false,
                activateMediaPopupVisible: false,
                localizationsPopupVisible: false,
                bannerMediaLinks: [], // when activating a banner, have to choose a link, this property has the options for links
                referralTypeOptions: [
                    { text: 'Ordinary', value: 1 },
                    { text: 'CPA ', value: 2 }
                ],
                form: { // search form components, inputs are mapped in here
                    bannerType: '',
                    selectedSize: '',
                    productId: '',
                    languageId: '',
                    name: '',
                    status: '',
                },
                activationForm: { //activation form components, inputs are mapped here
                    name: '',
                    link: '',
                    referralType: null
                },
                collapsedIndex: 0, //In main grid which row is collapsed to show the statistics
                localizationGridData: [],
                createMessage: { //properties for message component
                    text: '',
                    success: false,
                },
                recommendedSelected: false, // All or Recommended is selected above the main grid
                sizes: [], // banner sizes list e.g. 700x300
                generatedCodeGridColumns: [
                    {
                        title: '',
                        key: 'code',
                        customTemplate: (item, val) => `
                            <div class="generate-code-wrapper">
                                <span>${item.langCode}</span>
                                <div class="code-container">
                                    <p>${val}</p>
                                    <div class="btn copy-code">Copy</div>
                                </div>
                            </div>
                        `,
                    },
                ],
                getCodeGridData: [],
                getCodePopupVisible: false,
                commissionModeType: null
            }
        },

        methods: {
            //main grid data fetch
            setData() {
                axios.post('Media/GetMediaTemplates', this.postData).then(resp => {
                    this.bannersGridData = resp.data.data
                    this.totalCount = resp.data.count
                })
            },
            pageChangeHandler(page) {
                this.postData.pageCount = page
                this.setData()
            },
            pageCountChangeHandler(count) {
                this.postData.pageLength = parseInt(count)
                this.postData.pageCount = 1
                this.setData()
            },
            sortHandler(data) {
                this.postData.orderByParam = data.columnName
                this.postData.orderByIsDesc = data.orderByDesc
                this.setData()
            },
            searchHandler() {
                this.postData.params = [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 2,
                    },
                ]
                Object.keys(this.form).forEach(key => {
                    if (key == 'selectedSize') return //this needs little more logic
                    if (key == 'status') return //handling below
                    if (this.form[key]) {
                        //add param to postdata
                        this.postData.params.push({
                            operator: 0,
                            paramName: key,
                            value: this.form[key],
                        })
                    }
                })

                if (this.form.status) {
                    this.postData.params.push({
                        operator: 0,
                        paramName: 'status',
                        value: this.form.status == 1 ? true : false,
                    })
                }

                if (this.form.selectedSize) {
                    let sizeX = this.form.selectedSize.split('x')[0]
                    let sizeY = this.form.selectedSize.split('x')[1]
                    this.postData.params.push({
                        operator: 0,
                        paramName: 'sizeX',
                        value: sizeX,
                    })
                    this.postData.params.push({
                        operator: 0,
                        paramName: 'sizeY',
                        value: sizeY,
                    })
                }
                this.setData()
            },
            //prepares the dropdown of links in activation/creation popup
            prepareMediaActivatorPopup(rowID) {
                this.selectedRowID = rowID
                var item = this.bannersGridData.filter(el => el.id == rowID)[0]

                axios
                    .get('Media/GetBannerMediaLinks', {
                        params: { product: item.productId },
                    })
                    .then(resp => {
                        this.bannerMediaLinks = resp.data
                    })
            },
            //get the item ID that was clicked in the row
            getRowID(target) {
                let parent = target.parentNode
                while (!parent.matches('tr')) {
                    parent = parent.parentNode
                }
                return parent.getAttribute('rowid')
            },
            //activate click
            activateHandler() {
                if (this.commissionModeType !== 3) {
                    this.activationForm.referralType = this.commissionModeType
                }

                var data = {
                    mediaTemplateId: this.selectedRowID,
                    name: this.activationForm.name,
                    linkMediaTemplateId: this.activationForm.link,
                    referralType: this.activationForm.referralType
                }

                axios
                    .post('Media/CreateAffiliateMedia', data)
                    .then(resp => {
                        this.activateMediaPopupVisible = false
                        this.activationForm.name = ''
                        if (resp.data.code == 1) {
                            this.createMessage.text = 'Successfully Created'
                            this.createMessage.success = true
                            this.getCodePopupVisible = true
                            this.generateCodesAndShowPopup(resp.data.value)
                            this.setData()
                            
                        } else {
                            this.createMessage.text = 'An Error Has Occured'
                            this.createMessage.success = false
                        }
                        this.$refs.message.showMessage()
                    })
                    .catch(() => {
                        this.createMessage.text = 'An Error Has Occured'
                        this.createMessage.success = false
                    })
            },
            clearFilters() {
                Object.keys(this.form).forEach(key => this.form[key] = '')
            },
            thumbnailClickHandler(target) {
                let tr = target
                while (!tr.matches('tr')) {
                    tr = tr.parentNode
                }
                let ID = tr.getAttribute('rowid')
                axios
                    .get('Media/GetMediaTemplateLocalizations', {
                        params: {
                            mediaTemplateId: ID,
                        },
                    })
                    .then(resp => {
                        let localizations = resp.data
                        if (localizations.length == 1) {
                            // if only 1 localization, show bigger thumbnail
                            let src = localizations[0].imageUrl.includes('http')
                                ? localizations[0].imageUrl
                                : this.$store.state.assetsPath +
                                  localizations[0].imageUrl
                            this.$el.querySelector('.thumbnail-big').src = src
                            this.thumbnailPopupVisible = true
                        } else {
                            //show grid with all localizations
                            this.localizationGridData = localizations
                            this.localizationsPopupVisible = true
                        }
                    })
            },
            allClickHandler() {
                this.recommendedSelected = false
                this.clearFilters()
                this.postData.params = [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 2,
                    },
                ]
                this.setData()
            },
            recommendedClickHandler() {
                this.recommendedSelected = true
                this.clearFilters()
                this.postData.params = [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 2,
                    },
                    {
                        operator: 0,
                        paramName: 'recommended',
                        value: true,
                    },
                ]
                this.setData()
            },
            generateCodesAndShowPopup(data) {
                if (!this.directory) {
                    EventBus.$on('setDirectory', () => {
                        this.generateCodesAndShowPopup(data)
                    })
                    return
                }

                var gridData = []

                data.mediaLocalizations.forEach(el => {
                    var currentDomain = 'https://tracker.inplaynetpartners.com/'

                    var gifUrl = data.gifUrl.replace(currentDomain, this.directory.brandTrackerUrl)
                    var linkUrl = data.linkUrl.replace(currentDomain, this.directory.brandTrackerUrl)

                    var bannerCode = this.generateBannerCode(el, data.affilateGId, gifUrl, linkUrl)

                    gridData.push({ langCode: el.langCode, code: bannerCode })
                })

                this.getCodeGridData = gridData
            },
            generateBannerCode(localization, affilateGId, gifUrl, linkUrl) {
                let imgUrl = localization.imageUrl.includes('http')
                    ? localization.imageUrl
                    : this.$store.state.assetsPath + localization.imageUrl
                return `
                        &lt;img src="${gifUrl}?mediaid=${
                    localization.mediaId
                }&amp;affiliategid=${affilateGId}" style="position:absolute; visibility:hidden" /&gt;
                        &lt;a href="${linkUrl}?mediaid=${
                    localization.mediaId
                }&amp;lang=${
                    localization.langCode
                }&amp;affiliategid=${affilateGId}" target="_blank"&gt;
                            &lt;img src="${imgUrl}"
                                height="${localization.sizeY}" width="${
                    localization.sizeX
                }" /&gt;
                        &lt;/a&gt;
                    `
            },
            //changes button text to 'Copied' for 3 seconds
            changeCopyTextForAWhile(t) {
                t.innerText = 'Copied'
                setTimeout(() => {
                    t.innerText = 'Copy'
                }, 3000)
            },
        },

        computed: {
            //gets directory from Vuex
            directory() {
                return this.$store.state.directory
            },
            bannerTypeOptions() {
                var options = [{ text: this.$t('BANNERS__ANY_TYPE'), value: '' }]
                if (this.directory) {
                    this.directory.affiliateBannerTypes.forEach(el =>
                        options.push({ text: el.name, value: el.id })
                    )
                }
                return options
            },
            productOptions() {
                var options = [{ text: this.$t('BANNERS__ANY_PRODUCT'), value: '' }]
                if (this.directory) {
                    this.directory.afiliateMediaProducts.forEach(el =>
                        options.push({ text: el.name, value: el.id })
                    )
                }
                return options
            },
            sizeOptions() {
                var options = [{ text: this.$t('BANNERS__ANY_SIZE'), value: '' }]
                this.sizes.forEach(el => {
                    let size = el.sizeX + 'x' + el.sizeY
                    options.push({ text: size, value: size })
                })
                return options
            },
            languageOptions() {
                var options = [{ text: this.$t('BANNERS__ANY_LANGUAGE'), value: '' }]
                if (this.directory) {
                    this.directory.languages.forEach(el =>
                        options.push({ text: el.langCodeShort, value: el.id })
                    )
                }
                return options
            },
            linkOptions () {
                if (!this.directory) return []

                return this.bannerMediaLinks.map(link => {
                    var domain = this.directory.brandActiveMainDomain
                    var text = ''

                    if (link.url) {
                        text = link.url.replace('{domain}', domain.toLowerCase())
                    }

                    return { text, value: link.id+'' }
                })
            },
            bannersGridColumns() {
                return [
                    {
                        title: this.$t('BANNERS__ID'),
                        key: 'id',
                    },
                    {
                        title: this.$t('BANNERS__MEDIA_NAME'),
                        key: 'name',
                        customTemplate: (item, val) => {
                            let url = item.imageUrl
                            if (!url) return val
                            if (!url.includes('http'))
                                url = this.$store.state.assetsPath + url

                            return `<div class="thumbnail-wrapper">
                                        <div class="container">
                                            <img src="${url}" class="thumbnail"/>
                                            <div class="image-overlay">
                                                <i class="material-icons">search</i>
                                            </div>
                                        </div>
                                        <span class="media-name">${val}</span>
                                    </div>`
                        },
                    },
                    {
                        title: this.$t('BANNERS__TYPE'),
                        key: 'bannerType',
                    },
                    {
                        title: this.$t('BANNERS__SIZE'),
                        key: 'sizeX',
                        customTemplate: item => `${item.sizeX}x${item.sizeY}`,
                    },
                    {
                        title: this.$t('BANNERS__PRODUCT'),
                        key: 'mediaProduct',
                    },
                    {
                        title: this.$t('BANNERS__LANGUAGE'),
                        key: 'langCode',
                        customTemplate: (item, val) => {
                            if(item.hasManyLanguage) return 'Many'
                            return val
                        }
                    },
                    {
                        title: this.$t('BANNERS__ACTIVATION_DATE'),
                        key: 'activatedDate',
                        customTemplate: (item, val) =>
                            val ? getDateString(val) : '-',
                    },
                    {
                        title: this.$t('BANNERS__STATUS'),
                        key: 'status',
                        customTemplate: (item, val) => {
                            if (val) {
                                return '<span class="status-active">Active</span>'
                            }
                            return '<span class="status-inactive">Inactive</span>'
                        },
                    },
                    {
                        title: this.$t('BANNERS__ACTIONS'),
                        key: 'id',
                        plainColumn: true,
                        customTemplate: () => {
                            return `
                            <div class="actions media-actions">
                                <div class="create btn" title="Create Media">${this.$t('BANNERS__CREATE')}</div>
                                <i class="material-icons statistics" title="Statistics">insert_chart</i>
                            </div>`
                        },
                    },
                ]
            },
            statusOptions() {
                return [
                    {
                        text: this.$t('BANNERS__ANY_STATUS'),
                        value: '',
                    },
                    {
                        text: this.$t('BANNERS__ACTIVE'),
                        value: '1',
                    },
                    {
                        text: this.$t('BANNERS__INACTIVE'),
                        value: '2',
                    },
                ]
            },
            localizationGridColumns() {
                return [
                    {
                        title: this.$t('BANNERS__BANNER'),
                        key: 'imageUrl',
                        customTemplate: (item, val) => {
                            let url = val
                            if(!url) return val
                            if (!url.includes('http'))
                                url = this.$store.state.assetsPath + url
                            return `<img class="thumbnail" src="${url}" />`
                        },
                        width: 250,
                    },
                    {
                        title: this.$t('BANNERS__LANGUAGE'),
                        key: 'langCode',
                    },
                    {
                        title: this.$t('BANNERS__SIZE'),
                        key: 'sizeX',
                        customTemplate: item => item.sizeX + 'x' + item.sizeY,
                    },
                ]
            },
        }
    }
</script>

<style scoped lang="scss">

    

    /deep/ .thumbnail-wrapper {
        display: flex;
        align-items: center;
        overflow: hidden;

        .container {
            position: relative;
            display: flex;
            align-items: center;
            width: 73 * $rem;
            height: 63 * $rem;
            border: 1px solid rgba(102, 102, 102, 0.486);

            .thumbnail {
                width: 100%;
                cursor: pointer;
                border-radius: 2 * $rem;
            }

            .image-overlay {
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(102, 102, 102, 0.486);
                z-index: 5;
                cursor: pointer;
                transition: all 0.3s ease-out;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: white;
                    font-size: 28px;
                }
            }
            &:hover {
                .image-overlay {
                    opacity: 1;
                }
            }
        }

        .media-name {
            font-size: 14px;
            font-weight: 500;
            margin-left: 25 * $rem;
        }
    }

    /deep/ .media-actions {
        display: flex;
        align-items: center;

        .create.btn {
            width: 71 * $rem;
            height: 28 * $rem;
            background-color: #4800dd;
            font-weight: 500;
            font-size: 14 * $rem;
            border-radius: 3 * $rem;
        }

        .statistics {
            font-size: 36 * $rem;
            cursor: pointer;
        }
    }

    /deep/ .status-active {
        color: #3e7252;
        position: relative;
        padding-left: 15*$rem;

        &:before {
            content: '';
            position: absolute;
            top: 4 * $rem;
            left: 0;
            background-color: #3e7252;
            width: 8 * $rem;
            height: 8 * $rem;
            border-radius: 50%;
        }
    }

    /deep/ .status-inactive {
        color: rgb(196, 0, 0);
        position: relative;
        padding-left: 15*$rem;

        &:before {
            content: '';
            position: absolute;
            top: 4 * $rem;
            left: 0;
            background-color: rgb(196, 0, 0);
            width: 8 * $rem;
            height: 8 * $rem;
            border-radius: 50%;
        }
    }

    /deep/ .full-grid {
        margin-top: 30 * $rem;
    }

    /deep/ .generate-code-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        span {
            font-size: 20 * $rem;
            text-transform: capitalize;
        }

        .code-container {
            width: 500 * $rem;
            margin-bottom: 30 * $rem;

            p {
                padding: 15 * $rem;
                border: 1px solid grey;
                background-color: rgb(245, 245, 245);
            }
        }
    }

    .activate-popup {
        background-color: white;
        width: 600 * $rem;
        height: 400 * $rem;
        position: relative;

        .header {
            height: 60 * $rem;
            background-color: white;
            display: flex;
            align-items: center;

            h4 {
                margin-left: 50 * $rem;
                font-size: 18px;
                font-weight: 400;
            }
        }

        .content {
            padding: 40 * $rem 50 * $rem;
            color: $grey-dark;

            .row {
                margin-bottom: 20 * $rem;

                span {
                    display: inline-block;
                    margin-bottom: 10 * $rem;
                }

                input {
                    display: block;
                    height: 35 * $rem;
                    padding: 15 * $rem;
                    width: 100%;
                    border-radius: 5 * $rem;
                    border: 1 * $rem solid #d2d2d2;

                    &:disabled {
                        background-color: #ececec;
                    }
                }

                /deep/ .dd-container {
                    display: block;
                    height: 35 * $rem;
                    border: 1 * $rem solid #d2d2d2;
                    border-radius: 5 * $rem;
                    width: 100%;
                }
            }

            .btn.create-media {
                position: absolute;
                right: 110 * $rem;
                bottom: 10 * $rem;
                width: 90 * $rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                border-radius: 3px;
                color: white;
                background-color: $green;

                &:hover {
                    cursor: pointer;
                    filter: brightness(1.1);
                }
            }

            .btn.cancel-creating-media {
                position: absolute;
                right: 10 * $rem;
                bottom: 10 * $rem;
                width: 90 * $rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                border-radius: 3px;
                color: white;
                background-color: #a5a5a5;

                &:hover {
                    cursor: pointer;
                    filter: brightness(1.1);
                }
            }
        }
    }

    .filters-wrapper {
        display: block;
        width: 100%;
        height: calc(2 * 44 *#{$rem} + 2 * 17 *#{$rem});

        & > input,
        & > .dd-container {
            display: block;
            float: left;
            width: calc(25% - 18 * #{$rem});
            height: 44 * $rem;
            margin-bottom: 17 * $rem;

            &:not(:nth-child(4n)) {
                margin-right: 24 * $rem;
            }
        }

        & > input {
            border-radius: 3 * $rem;
            border: 1 * $rem solid rgba(154, 154, 154, 0.57);
            padding-left: 10 * $rem;
            box-sizing: border-box;
            font-size: 14 * $rem;

            &::placeholder {
                color: $placeholder;
            }
        }

        & > .filter-buttons {
            float: right;
            margin-right: 0;
            width: calc(25% - 18 * #{$rem});
            height: 44 * $rem;
            margin-bottom: 17 * $rem;
            display: flex;
            justify-content: space-between;

            button {
                color: white;
                width: calc(50% - 12 * #{$rem});
                height: inherit;
                border-radius: 3 * $rem;
                font-size: 14 * $rem;
                font-weight: 400;

                &.clear-btn {
                    background-color: $gray-btn;
                }
                &.search-btn {
                    background-color: $purple-btn;
                }
            }
        }
    }

    .localizations .popup {
        width: 800 * $rem;
        height: 80vh;
        overflow-y: auto;
        background-color: #fff;
        padding: 5 * $rem 30 * $rem;

        /deep/ .thumbnail {
            width: 250 * $rem;
        }

        /deep/ tr {
            th:first-of-type {
                width: 350px;
            }
        }
    }

    .banners {
        position: relative;

        .sections {
            position: absolute;
            top: 138 * $rem;
            display: flex;

            .all,
            .recommended {
                padding: 24 * $rem 34 * $rem;
                color: #636363;
                font-weight: 500;
                font-size: 14 * $rem;
                cursor: pointer;

                &.selected {
                    background-color: #f2f1f2;
                }
            }
        }
    }

    .popup.grid-wrapper {
        height: 50vh;
        width: 800 * $rem;
        background-color: #fff;
        padding: 0 30 * $rem;
        overflow-y: auto;

        /deep/ th {
            display: none;
        }
    }

@media only screen and (max-width: 600px) {
    .filters-wrapper {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
}
</style>
