<template>
    <div>
        <form class="filter-form" @submit.prevent="onSubmit">
            <div class="row">
                <input type="password" :placeholder="$t('CHANGE_PASSWORD__CURRENT_PASSWORD')" v-model="currentPassword" class="general-input" required>
                <input type="password" :placeholder="$t('CHANGE_PASSWORD__NEW_PASSWORD')" v-model="newPassword" class="general-input" required>
                <input type="password" :placeholder="$t('CHANGE_PASSWORD__CONFIRM_PASSWORD')" v-model="confirmPassword" class="general-input" required>
                <button class="btn btn-search">{{ $t('CHANGE_PASSWORD__SAVE') }}</button>
                <p v-show="confirmError" class="error-message">{{ $t('CHANGE_PASSWORDS__PASSWORD_DO_NOT_MATCH') }}</p>
            </div>
        </form>
        <message  ref="message" :message="changeMessage" :success="changeSuccess"></message>
    </div>
</template>

<script>
import Message from '@/components/ReusableComponents/Message/Message.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    components: {
        Message
    },
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            changeMessage: '',
            changeSuccess: false,
            confirmError: false,
        }
    },
    methods: {
        onSubmit() {
            if(this.newPassword != this.confirmPassword) {
                this.confirmError = true
                return
            }
            this.confirmError = false
            var data = {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
                newPasswordConfirm: this.confirmPassword,
            }
            axios
                .post('Security/ChangePassword', data)
                .then(resp => {
                    if (resp.data.status == 1) {
                        //success
                        this.changeMessage = 'Success'
                        this.changeSuccess = true
                        this.$refs.message.showMessage()
                    }
                    else {
                        //fail
                        this.changeMessage = 'Failed to change password'
                        this.changeSuccess = false
                        this.$refs.message.showMessage()
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss">
.row {
    width: 90%;
}
.error-message {
    color: red;
    font-size: 14*$rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
