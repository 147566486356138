<template>
    <transition name="slide" enter-active-class="slide-in">
        <div
            class="block"
            :class="success ? 'success' : 'failure'"
            v-show="visible"
            @click="visible = false"
        >
            <i v-if="success" class="material-icons-outlined">check_circle_outline</i>
            <i v-else class="material-icons-outlined">cancel</i>
            <span>{{message}}</span>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Message',
    props: {
        success: {
            type: Boolean,
            default: true,
        },
        message: {
            type: String,
            default: 'Success',
        },
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        showMessage() {
            document.querySelector('body').style.overflowX = 'hidden'
            this.visible = true
            setTimeout(() => {
                this.visible = false
                document.querySelector('body').style.overflowX = 'initial'
            }, 3000)
        },
    },
}
</script>

<style scoped lang="scss">
.success {
    color: green;
}
.failure {
    color: red;
}

.block {
    position: absolute;
    right: 20 * $rem;
    bottom: 5%;
    height: 100 * $rem;
    width: 20%;
    padding: 20 * $rem 20 * $rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        margin-left: 50 * $rem;
        font-size: 20 * $rem;
    }

    i {
        font-size: 44 * $rem;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(120%);
    }
    70% {
        transform: translateX(-10%);
    }
    100% {
        transform: translateX(0);
    }
}

.slide-in {
    animation: slide-in 0.4s ease-out;
}

@media only screen and (max-width: 1400px) {
    .block {
        width: 25%;
    }
}

@media only screen and (max-width: 1280px) {
    .block {
        width: 30%;
    }
}

@media only screen and (max-width: 1000px) {
    .block {
        width: 35%;
    }
}

@media only screen and (max-width: 850px) {
    .block {
        width: 40%;
    }
}

@media only screen and (max-width: 730px) {
    .block {
        width: 50%;
    }
}

@media only screen and (max-width: 500px) {
    .block {
        width: 70%;
    }
}
</style>