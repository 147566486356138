<template>
    <div class="users">
        <form class="filter-form" @submit.prevent="submitHandler">
            <div class="row">
                <input
                    type="number"
                    name="userID"
                    class="general-input"
                    :placeholder="$t('USERS__USER_ID')"
                    v-model="filter.userProfileID"
                />
                <input
                    type="text"
                    name="username"
                    class="general-input"
                    :placeholder="$t('USERS__USERNAME')"
                    v-model="filter.userName"
                />
                <input
                    type="text"
                    name="firstname"
                    class="general-input"
                    :placeholder="$t('USERS__FIRST_NAME')"
                    v-model="filter.firstName"
                />
                <input
                    type="text"
                    name="lastname"
                    class="general-input"
                    :placeholder="$t('USERS__LAST_NAME')"
                    v-model="filter.lastName"
                />
                <input
                    type="text"
                    name="email"
                    class="general-input"
                    :placeholder="$t('USERS__EMAIL')"
                    v-model="filter.email"
                />
                <input
                    type="text"
                    name="mobile"
                    class="general-input"
                    :placeholder="$t('USERS__MOBILE')"
                    v-model="filter.mobile"
                />
                <dropdown
                    name="country"
                    :options="countryOptions"
                    v-model="filter.countryId"
                    searchable
                ></dropdown>
                <div class="actions">
                    <div class="btn btn-clear" @click="clearHandler">Clear</div>
                    <button class="btn btn-search">{{ $t('USERS__SEARCH') }}</button>
                </div>
            </div>
        </form>
        <grid
            :columns="filteredColumns"
            requestURL="Customer/GetUsers"
            identificator="userProfileID"
            initialOrderingColumn="userProfileID"
            ref="usersGrid"
            fullGrid
        ></grid>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'
export default {
    components: {
        Grid,
        Dropdown,
    },
    data() {
        return {
            columns: [
                {
                    title: this.$t('USERS__ID'),
                    key: 'userProfileID',
                    customTemplate: (item, val) =>
                        `<a href="/#/Users/${val}" target="_blank">${val}</a>`,
                },
                {
                    title: this.$t('USERS__USERNAME'),
                    key: 'userName',
                },
                {
                    title: this.$t('USERS__FIRST_NAME'),
                    key: 'firstName',
                },
                {
                    title: this.$t('USERS__LAST_NAME'),
                    key: 'lastName',
                },
                {
                    title: this.$t('USERS__EMAIL'),
                    key: 'email',
                },
                {
                    title: this.$t('USERS__MOBILE'),
                    key: 'mobile',
                },
                {
                    title: this.$t('USERS__COUNTRY'),
                    key: 'countryName',
                },
                {
                    title: this.$t('USERS__BALANCE'),
                    key: 'balance',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.realDecimalPlaces)
                        let balance = Math.floor(val * x) / x
                        let currency = item.currencyCode
                        return balance + ' ' + currency
                    },
                },
                {
                    title: this.$t('USERS__BET'),
                    key: 'inAmount',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.realDecimalPlaces)
                        let deposit = Math.floor(val * x) / x
                        return deposit + ' ' + item.currencyCode
                    },
                },
                {
                    title: this.$t('USERS__WIN'),
                    key: 'outAmount',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.realDecimalPlaces)
                        let withdraw = Math.floor(val * x) / x
                        return withdraw + ' ' + item.currencyCode
                    },
                },
                {
                    title: this.$t('USERS__REVENUE'),
                    key: '',
                    customTemplate: (item) => {
                        if(!item.inAmount || !item.outAmount) return 0 + ' ' + item.currencyCode
                        let x = Math.pow(10, item.realDecimalPlaces)
                        let difference = Math.floor((item.inAmount - item.outAmount) * x) / x
                        return difference + ' ' + item.currencyCode
                    },
                    noPermission: true,
                },
                {
                    title: this.$t('USERS__NET_REVENUE'),
                    key: 'netRevenue',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.affiliateRealDecimalPlaces)
                        let netRevenue = Math.floor(val * x) / x
                        let currency = item.affiliateCurrencyCode
                        return netRevenue + ' ' + currency
                    },
                },
                {
                    title: this.$t('USERS__COMMISSION'),
                    key: 'commission',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.affiliateRealDecimalPlaces)
                        let commission = Math.floor(val * x) / x
                        let currency = item.affiliateCurrencyCode
                        return commission + ' ' + currency
                    },
                },
                {
                    title: this.$t('USERS__DEPOSIT'),
                    key: 'depositAmount',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.affiliateRealDecimalPlaces)
                        let deposit = Math.floor(val * x) / x
                        let currency = item.affiliateCurrencyCode
                        return deposit + ' ' + currency
                    },
                },
                {
                    title: this.$t('USERS__WITHDRAW'),
                    key: 'withdrawAmount',
                    customTemplate: (item, val) => {
                        let x = Math.pow(10, item.affiliateRealDecimalPlaces)
                        let withdraw = Math.floor(val * x) / x
                        let currency = item.affiliateCurrencyCode
                        return withdraw + ' ' + currency
                    },
                },
                {
                    title: this.$t('USERS__CREATE_DATE'),
                    key: 'createDate',
                    customTemplate: (item, val) => getDateString(val),
                },
                {
                    title: this.$t('USERS__USER_TYPE'),
                    key: 'userType',
                },
                {
                    title: this.$t('USERS__REGISTRATION_APPROVED'),
                    key: 'registrationApproved',
                    customTemplate: (item, val) => (val ? 'YES' : 'NO'),
                },
            ],
            filter: {
                userProfileID: '',
                userName: '',
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                countryId: '',
            },
        }
    },
    computed: {
        filteredColumns() {
            return this.columns.filter(column =>
                this.columnsThatShouldBeDisplayed.includes(
                    column.key.toLowerCase()
                )
                ||
                column.noPermission && this.columnsThatShouldBeDisplayed.includes('inamount') && this.columnsThatShouldBeDisplayed.includes('outamount') //custom login on revenue column
            )
        },
        //Names of the columns that should be visible to user, determined by privileges
        columnsThatShouldBeDisplayed() {
            var permissionEnumID = this.$store.state.permissionsEnum['UserList']
            var permissions = this.$store.state.permissions[permissionEnumID]
            if (permissions) return permissions.map(e => e.toLowerCase())
            return []
        },
        directory() {
            return this.$store.state.directory
        },
        countryOptions() {
            var options = [{ text: this.$t('USERS__ANY_COUNTRY'), value: '' }]
            if (!this.directory) return options
            this.directory.countries.forEach(c =>
                options.push({ text: c.countryName, value: c.countryId })
            )
            return options
        },
    },
    methods: {
        submitHandler() {
            this.searchUsers()
        },
        clearHandler() {
            this.filter = []
            this.searchUsers()
        },
        searchUsers() {
            this.$refs.usersGrid.search(this.filter)
        },
    },
}
</script>

<style scoped lang="scss">

.grid table thead th {
    text-align: center !important;
}

.grid table thead .title {
    text-align: center !important;
}

.btn-clear {
    margin-left: auto;
}

form.filter-form .row .actions {
    display: flex;
    flex-grow: 1;

    & * {
        width: calc(100% / 3 - 0.9375rem) !important;
    }
}


@media screen and (max-width: 1600px) {
    form.filter-form .row .actions {
        & * {
            width: calc(100% / 2 - 0.9375rem) !important;
        }
    }     
}

@media screen and (max-width: 1100px) {
    form.filter-form .row .actions {
        display: flex;
        flex-grow: 1;

        & * {
            width: calc(100% / 2 - 0.9375rem) !important;
        }
    }     
}

// .filter-form {
//     .row {
//         input,
//         .dd-container,
//         .btn {
//             max-width: calc(20% - 12 *#{$rem});
//         }
//     }
// }
</style>
