<template>
    <div>
        <div class="period-wrapper block">
            <period-item
                v-for="(period, index) in timePeriods"
                :key="index"
                @click.native="changePeriod(period, index)"
                :active="selectedPeriodIndex == index"
                :title="$t(period)"
            ></period-item>
            <period-item
                :title="$t('PERIODS__CUSTOM_PERIOD')"
                @click.native="customPeriodClickHandler"
                :active="selectedPeriodIndex == -1"
            ></period-item>
        </div>
        <div class="block custom-period" v-show="showCustomPeriod">
            <input-date @selected="startDate = $event" :placeholder="$t('PERIODS__START_DATE')"></input-date>
            <input-date @selected="toDate = $event" :placeholder="$t('PERIODS__TO_DATE')"></input-date>
            <Button :title="$t('PERIODS__SET_PERIOD')" outlined @clicked="setCustomPeriod"></Button>
        </div>
    </div>
</template>

<script>
import PeriodItem from './PeriodItem.vue'
import InputDate from '@/components/ReusableComponents/Input/InputDate.vue'
import Button from '@/components/ReusableComponents/Button/Button.vue'
export default {
    mounted() {
        var defaultIndex = 0
        this.changePeriod(this.timePeriods[defaultIndex], defaultIndex)
    },
    components: {
        PeriodItem,
        InputDate,
        Button,
    },
    data: function() {
        return {
            timePeriods: [
               'PERIODS__CURRENT_WEEK',
               'PERIODS__TODAY',
               'PERIODS__YESTERDAY',
               'PERIODS__CURRENT_MONTH',
               'PERIODS__LAST_MONTH',
            ],
            selectedPeriodIndex: 0,
            showCustomPeriod: false,
            startDate: '',
            toDate: '',
        }
    },
    methods: {
        changePeriod(period, index) {
            this.showCustomPeriod = false
            this.selectedPeriodIndex = index
            this.$emit('periodChanged', {
                startDate: this.getStartDate(period),
                toDate: this.getEndDate(period),
            })
        },
        getStartDate(periodName) {
            let date = new Date()
            switch (periodName) {
                case 'PERIODS__TODAY':
                    return new Date(date.setHours(0, 0, 0, 0))
                case 'PERIODS__YESTERDAY':
                    date.setDate(date.getDate() - 1)
                    return new Date(date.setHours(0, 0, 0, 0))
                case 'PERIODS__CURRENT_WEEK':
                    date.setDate(date.getDate() - date.getDay() + 1)
                    return new Date(date.setHours(0, 0, 0, 0))
                case 'PERIODS__CURRENT_MONTH':
                    date.setDate(1)
                    return new Date(date.setHours(0, 0, 0, 0))
                case 'PERIODS__LAST_MONTH':
                    date.setMonth(date.getMonth() - 1)
                    date.setDate(1)
                    return new Date(date.setHours(0, 0, 0, 0))
            }
        },
        getEndDate(periodName) {
            let date = new Date()
            switch (periodName) {
                case 'PERIODS__TODAY':
                    return date
                case 'PERIODS__YESTERDAY':
                    return new Date(date.setHours(0, 0, 0, 0))
                case 'PERIODS__CURRENT_WEEK':
                    return date
                case 'PERIODS__CURRENT_MONTH':
                    return date
                case 'PERIODS__LAST_MONTH':
                    date.setDate(1)
                    return new Date(date.setHours(0, 0, 0, 0))
            }
        },
        customPeriodClickHandler() {
            this.showCustomPeriod = !this.showCustomPeriod
            this.selectedPeriodIndex = -1
        },
        setCustomPeriod() {
            this.startDate.setHours(0)
            this.startDate.setMinutes(0)
            this.startDate.setSeconds(0)
            this.toDate.setHours(23)
            this.toDate.setMinutes(59)
            this.toDate.setSeconds(59)
            this.$emit('periodChanged', {
                startDate: this.startDate,
                toDate: this.toDate,
            })
            this.showCustomPeriod = false
        },
    },
}
</script>

<style scoped lang="scss">
.period-wrapper {
    display: flex;
    margin-bottom: 15 * $rem;
    height: 50 * $rem;
    cursor: pointer;
    overflow-x: auto;
}
.custom-period {
    margin-bottom: 15 * $rem;
    padding: 10 * $rem 0;
    display: flex;
    justify-content: center;
    overflow: visible;

    /deep/ .input-wrapper {
        margin-right: 15 * $rem;
    }
}
</style>
