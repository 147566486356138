<template>
    <div>
        <div class="row">
            <info-block :title="$t('INFORMATION__PERSONAL_INFO')" :fields="personalInfoFields"></info-block>
            <info-block :title="$t('INFORMATION__ACCOUNT_INFO')" :fields="accountInfoFields"></info-block>
        </div>
        <div class="row">
            <info-block :title="$t('INFORMATION__LAST_LOGIN')" :fields="lastLoginFields"></info-block>
            <div class="block wallets-grid-wrapper">
                <h4>{{ $t('INFORMATION__WALLETS') }}</h4>
                <grid
                    v-if="data != null"
                    :passedData="data.userWallets"
                    :columns="walletColumns"
                    :pagination="false"
                    :toggleable="false"
                    :searchable="false"
                ></grid>
            </div>
        </div>
    </div>
</template>

<script>
import InfoBlock from '@/components/ReusableComponents/InfoBlock/InfoBlock.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
export default {
    props: {
        data: Object,
    },
    components: {
        InfoBlock,
        Grid,
    },
    computed: {
        personalInfoFields() {
            var fields = []
            if (this.data && this.directory != null) {
                let country = this.directory.countries.filter(
                    c => c.countryId == this.data.userProfile.countryId
                )[0]
                let countryName = country ? country.countryName : ''
                fields = [
                    {
                        name: this.$t('INFORMATION__ID'),
                        value: this.data.userProfile.userProfileID,
                        permissionName: 'userprofileid',
                    },
                    {
                        name: this.$t('INFORMATION__FIRST_NAME'),
                        value: this.data.userProfile.firstName,
                        permissionName: 'firstname',
                    },
                    {
                        name: this.$t('INFORMATION__MIDDLE_NAME'),
                        value: this.data.userProfile.middleName,
                        permissionName: 'middlename',
                    },
                    {
                        name: this.$t('INFORMATION__LAST_NAME'),
                        value: this.data.userProfile.lastName,
                        permissionName: 'lastname',
                    },
                    {
                        name: this.$t('INFORMATION__EMAIL'),
                        value: this.data.userProfile.email,
                        permissionName: 'email',
                    },
                    {
                        name: this.$t('INFORMATION__MOBILE'),
                        value: this.data.userProfile.mobile,
                        permissionName: 'mobile',
                    },
                    {
                        name: this.$t('INFORMATION__MOBILE_CODE'),
                        value: this.data.userProfile.mobileCode,
                        permissionName: 'mobileCode',
                    },
                    {
                        name: this.$t('INFORMATION__DATE_OF_BIRTH'),
                        value: this.data.userProfile.birthDate
                            ? getDateString(this.data.userProfile.birthDate)
                            : '-',
                        permissionName: 'birthDate',
                    },
                    {
                        name: this.$t('INFORMATION__COUNTRY'),
                        value: countryName,
                        permissionName: 'countryId',
                    },
                    {
                        name: this.$t('INFORMATION__CITY'),
                        value: this.data.userProfile.city,
                        permissionName: 'city',
                    },
                    {
                        name: this.$t('INFORMATION__ADDRESS'),
                        value: this.data.userProfile.address,
                        permissionName: 'address',
                    },
                    {
                        name: this.$t('INFORMATION__POSTAL_CODE'),
                        value: this.data.userProfile.postalCode,
                        permissionName: 'postalCode',
                    },
                ]
                fields = this.restrictDataWithPermissions(
                    fields,
                    this.infoFieldNamesToDisplay
                )
            }
            return fields
        },
        accountInfoFields() {
            var fields = []
            if (this.data && this.directory) {
                fields = [
                    {
                        name: this.$t('INFORMATION__USERNAME'),
                        value: this.data.userProfile.userName,
                        permissionName: 'userName',
                    },
                    {
                        name: this.$t('INFORMATION__DEFAULT_LANGUAGE'),
                        value: this.data.userProfile.defaultLanguage,
                        permissionName: 'defaultLanguage',
                    },
                    {
                        name: this.$t('INFORMATION__IP'),
                        value: this.data.userProfile.ipAddress
                            ? `<a href="https://www.ip2location.com/demo/${this.data.userProfile.ipAddress}" target="_blank">
                                    ${this.data.userProfile.ipAddress}
                                </a>`
                            : '-',
                        permissionName: 'ipAddress',
                    },
                    {
                        name: this.$t('INFORMATION__KYC_STATUS'),
                        value: this.data.userProfile.kycStatus
                            ? 'Verified'
                            : 'Not Verified',
                        permissionName: 'kycstatus',
                    },
                    {
                        name: this.$t('INFORMATION__TWO_STEP_AUTHENTIFICATION'),
                        value: this.data.userProfile.twoStepAuthEnabled
                            ? 'Enabled'
                            : 'Disabled',
                        permissionName: 'twoStepAuthEnabled',
                    },
                    {
                        name: this.$t('INFORMATION__PHONE_VERIFIED'),
                        value: this.data.userProfile.phoneVerified
                            ? 'YES'
                            : 'NO',
                        permissionName: 'phoneVerified',
                    },
                    {
                        name: this.$t('INFORMATION__MAIL_VERIFIED'),
                        value: this.data.userProfile.mailVerified
                            ? 'YES'
                            : 'NO',
                        permissionName: 'mailVerified',
                    },
                    {
                        name: this.$t('INFORMATION__WITHDRAW_ALLOWED'),
                        value: this.data.userProfile.withdrawAllowed
                            ? 'YES'
                            : 'NO',
                        permissionName: 'withdrawAllowed',
                    },
                    {
                        name: this.$t('INFORMATION__BET_ALLOWED'),
                        value: this.data.userProfile.betAllowed ? 'YES' : 'NO',
                        permissionName: 'betAllowed',
                    },
                    {
                        name: this.$t('INFORMATION__DEPOSIT_ALLOWED'),
                        value: this.data.userProfile.depositAllowed
                            ? 'YES'
                            : 'NO',
                        permissionName: 'depositAllowed',
                    },
                ]
                fields = this.restrictDataWithPermissions(
                    fields,
                    this.infoFieldNamesToDisplay
                )
            }
            return fields
        },
        infoFieldNamesToDisplay() {
            var permEnum = this.$store.state.permissionsEnum[
                'AffiliateUserProfile_UserProfile'
            ]
            var permissions = this.$store.state.permissions[permEnum]
            if (permissions) return permissions.map(el => el.toLowerCase())
            return []
        },
        walletColumns() {
            var columns = []
            if (this.data && this.directory) {
                let directory = this.directory
                columns = [
                    {
                        title: this.$t('INFORMATION__CURRENCY'),
                        key: 'currencyId',
                        customTemplate: (item, val) => {
                            return directory.currencies.filter(
                                c => c.currencyId == val
                            )[0].currencyName
                        },
                        permissionName: 'currencyId',
                    },
                    {
                        title: this.$t('INFORMATION__BALANCE'),
                        key: 'balance',
                        customTemplate: (item, val) =>
                            `<span class="blue">${val}</span>`,
                        permissionName: 'balance',
                    },
                    {
                        title: this.$t('INFORMATION__FROZEN_BALANCE'),
                        key: 'frozenBalance',
                        permissionName: 'frozenBalance',
                    },
                    {
                        title: this.$t('INFORMATION__BONUS_POINTS'),
                        key: 'bonusPoints',
                        permissionName: 'bonusPoints',
                    },
                ]
                columns = this.restrictDataWithPermissions(
                    columns,
                    this.walletColumnNamesToDisplay
                )
            }
            return columns
        },
        walletColumnNamesToDisplay() {
            var permEnum = this.$store.state.permissionsEnum[
                'AffiliateUserProfile_UserWallets'
            ]
            var permissions = this.$store.state.permissions[permEnum]
            if (permissions) return permissions.map(el => el.toLowerCase())
            return []
        },
        lastLoginFields() {
            var fields = []
            if (this.data && this.directory && this.data.lastLogin) {
                let browser = this.data.lastLogin.browser
                let parsedBrowser = browser
                    ? browser.split(',')[1].split('-')[1]
                    : '-'
                fields = [
                    {
                        name: this.$t('INFORMATION__DATE'),
                        value: getDateString(this.data.lastLogin.createDate),
                        permissionName: 'createDate',
                    },
                    {
                        name: this.$t('INFORMATION__DEVICE_TYPE'),
                        value: this.data.lastLogin.deviceType,
                        permissionName: 'deviceType',
                    },
                    {
                        name: this.$t('INFORMATION__OPERATING_SYSTEM'),
                        value: this.data.lastLogin.os,
                        permissionName: 'os',
                    },
                    {
                        name: this.$t('INFORMATION__BROWSER'),
                        value: parsedBrowser,
                        permissionName: 'browser',
                    },
                    {
                        name: this.$t('INFORMATION__HOST_ADDRESS'),
                        value: this.data.lastLogin.hostAddress,
                        permissionName: 'hostAddress',
                    },
                ]
                fields = this.restrictDataWithPermissions(
                    fields,
                    this.lastLoginFieldNamesToDisplay
                )
            }
            return fields
        },
        lastLoginFieldNamesToDisplay() {
            var permEnum = this.$store.state.permissionsEnum[
                'AffiliateUserProfile_LastLogin'
            ]
            var permissions = this.$store.state.permissions[permEnum]
            if (permissions) return permissions.map(el => el.toLowerCase())
            return []
        },
        directory() {
            return this.$store.state.directory
        },
    },
    methods: {
        restrictDataWithPermissions(data, permissionNames) {
            return data.filter(
                element =>
                    !element.permissionName ||
                    permissionNames.includes(
                        element.permissionName.toLowerCase()
                    )
            )
        },
    },
}
</script>

<style scoped lang="scss">
.row {
    display: flex;

    /deep/ .block:first-child {
        margin-right: 12 * $rem;
    }

    &:not(:first-child) {
        margin-top: 15 * $rem;
    }
}

.wallets-grid-wrapper {
    padding: 35 * $rem 40 * $rem;
    width: calc((100% - 15 * #{$rem}) / 2);

    h4 {
        color: #6a7fe9;
        font-size: 17 * $rem;
        font-style: italic;
        margin-bottom: 50 * $rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 780px) {
    .row {
        flex-direction:column;
        margin-top: 0 !important;
        .block {
            width:100%;
            margin-bottom: 0.9375rem;
        }
    }
}
</style>
