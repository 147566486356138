<template>
    <nav class="sidebar" :class="{'show-on-mobile':showSidebarOnMobile, 'slide-in': slideIn}">
        <div class="content-wrapper">
            <div class="toggle-button" @click="slideInSidebar" :class="{'toggled': slideIn}">
                <i class="material-icons">{{arrowDirection}}</i>
            </div>
            <!-- <div class="hover-area" v-if="slideIn" @mouseover="slideIn = !slideIn"></div> -->
            <router-link to="/Dashboard" class="logo-url">
                <img :src="$store.state.assetsPath + logoUrl" alt="logo" v-if="logoUrl" />
            </router-link>

            <div class="side-menu">
                <side-menu-item
                    v-for="(item, index) in items"
                    :key="index"
                    :text="item.title"
                    :icon="item.icon"
                    :routerLink="item.routerLink"
                    :outlined="item.outlined"
                    :subItems="item.subItems"
                    :hideSideMenuIfMobile="() => showSidebarOnMobile = false"
                ></side-menu-item>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import SideMenuItem from './SideMenuItem.vue'
import EventBus from '@/Helpers/EventBus.js'

export default {
    created() {
        EventBus.$on('toggleSideMenuForMobile', () => {
            this.showSidebarOnMobile = !this.showSidebarOnMobile
        })

        axios.get('Profile/GetHeaderInfo').then(resp => {
            this.logoUrl = resp.data.affiliatePanelLogoUrl
        })
    },

    components: {
        SideMenuItem,
    },

    data() {
        return {
            showSidebarOnMobile: false, // hide/show
            slideIn: false,
            logoUrl: null,
        }
    },

    methods: {
        slideInSidebar() {
            EventBus.$emit('toggleSidebar')
            this.slideIn = !this.slideIn
        },
        removeNotAccessibleSideMenuItems (items) {
            Object.keys(items).forEach(key => {
                let menuItem = items[key]
                if(menuItem.permissionName) {
                    if(this.listOfAccessibleSideMenuItems.includes(menuItem.permissionName)) return
                    delete items[key] //if we can't access menu item, remove it
                }
                else if(menuItem.subItems) {
                    var subItemsDoNotHavePermissions = menuItem
                            .subItems
                            .every(subItem => !subItem.permissionName)
                    if(subItemsDoNotHavePermissions) return //if menu sub-items don't have permissions, don't remove them 

                    var canNotAccessAnySubItem = 
                        menuItem.subItems
                                .every(subItem => !this.listOfAccessibleSideMenuItems.includes(subItem.permissionName))
                    if(canNotAccessAnySubItem) {
                        delete items[key] //delete menu item because we can't display any sub-item
                        return
                    }
                    menuItem.subItems.filter(subItem => subItem.permissionName).forEach(subItem => {
                        if(!this.listOfAccessibleSideMenuItems.includes(subItem.permissionName)) {
                            delete items[key].subItems.splice(items[key].subItems.indexOf(subItem), 1) //remove specific subItem if we can't access it
                        }
                    })
                }
            })
            return items
        }
    },

    computed: {
        arrowDirection() {
            return this.slideIn ? 'arrow_forward' : 'arrow_back'
        },
        listOfAccessibleSideMenuItems() {
            var ID = this.permissionsEnum['General']
            return this.$store.state.permissions[ID]
        },
        permissionsEnum() {
            return this.$store.state.permissionsEnum
        },
        //change the layout here
        items() {
            if(!this.listOfAccessibleSideMenuItems) {
                return null
            }
            var items =  {
                dashboard: {
                    title: this.$t('MENU__DASHBOARD'),
                    icon: 'dashboard',
                    routerLink: '/Dashboard',
                },
                users: {
                    title: this.$t('MENU__USERS'),
                    icon: 'group',
                    routerLink: '/Users',
                    permissionName: 'UserList'
                },
                registerUsers: {
                    title: this.$t('MENU__REGISTER_USERS'),
                    icon: 'group',
                    routerLink: '/RegisterUsers',
                    permissionName: 'RegistrationPartnerUsers'
                },
                reports: {
                    title: this.$t('MENU__REPORTS'),
                    icon: 'assessment',
                    subItems: [
                        {
                            title: this.$t('MENU__GENERAL_REPORT'),
                            routerLink: '/Reports/GeneralReport',
                            permissionName: 'Report_General_Report'
                        },
                        {
                            title: this.$t('MENU__ACTIVITY_REPORT'),
                            routerLink: '/Reports/ActivityReport',
                            permissionName: 'Report_Activity_Report'
                        },
                        {
                            title: this.$t('MENU__SALES_REPORT'),
                            routerLink: '/Reports/SalesReport',
                            permissionName: 'Report_Sales_Report'
                        },
                        {
                            title: this.$t('MENU__MEDIA_REPORT'),
                            routerLink: '/Reports/MediaReport',
                            permissionName: 'Report_Media_Report'
                        },
                        {
                            title: this.$t('MENU__PRODUCT_REPORT'),
                            routerLink: '/Reports/ProductReport',
                            permissionName: 'Report_Product_Report'
                        },
                        {
                            title: this.$t('MENU__GEOGRAPHICAL_REPORT'),
                            routerLink: '/Reports/GeographicalReport',
                            permissionName: 'Report_Geographical_Report'
                        }
                    ],
                },
                marketing: {
                    title: this.$t('MENU__MARKETING_TOOLS'),
                    icon: 'collections',
                    subItems: [
                        {
                            title: this.$t('MENU__AFFILIATE_MEDIA_RESOURCES'),
                            routerLink: '/Marketing/MediaResources',
                            permissionName: 'Marketing_Affiliate_Media_Resources'
                        },
                        {
                            title: this.$t('MENU__BANNERS'),
                            routerLink: '/Marketing/Banners',
                            permissionName: 'Marketing_Banners'
                        },
                        {
                            title: this.$t('MENU__LINKS'),
                            routerLink: '/Marketing/Links',
                            permissionName: 'Marketing_Links'
                        },
                        {
                            title: this.$t('MENU__TEXTS'),
                            routerLink: '/Marketing/Texts',
                            permissionName: 'Marketing_Texts'
                        },
                    ],
                },
                cashDesk: {
                    title: this.$t('MENU__CASHDESK'),
                    icon: 'attach_money',
                    routerLink: '/Cashdesk',
                    permissionName: 'GetPartnerTransaction'
                },
                transactions: {
                    title: this.$t('MENU__TRANSACTIONS'),
                    icon: 'compare_arrows',
                    routerLink: '/Transactions',
                    permissionName: 'GetPartnerTransaction'
                },
            }
            items = this.removeNotAccessibleSideMenuItems(items) 
            return items
        },
        userID() {
            return this.$store.state.userID
        },
    },
}
</script>

<style scoped lang="scss">
nav {
    // overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: $charade;
    display: block;
    width: 293 * $rem;
    transform: translateX(0);
    z-index: 10;
    transition: transform 0.3s ease-out;

    .content-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .logo-url {
            width: 100%;
            padding: 20 * $rem;
            height: 76 * $rem;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            background: $charade-dark;
            display: inline-block;
            text-decoration: none;
            color: inherit;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                filter: brightness(1.5);
            }
        }

        .toggle-button {
            position: absolute;
            background-color: #ffffff;
            border-radius: 14px;
            right: -12 * $rem;
            z-index: 149;
            top: 22 * $rem;
            display: flex;
            align-items: center;
            padding: 5 * $rem;
            box-shadow: 0 0 10 * $rem #372727b3;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &.toggled {
                right: -24 * $rem;
                padding-left: 15 * $rem;
            }

            i {
                font-size: 18 * $rem;
            }
        }

        .hover-area {
            position: fixed;
            right: -30 * $rem;
            margin-top: 75 * $rem;
            width: 30 * $rem;
            height: calc(100vh - 75 *#{$rem});
            opacity: 0;
        }
    }
}

.show-on-mobile {
    transform: translateX(0);
}

.slide-in {
    transform: translateX(-293 * $rem);
}

@media only screen and (max-width: 1024px) {
    nav {
        transform: translateX(-293 * $rem);
        height: calc(100vh-74 *#{$rem});
        margin-top: 74 * $rem;
        overflow-y: auto;

        .content-wrapper .toggle-button {
            display: none;
        }
    }
}
</style>
