<template>
    <div class="report-chart block">
        <div ref="container" class="chart-wrapper"></div>
        <dropdown v-model="selectedOption" :options="selectOptions"></dropdown>
    </div>
</template>

<script>
/*eslint-disable */
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
export default {
    props: {
        chartData: Array,
    },
    components: {
        Dropdown,
    },
    mounted() {
        this.loadChart()
    },
    data() {
        return {
            selectOptions: [
                {
                    text: this.$t('GEOGRAPHICAL__SIGNUPS'),
                    value: 'signups',
                },
                {
                    text: this.$t('GEOGRAPHICAL__DEPOSITING_PLAYERS'),
                    value: 'depositingPlayers',
                },
                {
                    text: this.$t('GEOGRAPHICAL__ACTIVE_PLAYERS'),
                    value: 'activePlayers',
                },
                {
                    text: this.$t('GEOGRAPHICAL__FTD_PLAYERS'),
                    value: 'firstTimeDepositingPlayers',
                },
                {
                    text: this.$t('GEOGRAPHICAL__NET_AMOUNT'),
                    value: 'netAmount',
                },
                {
                    text: this.$t('GEOGRAPHICAL__DEPOSIT_AMOUNT'),
                    value: 'depositAmount',
                },
            ],
            selectedOption: 'signups',
        }
    },
    methods: {
        loadChart() {
            var container = this.$refs.container
            var columns = this.chartData
            google.charts.load('current', {
                packages: ['geochart'],
                mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY',
            })
            google.charts.setOnLoadCallback(drawRegionsMap)
            var selectedValue = this.selectedOption

            function drawRegionsMap() {
                var arr = []
                arr.push(new Array('Country', 'Count'))
                columns.forEach(el =>
                    arr.push(new Array(el.countryName, el[selectedValue]))
                )
                var dt = google.visualization.arrayToDataTable(arr)

                var options = {
                    // region: ''
                }

                var chart = new google.visualization.GeoChart(container)

                chart.draw(dt, options)
            }
        },
    },
    watch: {
        chartData() {
            this.loadChart()
        },
        selectedOption() {
            this.loadChart()
        }
    },
}
</script>

<style scoped lang="scss">
.report-chart {
    display: flex;

    .chart-wrapper {
        width: 80%;
        height: 500 * $rem;
    }

    /deep/ .dd-container {
        margin-left: auto;
        margin-right: 30*$rem;
        margin-top: 30*$rem;
        width: 250*$rem;
        height: 40*$rem;
    }
}
</style>
