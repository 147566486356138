<template>
    <div class="activity-report">
        <periods @periodChanged="periodChangehandler"></periods>
        <div class="blocks-row">
            <block-with-sub
                title="Player Signups"
                :amount="blockData.playersSignups"
                description="Total number of players that registered in the selected period"
            ></block-with-sub>
            <block-with-sub
                title="Depositing Players"
                :amount="blockData.depositingPlayers"
                description="Total number of players that have made a deposit in the selected period"
            ></block-with-sub>
            <block-with-sub
                title="Active Players"
                :amount="blockData.activePlayers"
                description="Total number of players that generated revenue in the selected period"
            ></block-with-sub>
            <block-with-sub
                title="First Time Depositing Players"
                :amount="blockData.firsTimeDepositingPlayers"
                description="Total number of players that deposited for the first time in the selected period"
            ></block-with-sub>
        </div>
        <line-chart :chartData="lineChartData" :columnNames="lineChartColumns" :chartWidth="1400"></line-chart>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import Periods from '@/components/ReusableComponents/Periods/Periods.vue'
import LineChart from '@/components/ReusableComponents/Charts/LineChart.vue'
import BlockWithSub from './BlockWithSub.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'
export default {
    components: {
        Periods,
        LineChart,
        BlockWithSub,
    },
    data: function() {
        return {
            lineChartData: [],
            lineChartColumns: [
                'Date',
                'Player Signups',
                'Depositing Players',
                'Active Players',
                'First Time Deposting Players',
            ],
            blockData: {},
        }
    },
    methods: {
        getData(startDate, toDate) {
            axios
                .get('Report/GetActivity', {
                    params: {
                        beginDate: getDateString(
                            startDate,
                            'yyyy-MM-dd HH:mm:ss'
                        ),
                        endDate: getDateString(
                            toDate,
                            'yyyy-MM-dd HH:mm:ss'
                        ),
                    },
                })
                .then(resp => {
                    this.lineChartData = resp.data.dataByMonth
                    this.blockData = resp.data.data
                })
        },
        periodChangehandler(data) {
            this.getData(data.startDate, data.toDate)
        },
    },
}
</script>

<style scoped lang="scss">
.blocks-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20 * $rem;
    margin-top: -15 * $rem;

    /deep/ .block {
        width: calc((100% - 60 *#{$rem}) / 4);
        margin-top: 15 * $rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
/deep/ .line-chart-block {
    width: 100%;
}

@media only screen and (max-width: 1280px) {
    .blocks-row {
        /deep/ .block {
            width: calc((100% - 20 *#{$rem}) / 2);
            margin-right: 0;
            &:nth-child(2n + 1) {
                margin-right: 20 * $rem;
            }
        }
    }
}

@media only screen and (max-width: 565px) {
    .blocks-row {
        margin-top: -10 * $rem;

        /deep/ .block {
            width: 100%;
            margin-right: 0 !important;
            margin-top: 10 * $rem;
        }
    }
}
</style>
