<template>
    <sections :titles="titles" @changeSection="(index) => this.activeSection = index">
        <Information :data="userProfileData" v-show="activeSection == 0"></Information>
    </sections>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import Sections from '@/components/ReusableComponents/Sections/Sections.vue'
import Information from './Information.vue'

export default {
    created() {
        var id = this.$route.params.id
        axios
            .get(`Customer/GetAffiliateUserbyId/${id}`)
            .then(resp => {
                this.userProfileData = resp.data
            })
    },
    components: {
        Sections,
        Information,
    },
    data() {
        return {
            titles: ['Information'],
            activeSection: 0,
            userProfileData: null,
        }
    },
}
</script>

<style>
</style>
