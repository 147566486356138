<template>
    <div>
        <div class="links">
            <form class="filters-wrapper" @submit.prevent="searchHandler">
                <input type="text" :placeholder="$t('LINKS__NAME')" v-model="form.name">
                <dropdown name="types" :options="linkTypeOptions" v-model="form.linkCategory"></dropdown>
                <dropdown name="products" :options="productOptions" v-model="form.productId"></dropdown>
                <dropdown
                    name="languages"
                    :options="languageOptions"
                    v-model="form.languageId"
                    searchable
                ></dropdown>
                <dropdown name="statuses" :options="statusOptions" v-model="form.status"></dropdown>
                <div class="filter-buttons">
                    <button class="btn clear-btn" type="button">{{ $t('LINKS__CLEAR_ALL') }}</button>
                    <button class="btn search-btn" type="submit">{{ $t('LINKS__SEARCH') }}</button>
                </div>
            </form>
            <grid
                :columns="linkGridColumns"
                identificator="id"
                fullGrid
                :searchable="false"
                :passedData="linkGridData"
                :passedPageSize="postData.pageLength"
                :passedCount="totalCount"
                :searchedColumns="this.postData.params"
                :exportable="false"
                @pageChanged="pageChangeHandler"
                @pageCountChanged="pageCountChangeHandler"
                @sort="sortHandler"
            ></grid>
            <overlay
                :visible="activateMediaPopupVisible"
                @close="activateMediaPopupVisible = false"
                class="activateMediaPopup"
            >
                <div class="activate-popup">
                    <div class="header">
                        <h4>Activate</h4>
                    </div>
                    <form class="content" @submit.prevent="activateHandler">
                        <div class="row">
                            <span>Name</span>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                autocomplete="off"
                                required
                                v-model="activationForm.name"
                            >
                        </div>

                        <div class="row">
                            <span>Link</span>

                            <input type="text" name="link" :value="activationFormLink" disabled>
                        </div>

                        <div class="row" v-if='commissionModeType == 3'>
                            <span>Referral Type</span>

                            <dropdown
                                name="referralType"
                                :options="referralTypeOptions"
                                v-model="activationForm.referralType"
                            ></dropdown>
                        </div>

                        <button class="btn create-media">Create</button>
                        <div
                            class="btn cancel-creating-media"
                            @click="activateMediaPopupVisible = false"
                        >Cancel</div>
                    </form>
                </div>
            </overlay>
            <overlay
                :visible="getCodePopupVisible"
                @close="getCodePopupVisible = false"
            >
                <div class="popup grid-wrapper">
                    <grid
                        :passedData="getCodeGridData"
                        :columns="getCodeGridColumns"
                        :pagination="false"
                        :toggleable="false"
                        :searchable="false"
                        :pageSize="40"
                        identificator="id"
                    >
                    </grid>
                </div>
            </overlay>
            <div class="sections">
                <div
                    class="all"
                    :class="{'selected': !recommendedSelected}"
                    @click="allClickHandler"
                >{{$t('LINKS__ALL')}}</div>
                <div
                    class="recommended"
                    :class="{'selected': recommendedSelected}"
                    @click="recommendedClickHandler"
                >{{$t('LINKS__RECOMMENDED')}}</div>
            </div>
        </div>
        <message ref="message" :message="createMessage.text" :success="createMessage.success"></message>
    </div>
</template>

<script>
    import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
    import axios from '@/Helpers/AxiosInstance.js'
    import Message from '@/components/ReusableComponents/Message/Message.vue'
    import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
    import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'

    import EventBus from '@/Helpers/EventBus.js'
    import { getDateString } from '@/Helpers/HelperFunctions.js'
    import { copyToClipboard } from '@/Helpers/HelperFunctions.js'

    export default {
        components: {
            Grid,
            Overlay,
            Message,
            Dropdown,
        },

        created() {
            this.setData()

            axios.get('Profile/GetProfile').then(resp => {
                this.commissionModeType = resp.data.commissionModeType
            })
        },

        mounted() {
            this.$el.addEventListener('click', event => {
                let t = event.target
                while (t && t !== this) {
                    if (t.matches('.create')) {
                        //activation popup
                        let rowID = this.getRowID(t)
                        this.prepareMediaActivatorPopup(rowID)
                        this.activateMediaPopupVisible = true
                    } else if (t.matches('.copy-code')) {
                        //copy generated code
                        this.changeCopyTextForAWhile(t)
                        copyToClipboard(t.parentNode.firstElementChild.innerText)
                    }
                    t = t.parentNode
                    if (t == this.$el) break
                }
            })
        },

        data() {
            return {
                linkGridColumns: [
                    {
                        title: this.$t('LINKS__ID'),
                        key: 'id',
                    },
                    {
                        title: this.$t('LINKS__MEDIA_NAME'),
                        key: 'name',
                    },
                    {
                        title: this.$t('LINKS__TYPE'),
                        key: 'linkCategory',
                    },
                    {
                        title: this.$t('LINKS__PRODUCT'),
                        key: 'mediaProduct',
                    },
                    {
                        title: this.$t('LINKS__LANGUAGE'),
                        key: 'langCode',
                        customTemplate: (item, val) => {
                            if(item.hasManyLanguage) return 'Many'
                            return val
                        }
                    },
                    {
                        title: this.$t('LINKS__ACTIVATION_DATE'),
                        key: 'activatedDate',
                        customTemplate: (item, val) => val ? getDateString(val) : '-',
                    },
                    {
                        title: this.$t('LINKS__URL'),
                        key: 'url',
                        customTemplate: (item, val) => {
                            if (!val) return ''

                            var domain = this.directory.brandActiveMainDomain
                            val = val.replace('{domain}', domain.toLowerCase())

                            return `<a class="link" href="${val}" target="_blank">${val}</a>`
                        }
                    },
                    {
                        title: this.$t('LINKS__STATUS'),
                        key: 'status',
                        customTemplate: (item, val) => {
                            if (val) {
                                return '<span class="status-active">Active</span>'
                            }
                            return '<span class="status-inactive">Inactive</span>'
                        },
                    },
                    {
                        title: this.$t('LINKS__ACTIONS'),
                        key: 'id',
                        plainColumn: true,
                        customTemplate: () => {
                            return `
                            <div class="actions media-actions">
                                <div class="create btn" title="Create Media">Create</div>
                            </div>`
                        },
                    },
                ],
                linkGridData: [],
                totalCount: 0,
                postData: {
                    orderByParam: 'id',
                    orderByIsDesc: true,
                    pageCount: 1,
                    pageLength: 15,
                    params: [
                        {
                            operator: 0,
                            paramName: 'mediaTypeId',
                            value: 1,
                        },
                    ],
                },
                activateMediaPopupVisible: false,
                linkOptions: [],
                referralTypeOptions: [
                    { text: this.$t('LINKS__ORDINARY'), value: 1 },
                    { text: this.$t('LINKS__CPA '), value: 2 }
                ],
                form: {
                    linkCategory: '',
                    productId: '',
                    languageId: '',
                    name: '',
                    status: '',
                },
                statusOptions: [
                    {
                        text: this.$t('LINKS__ANY_STATUS'),
                        value: '',
                    },
                    {
                        text: this.$t('LINKS__ACTIVE'),
                        value: '1',
                    },
                    {
                        text: this.$t('LINKS__INACTIVE'),
                        value: '2',
                    },
                ],
                activationForm: {
                    name: '',
                    link: '',
                    referralType: null
                },
                createMessage: {
                    text: '',
                    success: false,
                },
                recommendedSelected: false,
                getCodeGridColumns: [
                    {
                        title: '',
                        key: 'code',
                        customTemplate: (item, val) => `
                            <div class="generate-code-wrapper">
                                <span>${item.langCode}</span>
                                <div class="code-container">
                                    <p>${val}</p>
                                    <div class="btn copy-code">Copy</div>
                                </div>
                            </div>
                        `
                    },
                ],
                getCodeGridData: [],
                getCodePopupVisible: false,

                commissionModeType: null
            }
        },

        methods: {
            setData() {
                if (!this.directory) {
                    EventBus.$on('setDirectory', () => { this.setData() })
                    return
                }

                axios.post('Media/GetMediaTemplates', this.postData).then(resp => {
                    this.linkGridData = resp.data.data
                    this.totalCount = resp.data.count
                })
            },
            pageChangeHandler(page) {
                this.postData.pageCount = page
                this.setData()
            },
            pageCountChangeHandler(count) {
                this.postData.pageLength = parseInt(count)
                this.postData.pageCount = 1
                this.setData()
            },
            sortHandler(data) {
                this.postData.orderByParam = data.columnName
                this.postData.orderByIsDesc = data.orderByDesc
                this.setData()
            },
            searchHandler() {
                this.postData.params = [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 1,
                    },
                ]
                Object.keys(this.form).forEach(key => {
                    if (this.form[key]) {
                        let param = {
                            operator: 0,
                            paramName: key,
                        }
                        if(key == 'status') param.value = this.form[key] == 1 ? true : false
                        else param.value = this.form[key]
                        //add param to postdata
                        this.postData.params.push(param)
                    }
                })
                this.setData()
            },
            prepareLocalizationsPopup(rowID) {
                axios
                    .get('Media/GetMediaTemplateLocalizations', {
                        params: { mediaTemplateId: rowID },
                    })
                    .then(resp => (this.localizationData = resp.data))
            },
            prepareMediaActivatorPopup(rowID) {
                this.selectedRowID = rowID
                var item = this.linkGridData.filter(el => el.id == rowID)[0]
                this.activationForm.link = item.url || ''
            },
            //get the item ID that was clicked in row
            getRowID(target) {
                let parent = target.parentNode
                while (!parent.matches('tr')) {
                    parent = parent.parentNode
                }
                return parent.getAttribute('rowid')
            },
            //activate click
            activateHandler() {
                if (this.commissionModeType !== 3) {
                    this.activationForm.referralType = this.commissionModeType
                }

                var data = {
                    mediaTemplateId: this.selectedRowID,
                    name: this.activationForm.name,
                    referralType: this.activationForm.referralType
                }

                axios.post('Media/CreateAffiliateMedia', data)
                    .then(resp => {
                        this.activateMediaPopupVisible = false
                        this.activationForm.name = ''
                        if (resp.data.code == 1) {
                            this.createMessage.text = 'Successfully Created'
                            this.createMessage.success = true
                            this.getCodePopupVisible = true
                            this.generateCodesAndShowPopup(resp.data.value)
                        } else {
                            this.createMessage.text = 'An Error Has Occured'
                            this.createMessage.success = false
                        }
                        this.$refs.message.showMessage()
                    })
                    .catch(() => {
                        this.createMessage.text = 'An Error Has Occured'
                        this.createMessage.success = false
                    })
            },
            clearFilters() {},
            allClickHandler() {
                this.recommendedSelected = false
                this.clearFilters()
                this.postData.params = [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 1,
                    },
                ]
                this.setData()
            },
            recommendedClickHandler() {
                this.recommendedSelected = true
                this.clearFilters()
                this.postData.params = [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 1,
                    },
                    {
                        operator: 0,
                        paramName: 'recommended',
                        value: true,
                    },
                ]
                this.setData()
            },
            generateCodesAndShowPopup(data) {
                var gridData = []
                data.mediaLocalizations.forEach(el => {
                    let linkUrl = data.linkUrl.replace('https://tracker.inplaynetpartners.com/', this.directory.brandTrackerUrl)
                    let linkCode = this.generateLinkCode(el, data.affilateGId, linkUrl)
                    gridData.push({
                        langCode: el.langCode,
                        code: linkCode,
                    })
                })
                this.getCodeGridData = gridData
            },
            generateLinkCode(localization, affilateGId, url) {
                return `${url}?mediaid=${localization.mediaId}&amp;lang=${localization.langCode}&amp;affiliategid=${affilateGId}`
            },
            //changes 'Copy' to 'Copied' for 3 seconds
            changeCopyTextForAWhile(t) {
                t.innerText = 'Copied'
                setTimeout(() => {
                    t.innerText = 'Copy'
                }, 3000);
            },
        },

        computed: {
            directory() {
                return this.$store.state.directory
            },
            linkTypeOptions() {
                var options = [{ text: this.$t('LINKS__ANY_TYPE'), value: '' }]
                if (this.directory) {
                    this.directory.affiliateLinkCategories.forEach(el =>
                        options.push({ text: el.name, value: el.id })
                    )
                }
                return options
            },
            productOptions() {
                var options = [{ text: this.$t('LINKS__ANY_PRODUCT'), value: '' }]
                if (this.directory) {
                    this.directory.afiliateMediaProducts.forEach(el =>
                        options.push({ text: el.name, value: el.id })
                    )
                }
                return options
            },
            languageOptions() {
                var options = [{ text: this.$t('LINKS__ANY_LANGUAGE'), value: '' }]
                if (this.directory) {
                    this.directory.languages.forEach(el =>
                        options.push({ text: el.langCodeShort, value: el.id })
                    )
                }
                return options
            },

            activationFormLink () {
                if (!this.directory) return this.activationForm.link

                var domain = this.directory.brandActiveMainDomain
                return this.activationForm.link.replace('{domain}', domain.toLowerCase())
            }
        },
    }
</script>

<style scoped lang="scss">
    /deep/ .thumbnail-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .container {
            position: relative;
            display: flex;
            align-items: center;

            .thumbnail {
                width: 73 * $rem;
                height: 63 * $rem;
                cursor: pointer;
                border-radius: 2 * $rem;
            }

            .image-overlay {
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(102, 102, 102, 0.486);
                z-index: 5;
                cursor: pointer;
                transition: all 0.3s ease-out;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: white;
                    font-size: 28px;
                }
            }
            &:hover {
                .image-overlay {
                    opacity: 1;
                }
            }
        }

        .media-name {
            font-size: 14px;
            font-weight: 500;
            margin-left: 25 * $rem;
        }
    }

    /deep/ .media-actions {
        display: flex;
        align-items: center;

        .create.btn {
            width: 71 * $rem;
            height: 28 * $rem;
            background-color: #4800dd;
            font-weight: 500;
            font-size: 14 * $rem;
            border-radius: 3 * $rem;
        }

        .statistics {
            font-size: 36 * $rem;
            cursor: pointer;
        }
    }

    /deep/ .status-active {
        color: #3e7252;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 4 * $rem;
            left: -15 * $rem;
            background-color: #3e7252;
            width: 8 * $rem;
            height: 8 * $rem;
            border-radius: 50%;
        }
    }

    /deep/ .status-inactive {
        color: rgb(196, 0, 0);
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 4 * $rem;
            left: -15 * $rem;
            background-color: rgb(196, 0, 0);
            width: 8 * $rem;
            height: 8 * $rem;
            border-radius: 50%;
        }
    }

    /deep/ .full-grid {
        margin-top: 30 * $rem;
    }

    /deep/ .generate-code-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        span {
            font-size: 20*$rem;
            text-transform: capitalize;
        }

        .code-container {
            width: 500*$rem;
            margin-bottom: 30*$rem;

            p {
                padding: 15*$rem;
                border: 1px solid grey;
                background-color: rgb(245, 245, 245);
            }
        }
    }

    .activate-popup {
        background-color: white;
        width: 600 * $rem;
        height: 400 * $rem;
        position: relative;

        .header {
            height: 60 * $rem;
            display: flex;
            align-items: center;

            h4 {
                margin-left: 50 * $rem;
                font-size: 18px;
                font-weight: 400;
            }
        }

        .content {
            padding: 40 * $rem 50 * $rem;
            color: $grey-dark;

            .row {
                margin-bottom: 20 * $rem;

                span {
                    display: inline-block;
                    margin-bottom: 10 * $rem;
                }

                input {
                    display: block;
                    height: 35 * $rem;
                    padding: 15 * $rem;
                    width: 100%;
                    border-radius: 5 * $rem;
                    border: 1 * $rem solid #d2d2d2;

                    &:disabled {
                        background-color: #ececec;
                    }
                }

                /deep/ .dd-container {
                    display: block;
                    height: 35 * $rem;
                    border: 1 * $rem solid #d2d2d2;
                    border-radius: 5 * $rem;
                    width: 100%;
                }
            }

            .btn.create-media {
                position: absolute;
                right: 110 * $rem;
                bottom: 10 * $rem;
                width: 90 * $rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                border-radius: 3px;
                color: white;
                background-color: $green;

                &:hover {
                    cursor: pointer;
                    filter: brightness(1.1);
                }
            }

            .btn.cancel-creating-media {
                position: absolute;
                right: 10 * $rem;
                bottom: 10 * $rem;
                width: 90 * $rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                border-radius: 3px;
                color: white;
                background-color: #a5a5a5;

                &:hover {
                    cursor: pointer;
                    filter: brightness(1.1);
                }
            }
        }
    }

    .filters-wrapper {
        display: block;
        width: 100%;
        height: calc(2 * 44 *#{$rem} + 2 * 17 *#{$rem});

        & > input,
        & > .dd-container {
            display: block;
            float: left;
            width: calc(25% - 18 * #{$rem});
            height: 44 * $rem;
            margin-bottom: 17 * $rem;

            &:not(:nth-child(4n)) {
                margin-right: 24 * $rem;
            }
        }

        & > .filter-buttons {
            float: right;
            margin-right: 0;
            width: calc(25% - 18 * #{$rem});
            height: 44 * $rem;
            margin-bottom: 17 * $rem;
            display: flex;
            justify-content: space-between;

            button {
                color: white;
                width: calc(50% - 12 * #{$rem});
                height: inherit;
                border-radius: 3 * $rem;
                font-size: 14 * $rem;
                font-weight: 400;

                &.clear-btn {
                    background-color: $gray-btn;
                }
                &.search-btn {
                    background-color: $purple-btn;
                }
            }
        }

        & > input {
            border-radius: 3 * $rem;
            border: 1 * $rem solid rgba(154, 154, 154, 0.57);
            padding-left: 10 * $rem;
            box-sizing: border-box;
            font-size: 14 * $rem;

            &::placeholder {
                color: $placeholder;
            }
        }
    }

    .localizations .popup {
        width: 800 * $rem;
        height: 80vh;
        overflow-y: auto;
        background-color: #fff;
        padding: 5 * $rem 30 * $rem;

        /deep/ .thumbnail {
            width: 250 * $rem;
        }
    }

    .links {
        position: relative;

        .sections {
            position: absolute;
            top: 138 * $rem;
            display: flex;

            .all,
            .recommended {
                padding: 24 * $rem 34 * $rem;
                color: #636363;
                font-weight: 500;
                font-size: 14 * $rem;
                cursor: pointer;

                &.selected {
                    background-color: #f2f1f2;
                }
            }
        }
    }

    .popup.grid-wrapper {
        height: 50vh;
        width: 800*$rem;
        background-color: #fff;
        padding: 0 30*$rem;
        overflow-y: auto;

        /deep/ th {
            display: none;
        }
    }

@media only screen and (max-width: 600px) {
    .filters-wrapper {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
    .filters-wrapper > .dd-container {
        width: 100%;
    }
}
</style>
