<template>
    <sections :titles="titles" @changeSection="(index) => this.activeSection = index">
        <account-details :data="profileData" v-show="activeSection == 0"></account-details>
        <commissions v-show="activeSection == 1"></commissions>
        <payments
            v-if="paymentData != null"
            :data="paymentData"
            :userName="userName"
            :userID="userID"
            v-show="activeSection == 2"
        ></payments>
        <change-password v-show="activeSection == 3"></change-password>
    </sections>
</template>

<script>
import Sections from '@/components/ReusableComponents/Sections/Sections.vue'
import axios from '@/Helpers/AxiosInstance.js'
import AccountDetails from './AccountDetails/AccountDetails.vue'
import Payments from './Payments/Payments.vue'
import Commissions from './Commissions/Commissions.vue'
import ChangePassword from './ChangePassword/ChangePassword.vue'
export default {
    created() {
        axios
            .get('Profile/GetProfile')
            .then(resp => (this.profileData = resp.data))

        axios
            .get('Profile/GetPaymentInfo')
            .then(resp => (this.paymentData = resp.data))
    },
    components: {
        Sections,
        AccountDetails,
        Payments,
        Commissions,
        ChangePassword,
    },
    data() {
        return {
            titles: [
                this.$t('PROFILE__ACCOUNT_DETAILS'),
                this.$t('PROFILE__COMMISSIONS'),
                this.$t('PROFILE__PAYMENTS'),
                this.$t('PROFILE__CHANGE_PASSWORD'),
            ],
            activeSection: 0,
            profileData: null,
            paymentData: null,
        }
    },
    computed: {
        userName() {
            if (this.profileData) {
                return this.profileData.userProfile.userName
            }
            return 'username'
        },
        userID() {
            if (this.profileData) {
                return this.profileData.userProfile.userProfileID
            }
            return 0
        },
    },
}
</script>

<style>
</style>
