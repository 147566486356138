<template>
    <div class="block">
        <h4 class="title">{{ title }}</h4>
        <div class="fields">
            <div class="field" v-for="(field,index) in fields" :key="index">
                <span class="name">{{field.name}}:</span>
                <div v-html="getValue(field)" class="value-wrapper"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        fields: Array,
    },
    methods: {
        getValue(field) {
            if (field.customTemplate) return field.customTemplate(field)
            if (field.editable)
                return `<span class="value">${
                    field.value
                }</span><span class="material-icons-outlined edit">edit</span>`
            if (!field.value) return `<span class="empty">-</span>`
            return `<span class="value">${field.value}</span>`
        },
    },
}
</script>

<style scoped lang="scss">
.block {
    width: calc((100% - 15 *#{$rem}) / 2);
    padding: 35 * $rem 40 * $rem;

    .title {
        color: $blue-light;
        font-size: 17 * $rem;
        font-style: italic;
        margin-bottom: 50 * $rem;
        font-weight: 500;
    }

    .fields {
        .field {
            display: flex;
            height: 40 * $rem;
            align-items: center;
            justify-content: space-between;
            border-top: 1 * $rem solid #f4f4f4;

            &:last-child {
                border-bottom: 1 * $rem solid #f4f4f4;
            }

            .name {
                color: $grey-dark;
                font-size: 14 * $rem;
            }

            .value-wrapper {
                display: flex;
                align-items: center;

                /deep/ span.value {
                    color: #373737;
                    font-size: 15 * $rem;
                    font-weight: 600;
                }

                /deep/ .edit {
                    margin-left: 15 * $rem;
                    width: 25 * $rem;
                    text-align: center;
                    font-size: 18 * $rem;

                    &:hover {
                        cursor: pointer;
                    }
                }

                /deep/ .empty {
                    margin-left: 15 * $rem;
                    width: 25 * $rem;
                    text-align: center;
                }
            }
        }
    }
}
</style>
