<template>
    <div class="menu-item">
        <router-link v-if="routerLink" :to="routerLink" class="parent-link">
            <i @click="hideSideMenuIfMobile" :class="iconsClass">{{ this.icon }}</i>
            <span @click="hideSideMenuIfMobile">{{ this.text }}</span>
        </router-link>
        <a v-else class="parent-link" @click="toggleCollapse">
            <i :class="iconsClass">{{ this.icon }}</i>
            <span>{{ this.text }}</span>
            <i v-show="subItems.length > 0" class="material-icons-outlined arrow">{{ isCollapsed ? "keyboard_arrow_down" :
                "keyboard_arrow_right" }}</i>
        </a>
        <ul v-if="subItems.length > 0" :class="{ collapsed: isCollapsed }">
            <li v-for="(item, index) in subItems" :key="index">
                <i class="material-icons">fiber_manual_record</i>
                <router-link :to="item.routerLink">
                    <span @click="hideSideMenuIfMobile">{{ item.title }}</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
        icon: String,
        routerLink: String,
        outlined: {
            type: Boolean,
            default: true,
        },
        subItems: {
            type: Array,
            default: () => [],
        },
        hideSideMenuIfMobile: Function,
    },
    computed: {
        iconsClass() {
            return this.outlined ? 'material-icons-outlined' : 'material-icons'
        },
    },
    data: function () {
        return {
            isCollapsed: true,
        }
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed
        },
    },
}
</script>

<style scoped lang="scss">
.menu-item {
    font-size: 16 * $rem;
    color: $grey-blue-light;
    padding-left: 29 * $rem;
    padding-top: 24 * $rem;
    margin: 0;

    a.parent-link {
        display: flex;
        width: 221 * $rem;
        color: $grey-blue-light;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.2s ease-out;

        i {
            font-size: 20 * $rem;
        }

        span {
            margin-left: 26 * $rem;
        }

        .arrow {
            margin-left: auto;
        }
    }

    a.parent-link:hover {
        color: #edeffc;
    }

    a.router-link-active {
        color: #fff;
    }

    ul {
        margin: 0;
        height: 0;
        overflow: hidden;

        li {
            list-style: none;
            display: flex;
            margin-bottom: 15 * $rem;

            i {
                font-size: 14 * $rem;
                color: $grey-blue-dark;
                line-height: 18 * $rem;
                margin-left: 3 * $rem;
                transition: color 0.3s ease-out;
            }

            a {
                text-decoration: none;
                margin-left: 30 * $rem;
                color: $grey-blue-dark;
                font-size: 14 * $rem;
                transition: color 0.2s ease-out;
            }
        }

        li:hover {

            i,
            a {
                color: $grey-blue-light;
            }
        }

        li:last-of-type {
            margin-bottom: 0;
        }
    }

    .collapsed {
        height: auto;
        margin-top: 23 * $rem;
    }
}
</style>
