<template>
    <div class="login" :style="[ changeBackground ? {'background': `url(${require('@/assets/images/simdibahis.jpg')})`} : {'background': `url(${require('@/assets/images/login.jpg')})`}]">
        <form class="login-form" @submit.prevent="tryLogin">
            <div class="content">
                <label>
                    <span class="material-icons">account_circle</span>
                    <input type="text" placeholder="Username" v-model="username">
                </label>
                <label>
                    <span class="material-icons">lock</span>
                    <input type="password" placeholder="Password" v-model="password">
                </label>
                <button type="submit">Login</button>
                <p v-show="incorrectLogin">Username Or Password Is Incorrect</p>
            </div>
        </form>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
export default {
    props: {
        logIn: Function,
    },
    data: function() {
        return {
            changeBackground: location.href.includes('simdibhs'),
            incorrectLogin: false,
            username: '',
            password: '',
        }
    },
    methods: {
        tryLogin() {
            axios
                .post('Security/LogIn', {
                    userName: this.username,
                    password: this.password,
                    rememberMe: true,
                })
                .then(resp => {
                    if (resp.data.status == 1) this.logIn()
                    else this.incorrectLogin = true
                })
                .catch(() => (this.incorrectLogin = true))
        },
    },
}
</script>

<style scoped lang="scss">
.login {
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;

    .login-form {
        overflow: hidden;
        width: 400 * $rem;
        margin: auto;
        margin-top: 200 * $rem;

        h3 {
            font-size: 24 * $rem;
            color: #fff;
            text-align: center;
            font-weight: 200;
        }

        p {
            font-size: 12 * $rem;
            font-weight: 200;
            text-align: center;
            color: #fff;
            padding: 10 * $rem 0;
        }

        .content {
            background-color: #fff;
            border-radius: 10 * $rem;
            padding: 60 * $rem;
            margin-top: 5 * $rem;

            label {
                border: 1 * $rem solid #c7c7c7;
                background-color: #fff;
                border-radius: 2 * $rem;
                width: 100%;
                height: 40 * $rem;
                display: block;
                margin-bottom: 20 * $rem;
                cursor: pointer;

                span {
                    display: inline-block;
                    float: left;
                    width: 40 * $rem;
                    height: 100%;
                    line-height: 40 * $rem;
                    text-align: center;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    font-size: 20 * $rem;
                    color: #b1b1b8;
                }

                input {
                    float: left;
                    width: calc(100% - 40 *#{$rem});
                    border: 0;
                    outline: 0;
                    height: 100%;
                }
            }

            button {
                background-color: #1874ef;
                color: #fff;
                margin-bottom: 15 * $rem;
                border: none;
                border-radius: 2 * $rem;
                width: 100%;
                height: 40 * $rem;
                box-sizing: border-box;
                transition: opacity 0.3s ease-out;
                cursor: pointer;
            }

            p {
                text-align: center;
                margin-top: 10 * $rem;
                font-size: 13 * $rem;
                color: $red;
                font-weight: 500;
            }
        }
    }
}
</style>
