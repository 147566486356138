<template>
    <div class="popup">
        <div class="header">
            <div
                class="section"
                v-for="(lang, index) in data"
                :key="index"
                @click="selectedLang = lang.langCode"
                :class="{'active' : selectedLang == lang.langCode}"
            >{{lang.langCode}}</div>
        </div>
        <div class="content">
            <p>{{text}}</p>
        </div>
        <div class="btn copy" @click="copyClickHandler">{{copyBtnText}}</div>
    </div>
</template>

<script>
import { copyToClipboard } from '@/Helpers/HelperFunctions.js'
export default {
    props: {
        data: Array,
    },
    data() {
        return {
            selectedLang: '',
            copyBtnText: 'Copy',
        }
    },
    computed: {
        text() {
            var item = this.data.filter(
                el => el.langCode == this.selectedLang
            )[0]
            return item ? item.text : ''
        },
    },
    watch: {
        data(val) {
            this.selectedLang = val.length > 0 ? val[0].langCode : ''
        },
    },
    methods: {
        copyClickHandler() {
            copyToClipboard(this.text)
            this.copyBtnText = 'Copied!'
            setTimeout(() => {
                this.copyBtnText = 'Copy'
            }, 3000)
        },
    },
}
</script>

<style scoped lang="scss">
.popup {
    height: 90vh;
    padding: 30 * $rem 30 * $rem;
    background-color: white;
    min-width: 900*$rem;

    .header {
        display: flex;

        .section {
            padding: 10 * $rem 20 * $rem;
            background-color: rgba(226, 226, 226, 0.336);
            margin-right: 2px;
            text-transform: capitalize;
            cursor: pointer;

            &.active {
                background-color: #4800dd;
                color: white;
            }
        }
    }

    .content {
        margin-top: 60 * $rem;
        overflow-y: auto;
        height: calc(100% - 80 *#{$rem});
        padding: 10 * $rem 15 * $rem;
        border: 1px solid #d6d6d6;
        background-color: #fbfbfb;

        p {
            color: $grey-dark;
        }
    }

    .copy {
        position: absolute;
        top: 80 * $rem;
        right: 50%;
        transform: translateX(50%);
        width: 70 * $rem;
        background-color: #4800dd;
    }
}
</style>
