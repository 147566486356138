<template>
    <div class='commissions'>
        <div class='row' v-if='commissions != null'>
            <div class='commission-item block' v-for='commission in commissions' :key='commission.ID'>
                <span class='title'>{{ commission.name }}</span>
                <span class='percentage'>{{ commission.percent }}%</span>
                <span class='schedule' v-if='scheduleTypes'>{{ scheduleTypes[commission.scheduleType] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/Helpers/AxiosInstance.js'

    export default {
        created () {
            axios.get('profile/getaffiliatecommissionsetting').then(resp => {
                var commissions = resp.data.commissionSettingsByGameType
                var gameTypes = resp.data.gameTemplateGameTypes

                this.commissions = commissions.map(el => ({
                    ID: el.id,
                    name: gameTypes.filter(type => type.id == el.gameType)[0].name,
                    percent: el.commissionPercent,
                    scheduleType: el.scheduleType
                }))
            })
        },

        data () {
            return {
                commissions: null
            }
        },

        computed: {
            scheduleTypes () {
                var dir = this.$store.state.directory
                if (!dir || !dir.scheduleTypes) return null

                var ret = {}
                dir.scheduleTypes.forEach(el => {
                    ret[el.id] = el.name
                })

                return ret
            }
        }
    }
</script>

<style scoped lang='scss'>
    $colors: #5b9e81, #e6c26c, #c0516d, #436caf, #9c5aad, #a1895c, #3d3d3d;

    .row {
        display: flex;

        .commission-item {
            width: calc(100% / 7);
            margin-right: 10 * $rem;
            height: 120 * $rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }

            @for $i from 1 through length($colors) {
                &:nth-child(#{$i}) {
                    .percentage {
                        color: nth($colors, $i);
                    }
                }
            }

            .title {
                font-size: 16 * $rem;
                color: $grey-dark;
                font-family: 'Roboto Medium';
            }

            .percentage {
                font-size: 45 * $rem;
                margin: 3 * $rem 0;
                font-weight: 500;
            }

            .schedule {
                font-size: 14 * $rem;
                color: $grey-dark;
            }
        }
    }
</style>
