<template>
    <div class="general-report">
        <form class="filter" @submit.prevent="setData">
            <dropdown v-model="filter.grouping" :options="groupingOptions"></dropdown>
            <el-date-picker
                v-model="filter.fromDate"
                type="datetime"
                :placeholder="$t('GENERAL__FROM_DATE')"
                format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
            <el-date-picker
                v-model="filter.toDate"
                type="datetime"
                :placeholder="$t('GENERAL__TO_DATE')"
                format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
            <button class="btn">{{ $t('GENERAL__SEARCH') }}</button>
        </form>
        <grid
            fullGrid
            identificator="id"
            :passedData="gridData"
            :passedPageSize="pageSize"
            :columns="columnsToDisplay"
            :passedCount="count"
            @pageChanged="pageChangeHandler"
            @pageCountChanged="pageCountChangeHandler"
            @sort="sortHandler"
        ></grid>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import axios from '@/Helpers/AxiosInstance.js'

export default {
    components: {
        Grid,
        Dropdown,
    },

    mounted() {
        this.setData()
    },

    data() {
        return {
            filter: {
                grouping: 'day',
                fromDate: '',
                toDate: '',
            },
            groupingOptions: [
                { text: this.$t('GENERAL__DAY'), value: 'day' },
                { text: this.$t('GENERAL__MONTH'), value: 'month' },
                { text: this.$t('GENERAL__YEAR'), value: 'year' },
            ],

            currentPage: 1,
            pageSize: 15,
            orderColumn: 'date',
            orderDirection: 'desc',
            gridData: [],
            count: 0,
            columns: [
                {
                    title: 'GENERAL__DATE',
                    key: 'date',
                    customTemplate: (item, val) =>
                        val
                            .split(' ')[0]
                            .split('.')
                            .map(e => (e.length == 1 ? '0' + e : e))
                            .join('/'),
                    permissionName: 'date',
                },
                {
                    title: 'GENERAL__DEPOSIT',
                    key: 'amount',
                    permissionName: 'amount',
                },
                {
                    title: 'GENERAL__NET_AMOUNT',
                    key: 'netAmount',
                    permissionName: 'netAmount',
                },
                {
                    title: 'GENERAL__COMMISSION_AMOUNT',
                    key: 'commissionAmount',
                    permissionName: 'commissionAmount',
                },
                {
                    title: 'GENERAL__WITHDRAW_AMOUNT',
                    key: 'withdrawAmount',
                    permissionName: 'withdrawAmount',
                },
                {
                    title: 'GENERAL__COUNT',
                    key: 'count',
                    permissionName: 'count',
                },
                {
                    title: 'GENERAL__CLICK_COUNT',
                    key: 'clickCount',
                    permissionName: 'clickCount',
                },
                {
                    title: 'GENERAL__FTD_COUNT',
                    key: 'ftdCount',
                    permissionName: 'ftdCount',
                },
                {
                    title: 'GENERAL__SIGNUP_COUNT',
                    key: 'signupCount',
                    permissionName: 'signupCount',
                },
                {
                    title: 'GENERAL__UNIQUE_VIEW_COUNT',
                    key: 'uniqueViewCount',
                    permissionName: 'uniqueViewCount',
                },
                {
                    title: 'GENERAL__VIEW_COUNT',
                    key: 'viewCount',
                    permissionName: 'viewCount',
                },
            ],
        }
    },

    computed: {
        directory() {
            return this.$store.state.directory
        },
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Report_General_Report'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
        columnsToDisplay() {
            if(!this.namesOfColumnsToDisplay) return this.columns
            return this.columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
    },

    methods: {
        setData() {
            var data = {
                CurrentPage: this.currentPage,
                PageSize: this.pageSize,
                // OrderColumn: this.orderColumn,
                // OrderDirection: this.orderDirection,

                FromDate: this.filter.fromDate,
                ToDate: this.filter.toDate,
                Grouping: this.filter.grouping,
            }

            axios
                .get('Report/GetAffiliateGeneralReport', { params: data })
                .then(resp => {
                    this.gridData = resp.data
                    this.count = resp.data[0] ? resp.data[0].count : 0
                })
        },
        pageChangeHandler(page) {
            this.currentPage = page
            this.setData()
        },
        pageCountChangeHandler(size) {
            this.pageSize = size
            this.setData()
        },
        sortHandler(data) {
            this.orderColumn = data.columnName
            this.orderDirection = data.orderByDesc ? 'desc' : 'asc'
            this.setData()
        },
    },
}
</script>

<style lang="scss" scoped>
.filter {
    display: flex;
    align-items: center;
    margin-bottom: 30 * $rem;
    /deep/ input,
    .dd-container,
    .el-input {
        margin-right: 15 * $rem;
        width: 100%;
        height: 38 * $rem;
    }

    .btn {
        color: white;
        width: calc(50% - 12 * 0.0625rem);
        height: 38 * $rem;
        border-radius: 0.1875rem;
        font-size: 0.875rem;
        font-weight: 400;
        background-color: #4800dd;
    }
}

/deep/ .full-grid {
    margin-top: 30 * $rem;
}

@media only screen and (max-width: 600px) {
    .filter {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
}

</style>
