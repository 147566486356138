<template>
    <div class="salesReport">
        <form class="filter-form" @submit.prevent="setData">
            <div class="row">
                <input
                    type="text"
                    v-model="filter.username"
                    name="sername"
                    :placeholder="$t('SALES_REPORT__USERNAME')"
                    class="general-input"
                />
                <dropdown
                    name="country"
                    :options="countryOptions"
                    v-model="filter.countryId"
                    searchable
                ></dropdown>
                <input-date :placeholder="$t('SALES_REPORT__FROM_DATE')" v-model="postData.mandatoryParam.beginDate"></input-date>
                <input-date :placeholder="$t('SALES_REPORT__TO_DATE')" v-model="postData.mandatoryParam.endDate"></input-date>
                <button class="btn btn-search">{{ $t('SALES_REPORT__SEARCH') }}</button>
            </div>
            <label class="active-players">
                <span>{{ $t('SALES_REPORT__ACTIVE_PLAYERS') }}</span>
                <input type="checkbox" v-model="activePlayers" />
            </label>
        </form>
        <grid
            :passedData="gridData"
            :passedPageSize="postData.pageLength"
            identificator="userProfileID"
            :columns="columnsToDisplay"
            :passedCount="totalCount"
            :searchedColumns="this.postData.params"
            :lastRow="summaryRow"
            fullGrid
            @pageChanged="pageChangeHandler"
            @pageCountChanged="pageCountChangeHandler"
            @sort="sortHandler"
            @search="searchHandler"
        ></grid>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import Grid from '../../ReusableComponents/Grid/Grid.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import InputDate from '@/components/ReusableComponents/Input/InputDate.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'

export default {
    components: {
        Grid,
        Dropdown,
        InputDate,
    },
    created() {
        //current week
        this.postData.mandatoryParam.beginDate = getDateString(
            this.defaultFromDate(),
            'yyyy-MM-dd'
        )
        this.postData.mandatoryParam.endDate = getDateString(
            this.defaultToDate(),
            'yyyy-MM-dd'
        )
        this.setData()
    },
    data: function() {
        return {
            gridData: [],
            columns: [
                {
                    title: this.$t('SALES_REPORT__USERNAME'),
                    key: 'userName',
                    filterable: true,
                    permissionName: 'userName',
                },
                {
                    title: this.$t('SALES_REPORT__COUNTRY'),
                    key: 'countryName',
                    permissionName: 'countryName',
                },
                {
                    title: this.$t('SALES_REPORT__DEPOSIT_COUNT'),
                    key: 'depositCount',
                    permissionName: 'depositCount',
                },
                {
                    title: this.$t('SALES_REPORT__DEPOSIT_AMOUNT'),
                    key: 'deposit',
                    customTemplate: (item, val) => val + ' ' + item.curencyCode,
                    permissionName: 'deposit',
                },
                {
                    title: this.$t('SALES_REPORT__BET_COUNT'),
                    key: 'betCount',
                    permissionName: 'betCount',
                },
                {
                    title: this.$t('SALES_REPORT__BET_AMOUNT'),
                    key: 'betAmount',
                    customTemplate: (item, val) => val + ' ' + item.curencyCode,
                    permissionName: 'betAmount',
                },
                {
                    title: this.$t('SALES_REPORT__WIN_AMOUNT'),
                    key: 'winAmount',
                    customTemplate: (item, val) => val + ' ' + item.curencyCode,
                    permissionName: 'winAmount',
                },
                {
                    title: this.$t('SALES_REPORT__GROSS'),
                    key: 'gross',
                    permissionName: 'gross',
                },
                {
                    title: this.$t('SALES_REPORT__BONUS_AMOUNT'),
                    key: 'bonusAmount',
                    customTemplate: (item, val) => val + ' ' + item.curencyCode,
                    permissionName: 'bonusAmount',
                },
                {
                    title: this.$t('SALES_REPORT__OTHER_AMOUNT'),
                    key: 'otherAmount',
                    customTemplate: (item, val) => val + ' ' + item.curencyCode,
                    permissionName: 'otherAmount',
                },
                {
                    title: this.$t('SALES_REPORT__NET_REVENUE'),
                    key: 'netRevenue',
                    permissionName: 'netRevenue',
                },
                {
                    title: this.$t('SALES_REPORT__COMMISSION'),
                    key: 'commission',
                    permissionName: 'commission',
                },
            ],
            totalCount: 0,
            postData: {
                mandatoryParam: {
                    beginDate: '',
                    endDate: '',
                    onlyActivePlayers: false,
                },
                orderByParam: 'userProfileID',
                orderByIsDesc: true,
                pageCount: 1,
                pageLength: 10,
                params: [],
            },
            filter: {
                username: '',
                countryId: '',
            },
            activePlayers: false,
            summaryData: null,
        }
    },
    methods: {
        setData() {
            this.postData.mandatoryParam.onlyActivePlayers = this.activePlayers
            var newPostData = []
            Object.keys(this.filter).forEach(key => {
                if (this.filter[key]) {
                    newPostData.push({
                        operator: 0,
                        paramName: key,
                        value: this.filter[key],
                    })
                }
            })
            this.postData.params = newPostData
            axios
                .post('Report/GetPlayersActivity', this.postData)
                .then(resp => {
                    this.gridData = resp.data.data
                    this.totalCount = resp.data.count
                    this.summaryData = resp.data.total
                })
        },
        pageChangeHandler(page) {
            this.postData.pageCount = page
            this.setData()
        },
        pageCountChangeHandler(count) {
            this.postData.pageLength = parseInt(count)
            this.postData.pageCount = 1
            this.setData()
        },
        sortHandler(data) {
            this.postData.orderByParam = data.columnName
            this.postData.orderByIsDesc = data.orderByDesc
            this.setData()
        },
        searchHandler(data) {
            var index = this.postData.params.findIndex(
                el => el.paramName == data.paramName
            )
            if (index < 0) {
                if (data.value == '') return
                this.postData.params.push(data)
            } else {
                if (data.value == '') this.postData.params.splice(index, 1)
                else this.postData.params[index].value = data.value
            }
            this.setData()
        },
        defaultFromDate() {
            var date = new Date()
            date.setDate(date.getDate() - date.getDay() + 1)
            return new Date(date.setHours(0, 0, 0, 0))
        },
        defaultToDate() {
            return new Date()
        },
    },
    computed: {
        directory() {
            return this.$store.state.directory
        },
        countryOptions() {
            var options = [{ text: 'Any Country', value: '' }]
            if (!this.directory) return options
            this.directory.countries.forEach(c =>
                options.push({ text: c.countryName, value: c.countryId })
            )
            return options
        },
        summaryRow() {
            if (!this.summaryData) return
            return [
                'Total',
                '',
                this.summaryData.depositCount,
                this.summaryData.deposit,
                this.summaryData.betCount,
                this.summaryData.betAmount,
                this.summaryData.winAmount,
                this.summaryData.gross,
                this.summaryData.bonusAmount,
                this.summaryData.otherAmount,
                this.summaryData.netRevenue,
                this.summaryData.commission,
            ]
        },
        namesOfColumnsToDisplay() {
            var permissionKey = this.$store.state.permissionsEnum[
                'Report_Sales_Report'
            ]
            var permissions = this.$store.state.permissions[permissionKey]
            if (permissions) return permissions.map(perm => perm.toLowerCase())
            return permissions
        },
        columnsToDisplay() {
            if(!this.namesOfColumnsToDisplay) return this.columns
            return this.columns.filter(
                col =>
                    !col.permissionName ||
                    this.namesOfColumnsToDisplay.includes(
                        col.permissionName.toLowerCase()
                    )
            )
        },
    },
    watch: {
        activePlayers() {
            this.setData()
        },
    },
}
</script>

<style scoped lang="scss">

.filter-form {
    position: relative;
    .row {
        .dd-container,
        .general-input,
        .btn,
        .input-wrapper {
            max-width: 300 * $rem;
        }
    }

}

.active-players {
    position: absolute;
    bottom: -60 * $rem;
    right: 230 * $rem;
    cursor: pointer;

    span {
        font-size: 145$rem;
    }

    input {
        margin-left: 15 * $rem;
        transform: scale(1.3);
    }
}


@media only screen and (max-width: 600px) {
    .filter-form {
        div,
        input {
            width: 100% !important;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
            max-width: 100% !important;
        }
    }

    form.filter-form .row .input-wrapper input {
        max-width: 100% !important;
        width: 100% !important;
    }

    form.filter-form .row > .btn {
        max-width: 100% !important;
    }

    form.filter-form .row .input-wrapper input {
        max-width: 100% !important;
    }
    .active-players {
        position: unset !important;
    }
    .active-players input[type="checkbox"]{
        width: auto !important;
        margin-bottom: 0 !important;
    }
}

</style>
