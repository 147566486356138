<template>
    <div>
        <grid
            :columns="columns"
            requestURL="Transfer/GetPartnerTransaction"
            identificator="id"
            initialOrderingColumn="id"
            fullGrid
        >
        </grid>
        <overlay v-show="commentPopupVisible" @close="commentPopupVisible = false">
            <div class="popup-comment">
                <h4>Comment</h4>
                <p>{{ commentText }}</p>
            </div>
        </overlay>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    mounted() {
        this.$el.addEventListener('click', (e) => {
            if(e.target.classList.contains('view-comment')){
                var ID = e.target.parentNode.parentNode.querySelector('td').getAttribute('title')
                axios
                    .get('Transfer/GetP2PTransactionComment', {params: {transactionId: ID}})
                    .then(resp => {
                        let comment = resp.data
                        if(comment) this.commentText = comment
                        else this.commentText = 'No Comment'
                    })
                this.commentPopupVisible = true
            }
        })
    },
    components: {
        Grid, Overlay
    },
    data() {
        return {
            columns: [
                {
                    title: this.$t('TRANSACTIONS__ID'),
                    key: 'id',
                },
                {
                    title: this.$t('TRANSACTIONS__AMOUNT'),
                    key: 'amount',
                    customTemplate: (item, val) => {
                        var x = Math.pow(10, item.realDecimalPlaces)
                        return Math.floor(val * x) / x + ' ' + item.currencyCode
                    }
                },
                {
                    title: this.$t('TRANSACTIONS__TYPE'),
                    key: 'typeName',
                },
                {
                    title: this.$t('TRANSACTIONS__RECIEVER_USERNAME'),
                    key: 'userName',
                    customTemplate: (item, val) => `<a href="/#/Users/${item.userProfileIdTo}" target="_blank">${val}</a>`
                },
                {
                    title: this.$t('TRANSACTIONS__RECIEVER_ID'),
                    key: 'userProfileIdTo',
                },
                {
                    title: this.$t('TRANSACTIONS__DATE'),
                    key: 'date',
                    customTemplate: (item, val) => getDateString(val),
                },
                {
                    title: this.$t('TRANSACTIONS__STATUS'),
                    key: 'statusName',
                },
                {
                    title: this.$t('TRANSACTIONS__FROM_TRANSACTION_ID'),
                    key: 'fromTransactionId',
                },
                {
                    title: this.$t('TRANSACTIONS__TO_TRANSACTION_ID'),
                    key: 'toTransactionId',
                },
                {
                    key: '',
                    plainColumn: true,
                    customTemplate: () => '<div class="btn view-comment">Comment</div>'
                },
            ],
            commentPopupVisible: false,
            commentText: '',
        }
    },
}
</script>

<style scoped lang="scss">
    /deep/ .full-grid {
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15*$rem;
            margin-right: 15*$rem;
            cursor: pointer;
        }
        
        tr td:last-of-type {
            padding-left: 0;
        }
    }

    .popup-comment {
        width: 400px;
        height: 200px;
        background-color: #fff;
        color: $grey-dark;
        padding: 30px 35px;
        display: flex;
        align-items: center;
        justify-content: center;    

        h4 {
            font-weight: 500;
            text-align: center;
            margin-bottom: 40px;
            position: absolute;
            top: 10px;
            left: 10px;
            color: black;
        }

        p {
            text-align: center;
        }
    }

    .overlay .popup {
        border-radius: 10px !important;
        header {
            background-color: #282c37 !important;
            color: #d8dbeb !important;
            i {
                color: #d8dbeb !important;
            }
        }
    }
    .overlay .popup header {
        background-color: #282c37 !important;
    }


    @media only screen and (max-width: 565px) {
    
        .popup-comment {
            width: 19.375rem;
        }

    }
</style>
