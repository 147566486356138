<template>
    <div class="block">
        <h3>{{title}}</h3>
        <div class="wrapper">
            <div class="texts">
                <p>{{description}}</p>
            </div>
            <div class="amount">
                <span>{{amount}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        amount: Number,
        title: String,
        description: String,
    },
}
</script>

<style scoped lang="scss">
.block {
    margin-right: 20 * $rem;
}

.wrapper {
    display: flex;
    width: 100%;
    padding: 15 * $rem 30 * $rem 26 * $rem 30 * $rem;
    justify-content: space-between;

    &:last-child {
        margin-right: 0;
    }

    .texts {
        width: 70%;

        p {
            font-size: 14 * $rem;
            font-weight: 300;
            color: $grey-dark;
            font-style: italic;
        }
    }

    .amount {
        width: 20%;
        display: flex;
        align-items: center;
        position: relative;
        top: -9px;

        span {
            margin: auto;
            font-size: 40 * $rem;
            color: #6aac52;
        }
    }
}

h3 {
    font-weight: 500;
    color: $grey-dark;
    font-size: 17 * $rem;
    margin-bottom: 27 * $rem;
    margin: 25 * $rem 0 0 30 * $rem;
}
</style>
