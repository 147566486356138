<template>
    <div class="header" v-if="headerData" :class="{ 'expand': noLeftMarginOnHeader }">
        <div class="title">
            <h2>{{ $t(headerTitle) }}</h2>
            <i class="material-icons menu" @click="toggleSideMenuHandler">menu</i>
        </div>
        <div class="info">
            <div class="wallet" title="Balance">
                <i class="wallet material-icons-outlined">account_balance_wallet</i>
                <span class="amount">{{ balance }}</span>
                <span class="currency">{{ headerData.currencyCode }}</span>
            </div>

            <div class="user-info" title="Account" @click.stop="() => dropDownVisibility = !dropDownVisibility">
                <i class="material-icons-outlined account">account_circle</i>
                <span>{{ headerData.userName }}</span>
                <i class="material-icons arrow">keyboard_arrow_down</i>
                <ul class="dropdown" v-show="dropDownVisibility">
                    <router-link to="/Profile" @click="dropDownVisibility = false">
                        <li>{{ $t('HEADER__MY_ACCOUNT') }}</li>
                    </router-link>
                    <li @click="logoutHandler">{{ $t('HEADER__LOG_OUT') }}</li>
                </ul>
            </div>

            <div class="languages" @click.stop="languageDropdownIsVisible = !languageDropdownIsVisible">
                <div class="current-language">
                    <div class="flag" :country="languages.find(e => e.langCode === $i18n.locale).flag"></div>
                </div>
                <div class="language-list-wrapper" v-show="languageDropdownIsVisible">
                    <div class="language-list">
                        <div class="language-item" v-for="language in languages.filter(e => e.langCode !== $i18n.locale)"
                            :key="language.langId" @click.stop="onChangeLanguage(language)">
                            <div class="flag" :country="language.flag"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import NotificationIcon from './NotificationIcon.vue'
import axios from '@/Helpers/AxiosInstance.js'
import EventBus from '@/Helpers/EventBus.js'
export default {
    name: 'Header',
    components: {
        // NotificationIcon,
    },
    created() {
        //required because when page is refreshed,
        //we need to get the path name

        //close profile dropdown on document click
        document.addEventListener('click', () => {
            this.languageDropdownIsVisible = false
            this.dropDownVisibility = false
        })

        //header data from service
        axios
            .get('Profile/GetHeaderInfo')
            .then(resp => {
                this.headerData = resp.data
                this.$store.state.userID = resp.data.userProfileID
            })

        EventBus.$on('toggleSidebar', () => this.noLeftMarginOnHeader = !this.noLeftMarginOnHeader)
    },
    data: function () {
        return {
            dropDownVisibility: false,
            headerData: null,
            noLeftMarginOnHeader: false,
            languageDropdownIsVisible: false,
            languages: [
                { langId: 1, langCode: 'en', flag: 'england' },
                { langId: 34, langCode: 'br', flag: 'brazil' },
            ]
        }
    },
    methods: {
        logoutHandler() {
            axios.post('Security/LogOut').then(() => EventBus.$emit('logOut')) //App.vue listening
        },
        toggleSideMenuHandler() {
            EventBus.$emit('toggleSideMenuForMobile')
        },
        onChangeLanguage(language) {
            this.languageDropdownIsVisible = false
            this.selectedLanguage = language.langCode;
            this.$i18n.locale = language.langCode;
            this.$emit('changeLanguage', language)
            localStorage.setItem('lang', language.langCode)
        }
    },
    computed: {
        balance() {
            var x = Math.pow(10, this.headerData.realDecimalPlaces)
            return Math.floor(this.headerData.balance * x) / x
        },
        headerTitle() {
            const title = this.$route.meta.translationKey
            return title
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    width: calc(100% - 293 *#{$rem});
    margin-left: 293 * $rem;
    height: 74 * $rem;
    box-shadow: 0 2 * $rem 7 * $rem -4 * $rem grey;
    display: flex;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-out;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: white;

    &.expand {
        width: 100%;
        margin-left: 0;
    }

    .title {
        display: flex;
        align-items: center;
        width: 20%;

        h2 {
            font-size: 18 * $rem;
            padding-left: 32 * $rem;
            font-weight: 500;
        }

        .menu {
            display: none;
        }
    }

    .info {
        width: 80%;
        padding: 28 * $rem 0 24 * $rem 0 * $rem;
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 2.0625rem;

        .user-info {
            position: relative;
            margin-right: 30 * $rem;
            display: flex;
            margin-left: 53 * $rem;
            cursor: pointer;

            i.account {
                font-size: 28 * $rem;
                line-height: 16 * $rem;
            }

            span {
                font-size: 14 * $rem;
                margin-left: 10 * $rem;
            }

            i.arrow {
                font-size: 24 * $rem;
                margin-left: 30 * $rem;
                line-height: 16 * $rem;
            }

            .dropdown {
                position: absolute;
                list-style: none;
                background-color: white;
                top: 26 * $rem;
                left: -25 * $rem;
                width: 211 * $rem;
                border: 1 * $rem solid $grey-light;
                border-top: none;
                border-right: none;

                li {
                    height: 30 * $rem;
                    padding-left: 30 * $rem;
                    line-height: 30 * $rem;
                    border-bottom: 1 * $rem solid rgb(241, 241, 241);
                    color: $grey-dark;
                    font-weight: 400;
                    font-size: 14 * $rem;

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(243, 243, 243);
                    }
                }

                a {
                    text-decoration: none;
                }
            }
        }

        .user-info:before {
            position: absolute;
            content: '';
            height: 35 * $rem;
            width: 1 * $rem;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -25 * $rem;
            background-color: $grey-light;
        }

        .wallet {
            position: relative;
            width: 145 * $rem;
            display: flex;
            font-size: 28 * $rem;
            line-height: 19 * $rem;

            span.amount {
                font-size: 15 * $rem;
                margin-left: 38 * $rem;
                color: #6a7fe9;
            }

            span.currency {
                font-size: 14 * $rem;
                margin-left: 8 * $rem;
            }
        }

        .wallet:before {
            position: absolute;
            content: '';
            height: 35 * $rem;
            width: 1 * $rem;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -25 * $rem;
            background-color: $grey-light;
        }

        .languages {
            position: relative;

            .flag {
                transform: scale(1) !important;
            }

            &:before {
                position: absolute;
                content: '';
                height: 35 * $rem;
                width: 1 * $rem;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -25 * $rem;
                background-color: $grey-light;
            }

            .current-language {
                height: 2.1875rem;
                display: flex;
                align-items: center;
                padding: 0.525rem;
                cursor: pointer;
            }


            .language-list-wrapper {
                position: absolute;
                bottom: -1.0625rem;
                overflow: hidden;
                right: 0;
                transform: translateY(100%);
                background: #fff;
                border-radius: 0 0 0.3125rem 0.3125rem;
                border: 0.0625rem solid rgba(0, 0, 0, 0.2);
                border-top: none;

                .language-list {
                    .language-item {
                        overflow: hidden;
                        cursor: pointer;
                        padding: 0.525rem;
                        transition: 0.2s ease;

                        & + .language-item {
                            border-top: 1px solid #e0e0e0;
                        }

                        &:hover {
                            background: #e0e0e0;
                        }
                    }
                }
            }
        }

        .notifications {
            float: right;
            margin-right: 80 * $rem;

            i {
                font-size: 26 * $rem;
                line-height: 19 * $rem;
            }

            i+i {
                margin-left: 40 * $rem;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .header {
        width: 100%;
        margin-left: 0;

        .title {
            width: 15%;

            h2 {
                display: none;
            }

            .menu {
                display: block;
                padding: 0;
                font-size: 35 * $rem;
                margin-left: 35 * $rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .info {
            width: 85%;
        }
    }
}

@media only screen and (max-width: 720px) {
    .header {
        .info {
            .wallet {
                display: none;
            }

            .notifications {
                margin-right: 16 * $rem;
            }
        }
    }
}

@media only screen and (max-width: 465px) {
    .user-info {
        margin-left: 0 !important;

        i.account {
            display: none;
        }

        i.arrow {
            margin-left: 10 * $rem !important;
        }

        &:before {
            display: none;
        }
    }

    .notifications {
        .notification {
            margin-left: 20 * $rem;
        }
    }

    .header {
        .title {
            .menu {
                margin-left: 10 * $rem;
            }
        }
    }
}
</style>
