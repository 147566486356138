<template>
    <div>
        <div class="texts">
            <form class="filters-wrapper" @submit.prevent="searchHandler">
                <input type="text" :placeholder="$t('TEXTS__NAME')" v-model="form.name">
                <dropdown name="products" :options="productOptions" v-model="form.productId"></dropdown>
                <dropdown
                    name="languages"
                    :options="languageOptions"
                    v-model="form.languageId"
                    searchable
                ></dropdown>
                <dropdown name="statuses" :options="statusOptions" v-model="form.status"></dropdown>
                <div class="filter-buttons">
                    <button class="btn clear-btn" type="button">{{ $t('TEXTS__CLEAR_ALL') }}</button>
                    <button class="btn search-btn" type="submit">{{ $t('TEXTS__SEARCH') }}</button>
                </div>
            </form>
            <grid
                :columns="textsGridColumns"
                identificator="id"
                fullGrid
                :searchable="false"
                :passedData="textsGridData"
                :passedPageSize="postData.pageLength"
                :passedCount="totalCount"
                :searchedColumns="this.postData.params"
                :exportable="false"
                @pageChanged="pageChangeHandler"
                @pageCountChanged="pageCountChangeHandler"
                @sort="sortHandler"
            ></grid>
            <overlay :visible="textPopupVisible" @close="textPopupVisible = false">
                <texts-popup
                    :data="popupData"
                ></texts-popup>
            </overlay>
            <div class="sections">
                <div
                    class="all"
                    :class="{'selected': !recommendedSelected}"
                    @click="allClickHandler"
                >{{ $t('TEXTS__ALL') }}</div>
                <div
                    class="recommended"
                    :class="{'selected': recommendedSelected}"
                    @click="recommendedClickHandler"
                >{{ $t('TEXTS__RECOMMENDED') }}</div>
            </div>
        </div>
        <message ref="message" :message="createMessage.text" :success="createMessage.success"></message>
    </div>
</template>

<script>
import Grid from '@/components/ReusableComponents/Grid/Grid.vue'
import axios from '@/Helpers/AxiosInstance.js'
import Message from '@/components/ReusableComponents/Message/Message.vue'
import Overlay from '@/components/ReusableComponents/Overlay/Overlay.vue'
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import TextsPopup from './TextsPopup.vue'
import { getDateString } from '@/Helpers/HelperFunctions.js'

export default {
    components: {
        Grid,
        Overlay,
        Message,
        Dropdown,
        TextsPopup
    },
    created() {
        this.setData()
    },
    mounted() {
        this.$el.addEventListener('click', event => {
            let t = event.target
            while (t && t !== this) {
                if (t.matches('.view-text-icon')) {
                    //TODO
                    this.textClickHandler(t)
                }
                t = t.parentNode
                if (t == this.$el) break
            }
        })
    },
    data() {
        return {
            textsGridColumns: [
                {
                    title: this.$t('TEXTS__ID'),
                    key: 'id',
                },
                {
                    title: this.$t('TEXTS__TEXT_TITLE'),
                    key: 'name',
                },
                {
                    title: this.$t('TEXTS__TEXT'),
                    key: 'textValue',
                    customTemplate: (item, val) => `<div class="text-wrapper">${val}</div>`
                },
                {
                    title: this.$t('TEXTS__PRODUCT'),
                    key: 'mediaProduct',
                },
                {
                    title: this.$t('TEXTS__LANGUAGE'),
                    key: 'langCode',
                    customTemplate: (item, val) => {
                        if(item.hasManyLanguage) return 'Many'
                        return val
                    }
                },
                {
                    title: this.$t('TEXTS__CREATE_DATE'),
                    key: 'createDate',
                    customTemplate: (item, val) => val ? getDateString(val) : '-',
                },
                {
                    title: this.$t('TEXTS__VIEW'),
                    key: 'status',
                    customTemplate: () => {
                        return `<i class="material-icons view-text-icon">remove_red_eye</i>`
                    }
                }
            ],
            textsGridData: [],
            totalCount: 0,
            postData: {
                orderByParam: 'id',
                orderByIsDesc: true,
                pageCount: 1,
                pageLength: 15,
                params: [
                    {
                        operator: 0,
                        paramName: 'mediaTypeId',
                        value: 3,
                    },
                ],
            },
            linkOptions: [],
            form: {
                productId: '',
                languageId: '',
                name: '',
                status: '',
            },
            statusOptions: [
                {
                    text: this.$t('TEXTS__ANY_STATUS'),
                    value: '',
                },
                {
                    text: this.$t('TEXTS__ACTIVE'),
                    value: '1',
                },
                {
                    text: this.$t('TEXTS__INACTIVE'),
                    value: '2',
                },
            ],
            activationForm: {
                name: '',
                link: '',
            },
            createMessage: {
                text: '',
                success: false,
            },
            recommendedSelected: false,
            textPopupVisible: false,
            popupData: [],
        }
    },
    methods: {
        setData() {
            axios.post('Media/GetMediaTemplates', this.postData).then(resp => {
                this.textsGridData = resp.data.data
                this.totalCount = resp.data.count
            })
        },
        pageChangeHandler(page) {
            this.postData.pageCount = page
            this.setData()
        },
        pageCountChangeHandler(count) {
            this.postData.pageLength = parseInt(count)
            this.postData.pageCount = 1
            this.setData()
        },
        sortHandler(data) {
            this.postData.orderByParam = data.columnName
            this.postData.orderByIsDesc = data.orderByDesc
            this.setData()
        },
        searchHandler() {
            this.postData.params = [
                {
                    operator: 0,
                    paramName: 'mediaTypeId',
                    value: 3,
                },
            ]
            Object.keys(this.form).forEach(key => {
                if (this.form[key]) {
                    let param = {
                        operator: 0,
                        paramName: key,
                    }
                    if(key == 'status') param.value = this.form[key] == 1 ? true : false
                    else param.value = this.form[key]
                    //add param to postdata
                    this.postData.params.push(param)
                }
            })
            this.setData()
        },
        //get the item ID that was clicked in row
        getRowID(target) {
            let parent = target.parentNode
            while (!parent.matches('tr')) {
                parent = parent.parentNode
            }
            return parent.getAttribute('rowid')
        },
        clearFilters() {},
        allClickHandler() {
            this.recommendedSelected = false
            this.clearFilters()
            this.postData.params = [
                {
                    operator: 0,
                    paramName: 'mediaTypeId',
                    value: 3,
                },
            ]
            this.setData()
        },
        recommendedClickHandler() {
            this.recommendedSelected = true
            this.clearFilters()
            this.postData.params = [
                {
                    operator: 0,
                    paramName: 'mediaTypeId',
                    value: 3,
                },
                {
                    operator: 0,
                    paramName: 'recommended',
                    value: true,
                },
            ]
            this.setData()
        },
        textClickHandler(t) {
            var ID = this.getRowID(t)
            axios
                .get('Media/GetMediaTemplateLocalizations', {
                    params: {
                        mediaTemplateId: ID
                    }
                })
                .then(resp => {
                    this.popupData = resp.data.map(el => ({
                        "langCode": el.langCode, 
                        "text": el.textValue
                    })) 
                    this.textPopupVisible = true
                })
        }
    },
    computed: {
        directory() {
            return this.$store.state.directory
        },
        productOptions() {
            var options = [{ text: this.$t('TEXTS__ANY_PRODUCT'), value: '' }]
            if (this.directory) {
                this.directory.afiliateMediaProducts.forEach(el =>
                    options.push({ text: el.name, value: el.id })
                )
            }
            return options
        },
        languageOptions() {
            var options = [{ text: this.$t('TEXTS__ANY_LANGUAGE'), value: '' }]
            if (this.directory) {
                this.directory.languages.forEach(el =>
                    options.push({ text: el.langCodeShort, value: el.id })
                )
            }
            return options
        },
    },
}
</script>

<style scoped lang="scss">
/deep/ .text-wrapper {
    width: 150*$rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
    cursor: pointer;
}

/deep/ .full-grid {
    margin-top: 30 * $rem;
}

.popup {
    height: 60vh !important;
    overflow: auto !important;
    .content {
        height: 35vh !important;
    }
}

.activate-popup {
    background-color: white;
    width: 600 * $rem;
    height: 400 * $rem;
    box-shadow: 0 0 13 * $rem 3 * $rem #5d5d5d;
    position: relative;

    .header {
        height: 60 * $rem;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;

        h4 {
            margin-left: 30 * $rem;
            font-size: 18px;
            font-weight: 400;
        }
    }

    .content {
        padding: 40 * $rem 50 * $rem;
        color: $grey-dark;

        .row {
            margin-bottom: 20 * $rem;

            span {
                display: inline-block;
                margin-bottom: 10 * $rem;
            }

            input {
                display: block;
                height: 35 * $rem;
                padding: 15 * $rem;
                width: 100%;
                border-radius: 5 * $rem;
                border: 1 * $rem solid #d2d2d2;

                &:disabled {
                    background-color: #ececec;
                }
            }

            /deep/ .dd-container {
                display: block;
                height: 35 * $rem;
                border: 1 * $rem solid #d2d2d2;
                border-radius: 5 * $rem;
                width: 100%;
            }
        }

        .btn.create-media {
            position: absolute;
            right: 110 * $rem;
            bottom: 10 * $rem;
            width: 90 * $rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            border-radius: 3px;
            color: white;
            background-color: $green;

            &:hover {
                cursor: pointer;
                filter: brightness(1.1);
            }
        }

        .btn.cancel-creating-media {
            position: absolute;
            right: 10 * $rem;
            bottom: 10 * $rem;
            width: 90 * $rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            border-radius: 3px;
            color: white;
            background-color: #a5a5a5;

            &:hover {
                cursor: pointer;
                filter: brightness(1.1);
            }
        }
    }
}

.filters-wrapper {
    display: block;
    width: 100%;
    height: calc(2 * 44 *#{$rem} + 2 * 17 *#{$rem});

    & > input,
    & > .dd-container {
        display: block;
        float: left;
        width: calc(25% - 18 * #{$rem});
        height: 44 * $rem;
        margin-bottom: 17 * $rem;

        &:not(:nth-child(4n)) {
            margin-right: 24 * $rem;
        }
    }

    & > .filter-buttons {
        float: right;
        margin-right: 0;
        width: calc(25% - 18 * #{$rem});
        height: 44 * $rem;
        margin-bottom: 17 * $rem;
        display: flex;
        justify-content: space-between;

        button {
            color: white;
            width: calc(50% - 12 * #{$rem});
            height: inherit;
            border-radius: 3 * $rem;
            font-size: 14 * $rem;
            font-weight: 400;

            &.clear-btn {
                background-color: $gray-btn;
            }
            &.search-btn {
                background-color: $purple-btn;
            }
        }
    }

    & > input {
        border-radius: 3 * $rem;
        border: 1 * $rem solid rgba(154, 154, 154, 0.57);
        padding-left: 10 * $rem;
        box-sizing: border-box;
        font-size: 14 * $rem;

        &::placeholder {
            color: $placeholder;
        }
    }
}

.localizations .popup {
    width: 800 * $rem;
    height: 80vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 5 * $rem 30 * $rem;

    /deep/ .thumbnail {
        width: 250 * $rem;
    }
}

.texts {
    position: relative;

    .sections {
        position: absolute;
        top: 138 * $rem;
        display: flex;

        .all,
        .recommended {
            padding: 24 * $rem 34 * $rem;
            color: #636363;
            font-weight: 500;
            font-size: 14 * $rem;
            cursor: pointer;

            &.selected {
                background-color: #f2f1f2;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .filters-wrapper {
        flex-direction: column;
        div,
        input {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.9375rem;
        }
    }
    .filters-wrapper > .dd-container {
        width: 100%;
    }
}

/deep/ .view-text-icon {
    cursor: pointer;

}
</style>
