<template>
    <div>
        <div class="block register-users">
            <div class="header">
                <h3>Register Users</h3>
                <div class="generate-toggle" @click="showGenerateForm = !showGenerateForm">
                    <i class="material-icons">autorenew</i>
                    <span>Generate</span>
                </div>
                <form class="generate-form" v-show="showGenerateForm" @submit.prevent="generateUsers">
                    <input type="text" name="prefix" placeholder="Prefix">
                    <input type="number" name="amount" placeholder="Amount">
                    <button class="generate">GO</button>
                </form>
            </div>
            <ul class="forms">
                <li class="form" v-for="(user, index) in users" :key="index">
                    <span class="number">{{index + 1}}</span>
                    <div class="helper">
                        <input
                            type="text"
                            name="userName"
                            placeholder="Username"
                            v-model="user.username"
                        >
                        <input
                            :type="user.showPassword ? 'text' : 'password'"
                            name="password"
                            placeholder="Password"
                            v-model="user.password"
                            @blur="testPasswordStrength($event, index)"
                        >
                        <input
                            :type="user.showPassword ? 'text' : 'password'"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            v-model="user.confirmPassword"
                            @blur="testPasswordStrengthAndMatch($event, index)"
                        >
                        <dropdown
                            name="currencyId"
                            :options="currencyOptions"
                            :searchable='true'
                            v-model="user.currencyID"
                        ></dropdown>
                    </div>
                    <i class="material-icons" @click="() => users.splice(index, 1)">clear</i>
                    <p class="error-message">{{user.errorMessage}}</p>
                </li>
            </ul>
            <div class="plus-button" @click="() => this.users.push({})">
                <i class="material-icons">add_circle</i>
            </div>
            <div class="save" @click="saveUsers">Register All</div>
        </div>
        <message ref="message" :message="registerMessage" :success="registerSuccess"></message>
    </div>
</template>

<script>
import Dropdown from '@/components/ReusableComponents/Dropdown/Dropdown.vue'
import Message from '@/components/ReusableComponents/Message/Message.vue'
import axios from '@/Helpers/AxiosInstance.js'
export default {
    created() {
        var dir = this.$store.state.directory
        if (dir) this.appendBrands(dir)

        // EventBus.$on('setDirectory', (data) => {
        //     if (data) this.appendBrands(data)
        // })

    },
    components: {
        Dropdown, Message,
    },
    data() {
        return {
            numberOfUsers: 1,
            currencyOptions: [{ text: 'Currency', value: '' }],
            users: [
                {
                    username: '',
                    password: '',
                    confirmPassword: '',
                    currencyID: '',
                    errorMessage: '',
                },
            ],
            showGenerateForm: false,
            registerMessage: '',
            registerSuccess: false,
        }
    },
    methods: {
        saveUsers() {
            if(!this.checkFormsBeforeSending())return
            var data = []
            this.users.forEach(user => {
                data.push({
                    userName: user.username,
                    password: user.password,
                    currencyId: user.currencyID,
                })
            })

            axios
                .post('Customer/RegistrationPartnerUsers', [...data])
                .then(resp => {
                    if(resp.data.code == 1){
                        //register success
                        this.registerMessage = 'Success'
                        this.registerSuccess = true
                        this.registerSuccess = true
                        this.$refs.message.showMessage()
                        this.users.map(user => user.errorMessage = '')
                    }
                    else {
                        //register failure
                        resp.data.invalidUsers.forEach(user => {
                            this.users.filter(u => u.username == user.userName)[0].errorMessage = user.message
                        })
                        this.registerMessage = "Couldn't register one or more users"
                        this.registerSuccess = false
                        this.$refs.message.showMessage()
                    }
                })
        },
        testPasswordStrength(e, index) {
            var password = e.target.value
            var mediumRegex = new RegExp(
                '^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
                'g'
            )
            if (!mediumRegex.test(password))
                this.users[index].errorMessage = 'Weak Password!'
            else this.users[index].errorMessage = ''
        },
        testPasswordStrengthAndMatch(e, index) {
            if (
                this.users[index].password != this.users[index].confirmPassword
            ) {
                this.users[index].errorMessage = "Passwords don't match!"
            } else {
                this.testPasswordStrength(e, index)
            }
        },
        generateUsers(e) {
            this.showGenerateForm = false
            this.users = []
            var prefix = e.target.querySelector('input[name=prefix]').value
            var amount = e.target.querySelector('input[name=amount]').value
            for (let i = 0; i < amount; ) {
                let password = this.generateRandomPassword()
                let username = prefix + ++i
                this.users.push({
                    username: username,
                    password: password,
                    confirmPassword: password,
                    showPassword: true,
                })
            }
        },
        generateRandomPassword() {
            var length = 7
            var letters = 'abcdefghijklmnopqrstuvwxyz'
            var lowercasePassword = ''
            for (let i = 0; i < length; i++) {
                lowercasePassword +=
                    letters[Math.floor(Math.random() * letters.length)]
            }
            var strongPassword =
                lowercasePassword.charAt(0).toUpperCase() +
                lowercasePassword.slice(1)
            return strongPassword
        },
        appendBrands(data) {
            data.brandCurrencies.forEach(c => {
                this.currencyOptions.push({
                    value: c.currencyId,
                    text: c.currencyCode,
                })
            })
        },
        checkFormsBeforeSending() {
            var result = true
            this.users.forEach(u => {
                if(!u.username) {
                    u.errorMessage = 'Please Enter The Username!'
                    result = false
                }
                else if(!u.password) {
                    u.errorMessage = 'Please Enter The Password!'
                    result = false
                }
                else if(u.password != u.confirmPassword) {
                    u.errorMessage = "Passwords don't match!"
                    result = false
                }
                else if(!u.currencyID) {
                    u.errorMessage = "Please Select The Currency!"
                    result = false
                }
            })
            return result
        }
    },
    computed: {
        directory() {
            return this.$store.state.directory
        },
    },
    watch: {
        directory(data) {
            if (!data) return
            this.appendBrands(data)
        },
    },
}
</script>

<style lang="scss" scoped>
.register-users {
    padding: 40 * $rem;
    min-height: 550 * $rem;
    position: relative;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 20 * $rem;

        h3 {
            font-size: 16 * $rem;
            font-weight: 500;
        }
        .generate-toggle {
            margin-left: 25 * $rem;
            width: 192 * $rem;
            height: 57 * $rem;
            background-color: #a5a5a5;
            border-radius: 0.1875rem;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15 * $rem;

            i {
                font-size: 32 * $rem;
                margin-right: 15 * $rem;
            }

            &:hover {
                cursor: pointer;
                filter: brightness(1.1);
            }
        }
        .generate-form {
            display: flex;
            align-items: center;
            margin-left: 15 * $rem;

            input {
                height: 32 * $rem;
                margin-right: 16 * $rem;
                border: 1 * $rem solid #d0d0d0;
                padding-left: 10 * $rem;
                width: 192 * $rem;
            }

            .generate {
                background-color: $blue-dark;
                padding: 10 * $rem 15 * $rem;
                height: 32 * $rem;
                color: white;
                border-radius: $rem;
                border: none;
                margin: auto;
                line-height: 12 * $rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    ul.forms {
        list-style: none;
        margin-bottom: 20 * $rem;

        li.form {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 20 * $rem 0;
            border-bottom: 1 * $rem solid #d4d4d4;

            span.number {
                font-size: 14 * $rem;
                color: $grey-dark;
                margin-right: 18 * $rem;
            }

            input {
                vertical-align: top; //n
                height: 32 * $rem;
                margin-right: 16 * $rem;
                border: 1 * $rem solid #d0d0d0;
                padding-left: 10 * $rem;
                width: 192 * $rem;
            }

            .dd-container {
                width: 192 * $rem;
                height: 32 * $rem;
            }

            /deep/ select {
                width: 150 * $rem;
            }

            i {
                margin-left: 16 * $rem;
                color: $grey-dark;
                margin-right: 16 * $rem;

                &:hover {
                    cursor: pointer;
                    filter: brightness(1.2);
                }
            }

            p.error-message {
                color: $red;
                font-weight: 500;
            }
        }
    }

    .plus-button {
        margin-left: 25 * $rem;
        width: 192 * $rem;
        height: 57 * $rem;
        background-color: #a5a5a5;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.1875rem;

        i {
            font-size: 44 * $rem;
            color: white;
        }

        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    .save {
        position: absolute;
        bottom: 40 * $rem;
        right: 40 * $rem;
        width: 192 * $rem;
        height: 57 * $rem;
        background-color: $blue-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 0.1875rem;

        &:hover {
            cursor: pointer;
            filter: brightness(1.2);
        }
    }
}

@media screen and (max-width: 1530px) {
    .register-users {
        ul.forms {
            li.form {
                position: relative;

                .helper {
                    width: 100%;
                }

                input,
                select,
                .dd-container {
                    width: calc(100% / 4 - #{16 * $rem});
                    margin-bottom: 10 * $rem;
                }

                i {
                    margin-right: 0;
                }

                p.error-message {
                    position: absolute;
                    bottom: 5 * $rem;
                    left: 27 * $rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .register-users {
        ul.forms {
            li.form {
                input,
                select,
                .dd-container {
                    width: calc(100% / 2 - #{16 * $rem});
                }
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .register-users {
        padding: 2.5rem 1.5rem;
        ul.forms {
            li.form {
                input,
                select,
                .dd-container {
                    width: 100%;
                }
            }
        }
    }
}
</style>
