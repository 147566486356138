<template>
    <div class="dashboard">
        <periods @periodChanged="periodChangeHandler"></periods>
        <dashboard-header :headerData="dashboardHeaderData"></dashboard-header>
        <div class="row">
            <line-chart
                v-if="havePermission('Dashboard_Actions')"
                :chartData="mediasStats"
                :columnNames="lineChartColumnNames"
                :chartName="$t('DASHBOARD__ACTIONS')"
            ></line-chart>
            <geo-chart
                v-if="havePermission('Dashboard_Geographical_Stats')"
                :chartData="geographicalStats"
            ></geo-chart>
            <column-chart
                v-if="havePermission('Dashboard_Products_Comparison')"
                :chartData="productsComparison"
                amountFieldName="commissionsAmount"
                typeFieldName="gameTypeName"
            ></column-chart>
            <top-players
                v-if="topPlayers.length > 0 && havePermission('Dashboard_Top_Players')"
                :topPlayers="topPlayers"
                :currencyCode="this.dashboardHeaderData.currencyCode"
                :realDecimalPlaces="dashboardHeaderData.realDecimalPlaces"
            ></top-players>
            <last-signups
                v-if="lastSignups.length > 0 && havePermission('Dashboard_Last_Signups')"
                :lastSignups="lastSignups"
                :currencyCode="this.dashboardHeaderData.currencyCode"
                :realDecimalPlaces="dashboardHeaderData.realDecimalPlaces"
            ></last-signups>
            <!-- <notification-block></notification-block> -->
        </div>
    </div>
</template>

<script>
import axios from '@/Helpers/AxiosInstance.js'
import DashboardHeader from './DashboardHeader/DashboardHeader.vue'
import GeoChart from '@/components/ReusableComponents/Charts/GeoChart.vue'
import ColumnChart from '@/components/ReusableComponents/Charts/ColumnChart.vue'
import LineChart from '@/components/ReusableComponents/Charts/LineChart.vue'
import LastSignups from './LastSignups.vue'
// import NotificationBlock from './NotificationBlock/NotificationBlock.vue'
import TopPlayers from './TopPlayers.vue'
import Periods from '../ReusableComponents/Periods/Periods.vue'
import { getDateString } from '../../Helpers/HelperFunctions.js'

export default {
    components: {
        DashboardHeader,
        GeoChart,
        ColumnChart,
        LineChart,
        LastSignups,
        // NotificationBlock,
        TopPlayers,
        Periods,
    },
    data: function() {
        return {
            mediasStats: [],
            geographicalStats: [],
            dashboardHeaderData: {},
            lastSignups: [],
            topPlayers: [],
            productsComparison: [],
        }
    },
    methods: {
        getData(startDate, toDate) {
            toDate.setMilliseconds(toDate.getMilliseconds() - 1)
            axios
                .get('Dashboard/GetDashboard', {
                    params: {
                        beginDate: getDateString(
                            startDate,
                            'yyyy-MM-dd HH:mm:ss'
                        ),
                        endDate: getDateString(toDate, 'yyyy-MM-dd HH:mm:ss'),
                    },
                })
                .then(resp => {
                    this.mediasStats = resp.data.mediasStats
                    this.geographicalStats = resp.data.geographicalStats
                    this.dashboardHeaderData = resp.data.dashboardHeader
                    resp.data.lastSignups.forEach(
                        el => (el.countryName = el.countryName.toLowerCase())
                    )
                    this.lastSignups = resp.data.lastSignups
                    resp.data.topPlayers.forEach(
                        el => (el.countryName = el.countryName.toLowerCase())
                    )
                    this.topPlayers = resp.data.topPlayers
                    this.productsComparison = resp.data.productsComparisons
                })
        },
        periodChangeHandler(data) {
            this.getData(data.startDate, data.toDate)
        },
        havePermission(permissionName) {
            return this.dashboardBlockNamesToDisplay.includes(permissionName)
        },
    },
    computed: {
        dashboardBlockNamesToDisplay() {
            var permEnum = this.$store.state.permissionsEnum['Dashboard']
            var permissions = this.$store.state.permissions[permEnum]
            return permissions || []
        },
        lineChartColumnNames() {
            return [
                this.$t('DASHBOARD__DATE'),
                this.$t('DASHBOARD__VIEW'),
                this.$t('DASHBOARD__UNIQUE_VIEW'),
                this.$t('DASHBOARD__CLICK'),
                this.$t('DASHBOARD__SIGN_UPS'),
            ]
        },
    },
}
</script>

<style scoped lang="scss">

.dashboard {
    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15 * $rem;
        margin-top: -15 * $rem;

        /deep/ .block {
            margin-top: 15 * $rem;
            margin-right: 10 * $rem;
            width: calc(100% / 3 - 20 *#{$rem} / 3);
            overflow: auto;
            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 1900px) {
    .dashboard .row {
        /deep/ .pie-chart-wrapper {
            height: 435 * $rem;
        }
        /deep/ .block {
            width: 49%;

            &:nth-of-type(3n) {
                margin-right: 10 * $rem;
            }

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .dashboard .row {
        /deep/ .pie-chart-wrapper {
            height: initial;
        }
        /deep/ .block {
            width: 100%;
            margin-right: 0;

            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }
    }
}

</style>
