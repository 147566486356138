<template>
    <div class="dd-container" :name="name" @input="event => { $emit('input', event.target.value) }">
        <div class="chosen-and-search">
            <div
                class="dd-chosen"
                :class="chosenOpt === initialOpt ? 'default-option' : ''"
                :title="chosenOpt.text"
                :value="chosenOpt.value"
                v-on:click="headerClick($event)"
            ><p>{{chosenOpt.text}}</p>
            </div>
            <div class="dd-search" v-if="filteredOptions && searchable" v-show="expanded">
                <input type="text" v-model="searchPhrase">
            </div>
        </div>
        <ul class="dd-options" :class="searchable ? 'searchable' : ''" v-show="expanded">
            <template v-if="filteredOptions">
                <li
                    class="dd-option"
                    v-for="option in filteredOptions"
                    :title="option.text"
                    :value="option.value"
                    :key="option.value"
                    v-on:click="optionClick(option.value)"
                >{{option.text}}</li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    mounted() {
        document.body.addEventListener('click', e => {
            var clicked = e.target
            if (!this.isInsideCurrentComponent(clicked)) {
                this.expanded = false
            }
        })
    },
    computed: {
        filteredOptions() {
            if (!this.options) return []

            return this.options.filter(opt => {
                if (opt === this.chosenOpt) return false

                if (typeof opt.text == 'string') {
                    return ~opt.text
                        .toLowerCase()
                        .indexOf(this.searchPhrase.toLowerCase())
                }

                return true
            })
        },
        chosenOpt() {
            if (this.options && this.options.length > 0) {
                let opt = this.options.filter(el => el.value == this.value)[0]
                if (opt) return opt
                return this.options[0]
            }
            return { text: '', value: '' }
        },
        initialOpt() {
            if (this.initialValue) {
                let opt = this.options.filter(
                    el => el.value == this.initialValue
                )[0]
                if (opt) return opt
            } else if (this.options && this.options.length) {
                return this.options[0]
            }
            return { text: '', value: '' }
        },
    },
    props: {
        options: Array,
        name: String,
        width: String,
        value: String,
        searchable: {
            type: Boolean,
            default: false,
        },
        initialValue: String, //not required
    },
    data: function() {
        return {
            searchPhrase: '',
            expanded: false,
        }
    },
    methods: {
        isInsideCurrentComponent(element) {
            if (element == document) return false
            if (element == this.$el) return true
            return (
                element.parentNode &&
                this.isInsideCurrentComponent(element.parentNode)
            )
        },
        optionClick(value) {
            this.$emit('input', value.toString())
            this.expanded = false
        },
        headerClick(e) {
            this.expanded = !this.expanded
            var searchInp = e.currentTarget.parentNode.querySelector(
                '.dd-search > input'
            )
            if (this.expanded && this.searchable) {
                this.$nextTick(() => {
                    searchInp.focus()
                })
            }
        },
    },
    watch: {
        options() {
            this.$emit('input', this.chosenOpt.value)
        },
    },
}
</script> 

<style scoped lang="scss">
* { box-sizing: border-box; }

.dd-container {
    $height: 32 * $rem;
    display: inline-block;
    position: relative;
    min-height: $height;
    min-width: 60 * $rem;
    height: 100%;

    .chosen-and-search {
        height: 100%;

        & > div {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #fff;
            padding: 0 5 * $rem;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            border: 1 * $rem solid #d0d0d0;

            &.dd-search {
                height: 2rem;
                z-index: 999;
                position: relative;
            }
        }

        .dd-chosen {
            position: relative;
            font-size: 14 * $rem;
            font-weight: 500;
            color: $grey-dark;
            padding-left: 7 * $rem;
            user-select: none;
            padding-right: 25 * $rem;
            outline: 0;
            border-radius: 4 * $rem;

            p {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;   
            }

            &::after {
                content: "";
                position: absolute;
                right: 10 * $rem;
                top: calc(50% - 5*#{$rem});
                border: solid $grey-dark;
                border-width: 0 2.5 * $rem 2.5 * $rem 0;
                display: inline-block;
                padding: 2.5 * $rem;
                transform: rotate(45deg);
            }
        }

        .dd-search {

            input {
                width: 100%;
                height: 24 * $rem;
                outline: 0;
                border: 1 * $rem solid #d0d0d0;
                padding-left: 5 * $rem;
                box-sizing: border-box;
            }
        }
    }

    .dd-options {
        position: absolute;
        top: calc(100% - 1 * #{$rem});
        z-index: 5;
        list-style-type: none;
        min-width: 60 * $rem;
        width: 100%;
        height: auto;
        max-height: calc(5*#{$height});
        overflow: auto;
        border: 1 * $rem solid #d0d0d0;
        border-top: 0;

        li.dd-option {
            display: flex;
            align-items: center;
            width: 100%;
            height: $height;
            background-color: #fff;
            padding: 0 5 * $rem;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                color: white;
                background-color: #1e90ff;
            }
        }

        &.searchable {
            top: calc(2rem + 2.375rem);
        }
    }
}
</style>
