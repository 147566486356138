<template>
    <a @click="clicked" :class="{outlined: outlined}">{{$t(title) || title}}</a>
</template>

<script>
export default {
    props: {
        title: String,
        outlined: Boolean,
    },
    methods: {
        clicked() {
            this.$emit('clicked')
        },
    },
}
</script>

<style scoped lang="scss">
a.outlined,
button.outlined {
    display: flex;
    align-items: center;
    font-size: 14 * $rem;
    padding: 0 * $rem 10 * $rem;
    border: 1 * $rem solid $grey-dark;
    border-radius: 2 * $rem;
    transition: background-color 0.2s, color 0.2s, border 0.2s;
    font-weight: 500;
    color: $grey-dark;

    &:hover {
        cursor: pointer;
        background-color: #f2f1f2;
    }
}
</style>
