<template>
    <div class="block pie-chart-wrapper">
        <h5>{{ title }}</h5>
        <div ref="container" class="chart"></div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    mounted() {
        this.loadChart(this.chartData)
    },

    props: {
        chartData: Array,
        title: {
            type: String,
            default: 'Products Comparison',
        },
        amountFieldName: String,
        typeFieldName: String,
    },

    watch: {
        chartData: function(newValue) {
            this.loadChart(newValue)
        },
    },

    methods: {
        loadChart(chartData) {
            var container = this.$refs.container
            google.charts.load('current', {packages: ['corechart', 'bar']});
            google.charts.setOnLoadCallback(() => {
                var arrays = [
                    ['Game Types', 'Commission']
                ]
                chartData.forEach(el => {
                    arrays.push(new Array(el.gameTypeName, el.commissionsAmount))
                })
                var columnChart = new google.charts.Bar(container);
                var datatable = google.visualization.arrayToDataTable(arrays)
                var options = {
                    // width: 500,
                    height: 275,
                    legend: {position: 'none'}
                }
                columnChart.draw(datatable, options);
            })
        },
    },
}
</script>

<style scoped lang="scss">
.block {
    width: 510 * $rem;
    height: 350 * $rem;
    padding: 15 * $rem;
}

h5 {
    padding-left: 15 * $rem;
}
</style>
