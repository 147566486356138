import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        directory: null,
        permissions: {},
        permissionsEnum: {
            General: 1,
            UserList: 2,
            AffiliateUserProfile_UserProfile: 3,
            AffiliateUserProfile_UserWallets: 4,
            AffiliateUserProfile_LastLogin: 5,
            Dashboard: 6,
            Dashboard_Top_Players: 7,
            Dashboard_Last_Signups: 8,
            Marketing_Affiliate_Media_Resources: 9,
            Report_General_Report: 10,
            Report_Sales_Report: 12,
            Report_Media_Report: 13,
            Report_Product_Report: 14,
            Report_Geographical_Report: 15
        },
        assetsPath: 'https://static.inpcdn.com/',
        userID: ''
    },
    mutations: {
        setPermissions(state, permissions) {
            state.permissions = permissions
        }
    }
})
