<template>
    <div class="input-wrapper">
        <datepicker
            @selected="onSelect"
            v-model="modelValue"
            :placeholder="placeholder"
            format="yyyy-MM-dd"
        ></datepicker>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { getDateString } from '@/Helpers/HelperFunctions.js'
export default {
    components: {
        Datepicker,
    },
    props: {
        placeholder: String,
        value: String,
    },
    data() {
        return {
            modelValue: this.value,
        }
    },
    methods: {
        onSelect(date) {
            this.$emit('selected', date)
        },
    },
    watch: {
        modelValue(val) {
            this.$emit('input', getDateString(val, 'yyyy-MM-dd'))
        },
    },
}
</script>

<style scoped lang="scss">
/deep/ input {
    border-radius: 4 * $rem;
    border: 1 * $rem solid #ccc;
    padding: 7 * $rem 10 * $rem;
    font-weight: 500;
    width: 100%;
    color: $grey-dark;

    &::placeholder {
        color: $grey-dark;
        font-size: 14 * $rem;
        font-weight: 500;
    }
}
</style>
