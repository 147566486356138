<template>
    <div class="block">
        <h5>{{ $t('GEO_CHART__GEOGRAPHICAL_STATS') }}</h5>
        <div ref="container" class="chart"></div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    mounted() {
        this.loadChart(this.chartData)
    },

    props: {
        chartData: Array,
    },

    watch: {
        chartData: function(newValue) {
            this.loadChart(newValue)
        },
    },

    methods: {
        loadChart(chartData) {
            var container = this.$refs.container
            var columns = chartData
            google.charts.load('current', {
                packages: ['geochart'],
                mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY',
            })
            google.charts.setOnLoadCallback(drawRegionsMap)

            function drawRegionsMap() {
                var arr = []
                arr.push(new Array('Country', 'Popularity'))
                columns.forEach(element =>
                    arr.push(new Array(element.countryName, element.count))
                )
                var dt = google.visualization.arrayToDataTable(arr)

                var options = {
                    showZoomOut: true
                }

                var chart = new google.visualization.GeoChart(container)

                chart.draw(dt, options)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.block {
    width: 510 * $rem;
    height: 350 * $rem;
    padding: 15 * $rem 33 * $rem;
}
</style>
